@import "styles/theme.scss";

.assign-header {
  span {
    display: inline-block;
    margin-right: 24px;

    .anticon {
      transform: translateY(2px);
      margin-right: 4px;

      svg,
      svg * {
        fill: $text-faded;
      }
    }
  }
}

.assign-content {
  margin-left: -32px;
  margin-right: -32px;

  td {
    cursor: pointer;
  }

  .center-container {
    margin-top: 24px;
  }
}
