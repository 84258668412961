td.ant-picker-cell-week {
  visibility: hidden;
}

.ant-picker-week-panel {
  .ant-picker-body {
    transform: translateX(-16px);
  }
}

.ant-select-open {
  .ant-select-arrow {
    span[aria-label="down"] {
        transform: rotate(180deg);
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-ranges {
    .ant-picker-ok {
      display: none;
    }
  }
}

.ant-picker-cell {
  &::after {
    position: absolute !important;
    top: 15px !important;
  }
  &::before {
    position: absolute !important;
    top: 18px !important;
    transition: background 0s !important;
  }
}
