@import "styles/theme.scss";

.kfc-rating-report {
  &.kfc-view-cashiers {
    .ant-table-thead {
      tr {
        th {
          padding-top: 24px;
          padding-bottom: 8px !important;
          text-align: right !important;
          white-space: normal;
          vertical-align: bottom;

          .ant-table-column-title {
            padding-right: 8px;
            text-align: right;
            font-size: 12px;
            line-height: 14px;
          }

          &:last-child,
          &:nth-last-child(2) {
            white-space: nowrap;
          }
        }
      }
    }
    .report-wrap {
      .ant-table-thead {
        tr th {
          &:last-child,
          &:nth-last-child(2) {
            .ant-table-column-sorters {
              white-space: nowrap;
            }
          }
        }
      }
      .ant-table-body {
        table-layout: fixed;

        .ant-table-tbody {
          tr td {
            padding-left: 8px;
            width: 7%;
            padding-top: 10px;
            padding-bottom: 10px;

            &:first-child {
              width: auto;
              padding-left: 32px;
            }

            &:last-child,
            &:nth-last-child(2) {
              text-align: right;
            }

            &:nth-last-child(2) {
              border-left: 1px solid $border-color;
            }
          }
        }
      }

      & > .ant-checkbox-wrapper {
        padding: 16px 32px;
      }
    }

    .report-table .ant-table-thead > tr > th .ant-table-column-sorters {
      white-space: normal;
      text-align: left;

      &:before {
        display: none;
      }
    }
  }

  .report-table
    .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-sorters.numeric {
    padding-left: 24px;
  }

  &.kfc-view-categories {
    .ant-table {
      tr td {
        cursor: pointer;

        & > * {
          cursor: pointer;
        }
      }
    }
  }

  .ant-form-item {
    margin-bottom: -5px;
    margin-top: -5px;

    .ant-input-number-input {
        height: 22px;
    }
  }

  .rating-aside {
    position: absolute;
    left: 70%;
    z-index: 9;
    top: 160px;
    width: 255px;
  }

  .rating-empty-search {
    text-align: left;
    max-width: 350px;
    color: $text-color;
    padding: 0 9px;
  }
  .pager-wrap {
    min-height: 150px;
  }

  .button-download {
    height: 24px;
    button {
      padding: 0;
      height: 24px;
    }
    button:disabled {
      path: {
        fill: $text-faded;
      }
    }
  }
}
