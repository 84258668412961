.staff-training {
    & > .ant-row {
        max-width: 610px;
        margin-bottom: 9px;
    }

    .training-textarea {
        margin-bottom: 16px;
    }

    .fi-field-isBench {
        padding-top: 24px !important;
    }
    .ant-checkbox-group {
        flex-wrap: wrap;
        justify-content: flex-start;
        .ant-checkbox-group-item {
            margin-top: 6px;
            margin-right: 16px;
            flex-direction: row;
            width: max-content;
            .ant-checkbox {
                margin-right: 6px;
            }
        }
    }

    .ant-calendar-picker#StaffTraining_period {
        max-width: 140px;
        margin-left: 2px;
        .ant-calendar-picker-icon {
            display: inline-block;
            left: 6px;
            color: #1F86FF;
        }
        .ant-calendar-picker-input {
            padding-left: 28px;
            border: unset;
            color: rgba(31, 134, 255, 0.87);
            text-transform: capitalize;
        }
    }
}
