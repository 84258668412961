@import "styles/theme.scss";

.kfc-planning {
  .inner-content .ant-card-body .center-container {
    min-height: 300px;
  }

  .ant-form-item {
    margin-bottom: -2px;
    margin-top: -2px;
  }

  .predict-date-picker {
    .ant-calendar-picker-icon::after,
    .ant-time-picker-icon::after,
    .ant-picker-icon::after,
    .ant-select-arrow,
    .ant-cascader-picker-arrow {
      color: $error-color;
    }

    .ant-time-picker-input {
      padding-left: 7px;
      padding-right: 7px;
    }

    .ant-calendar-picker-input {
      width: auto;
      border: none;
      color: $text-link;
      box-shadow: none !important;
      cursor: pointer;

      &:hover {
        color: $text-color;

        .ant-calendar-picker-icon {
          color: $text-color;
        }

        .ant-calendar-range-picker-separator {
          &:after {
            color: $text-color;
          }
        }
      }
    }
  }

  .calendar-switch .ant-card-body {
    min-height: 0;
  }

  .calendar-switch {
      .ant-picker {
        border: none;
      }

      .ant-picker-disabled {
        background: transparent;

        .ant-picker-suffix {
          color: inherit;
        }
      }
  }

  .planning-cover {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  h2 {
    font-size: 20px;
    line-height: 1;
    color: $text-color;
  }

  .ant-card.page-title .ant-card-body {
    padding-left: 0;
  }

  .report-wrap {
    margin-left: -24px;
    margin-right: -24px;
  }

  .ant-calendar-picker {
    margin-top: 3px;
    padding-left: 24px;

    .ant-calendar-picker-input {
      text-transform: capitalize;
    }

    .ant-calendar-picker-icon {
      left: 16px;
    }

    &:hover {
      .ant-calendar-picker-input,
      .ant-calendar-picker-icon {
        color: $text-color;
      }
    }
  }

  .ant-table {
    table {
      width: 100%;

      .predict-date-picker {
        .ant-picker {
          border: none;
        }
      }

      .ant-table-row {
        &:hover {
          td {
            background-color: $old-row-hover;
          }
        }
      }

      th {
        text-align: left !important;
        background: inherit;

        &::before {
          display: none;
        }



        &.ant-table-column-has-actions.ant-table-column-has-sorters.numeric:nth-last-child(2),
        &.ant-table-column-has-actions.ant-table-column-has-sorters.numeric:nth-last-child(3),
        &.ant-table-column-has-actions.ant-table-column-has-sorters.numeric:nth-last-child(4) {
          text-align: right !important;
          padding-right: 8px !important;
        }

        &:last-child {
          text-align: right !important;
          padding-right: 32px !important;
        }
      }

      td:first-child {
        padding-left: 24px;
      }

      th,
      td {
        &:nth-child(3) {
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:nth-child(4) {
          max-width: 150px;
        }

        &:nth-child(4),
        &:nth-child(5) {
          min-width: 150px;
          text-align: right !important;

          .ant-input-number {
            width: 100%;
          }

          .ant-table-header-column {
            padding-right: 32px;
          }

          .ant-input-number-input {
            text-align: right;
            padding-right: 24px;
          }
        }
      }
    }
  }

  .report-wrap tr.ant-table-row.ant-table-row-level-0 > td {
    cursor: default;

    & > a {
      display: block;
      position: relative;
      box-sizing: content-box;
      margin: -7px 0;
      padding: 7px 0;
      color: $text-color;
      cursor: pointer;

      &:hover {
        color: $text-link;
      }
    }
  }

  .ant-calendar-picker-input.ant-input[disabled] {
    background-color: transparent;
  }

  hr {
    display: block;
    border: none;
    height: 1px;
    background-color: $shaded-background;
    margin: 24px -24px;
  }

  .with-origin {
    position: relative;
    display: inline-block;
    padding-left: 8px;
    color: $text-helper;
  }

  .origin-indicator {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 5px;
    background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOC42NzMzNSAyLjMzMzcxSDEuMzMzMzVDMC45NjY2ODcgMi4zMzM3MSAwLjY2NjY4NyAyLjYzMzcxIDAuNjY2Njg3IDMuMDAwMzhDMC42NjY2ODcgMy4zNjcwNCAwLjk2NjY4NyAzLjY2NzA0IDEuMzMzMzUgMy42NjcwNEg4LjY3MzM1VjQuODYwMzhDOC42NzMzNSA1LjE2MDM4IDkuMDMzMzUgNS4zMDcwNCA5LjI0MDAyIDUuMDkzNzFMMTEuMDkzNCAzLjIzMzcxQzExLjIyIDMuMTAwMzggMTEuMjIgMi44OTM3MSAxMS4wOTM0IDIuNzYwMzhMOS4yNDAwMiAwLjkwMDM3OEM5LjAzMzM1IDAuNjg3MDQ0IDguNjczMzUgMC44NDAzNzcgOC42NzMzNSAxLjEzMzcxVjIuMzMzNzFaIiBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii42Ii8+PC9zdmc+)
      no-repeat center center;
  }

  .hover-origin {
    .link {
      display: none;
    }

    &:hover {
      .link {
        display: inline-block;
      }
      em {
        display: none;
      }
    }
  }
}

.alert-cancel {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  .anticon {
    margin-left: 8px;
    font-size: 12px;
  }
}

.lastyear-popover {
  p {
    font-size: 12px;
    line-height: 1.2;
    max-width: 250px;
  }

  .link {
    font-size: 12px;
    margin-right: 24px;

    &.remove {
      color: $error-color;
    }
  }
}
