.manning-card {
  .ant-card {
    max-width: 100%;

    tr:hover .ant-input-number-handler-wrap,
    tr.ant-table-row-hover .ant-input-number-handler-wrap {
      opacity: 1;
    }
  }
}

.manning-table {
  
  .ant-table-row {
    .ant-form-item {
      margin: 0px;
    }
  }
  .ant-table-content::-webkit-scrollbar {
    display: none;
  }

  .ant-table-scroll {
    overflow-y: hidden;

    table {
      table-layout: fixed;

      colgroup {
        display: none;
      }

      td,
      th {
        width: 100px;

        &:first-child {
          width: 200px;
        }

        &:last-child {
          width: 150px;
        }

        &:nth-last-child(2) {
          width: 130px;
        }
      }
    }
  }
}
