@import "styles/theme.scss";

.permissions-list {
  height: 300px;
}

.kfc-admin-permissions {
  max-width: 430px;

  .ant-divider-horizontal {
    margin-bottom: 8px;
  }

  .ant-modal-body {
    padding-right: 0;
    padding-left: 0;
    height: 400px;

    .filter-container {
      padding-right: $default-padding;
      padding-left: $default-padding;

      p {
        color: $text-faded;
        padding: 0 8px;
      }
    }
  }

  .list-overspin {
    padding: 24px 40px 24px 32px;

    .ant-checkbox-wrapper {
      font-size: 12px;
    }
  }

  .ant-list-item {
    height: auto;
    border: none !important;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;
    padding-left: 8px;

    & > label {
      width: 100%;
    }

    .ant-checkbox-wrapper {
      width: 100%;
    }

    .item-title {
      margin-right: 8px;
    }

    &.item-radio {
      padding-left: 24px;
    }
  }

  .popup-columns {
    display: flex;
    flex-direction: row;

    & + .ant-divider-horizontal {
      margin-top: 0;
    }

    .popup-column {
      flex: 0 0 50%;
    }
  }
}
