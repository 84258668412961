.view-type-drop {
  line-height: 1.5;
  position: relative;
  min-width: 230px;
  margin-bottom: 16px;
}

.select {
  width: 100%;
}
