@import "styles/theme.scss";

.create-action-plan-popup {
  .ant-form .ant-form-item.fi-type-link {
    padding-top: 0;
    margin-bottom: 0;

    .list-icon {
      margin-bottom: 0;
    }
  }

  .ant-divider-horizontal {
    margin-bottom: 0;
  }

  .deviations-list {
    height: 334px;
  }

  .deviations-list > div[style] {
    height: calc(100% - 30px) !important;

    & + .ant-divider-horizontal {
      margin-top: 0;
    }
  }

  .list-overspin {
    padding: 10px 40px 10px 32px;

    .ant-checkbox-wrapper {
      font-size: 12px;
    }
  }

  .ant-form {
    margin-left: -24px;
    margin-right: -24px;

    .form-inner {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .filter-container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .ant-list-item {
    height: auto;
    border: none !important;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;
    padding-left: 24px;
    padding-right: 24px;

    .ant-checkbox-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;

      .ant-checkbox {
        transform: translateY(4px);
      }
    }

    .item-title {
      margin-right: 8px;
    }
  }
}
