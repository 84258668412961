@import "styles/theme.scss";

.kfc-report {
  height: 100%;

  .report-wrap .ant-table table {
    width: auto;
  }

  .report-table.report-page {
    .ant-dropdown {
      min-width: 0 !important;
    }
  }
}

.report-page .report-wrap .ant-table table,
.report-wrap .ant-table table.ant-calendar-year-panel-table,
.report-wrap .ant-table table.ant-calendar-decade-panel-table,
.report-wrap .ant-table table.ant-calendar-month-panel-table {
  width: 100%;
}

.settings-icon {
  transition: transform ease 0.3s;
  cursor: pointer;
  position: absolute;
  top: -56px;
  right: 0;

  path {
    transition: all ease 0.3s;
    fill: $icon-def;
  }
}

.ant-dropdown-open {
  .settings-icon {
    transform: rotateZ(60deg);
    path {
      fill: $icon-active;
    }
  }
}
