@import "styles/theme.scss";

.ppr-area-header {
    background: #fff;
    padding-left: calc(25px + 16px);
    .ppr-area-header-inner {
        border-top: 1px solid $border-color;
        display: flex;
        align-items: center;
        padding: 24px 0;
        .header-title {
            margin-top: 8px;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            margin-right: 48px;
            text-transform: capitalize;
            min-width: 130px;
            color: #000;
        }

        .header-picker {
            margin-top: 8px;
            align-items: center;
            .reports-controls,
            .reports-controls-header {
                height: 24px;
                width: 34px;
                padding: 0;
            }
            .dates-fake {
                display: block;
                border: 1px solid #1F86FF;
                border-left-width: 0;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                .anticon {
                    margin: 0;
                }
            }
        }

        .vertical-line {
            height: 24px;
            width: 0px;
            border: 1px solid $border-color;
            margin: 8px 24px 0
        }

        .area-controls {
            align-self: flex-start;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-right: 40px;
            .ac-col {
                max-width: 270px;
                align-self: center;
                &:last-child {
                    margin-left: 24px;
                }
            }
            label {
                display: block;
                font-size: 12px;
                color: $text-helper;
                line-height: 14px;
            }
            .ant-select {
                margin-top: 6px;
            }
            .ant-btn {
                width: 166px;
            }
        }
    }
}

/* .ppr-area-manager-select {
    min-width: 350px;
} */