@import "styles/theme.scss";

.siv-block {
  text-align: center;

  .siv-block-header {
    height: 1em;
    margin-bottom: 16px;
  }

  .siv-block-content {
    display: flex;
    flex-direction: row;
    border: 1px solid $border-color;
    border-radius: 4px;

    .siv-block-report {
      flex: 0 0 50%;

      &:first-child {
        border-right: 1px solid $border-color;
      }
    }
  }

  &.current-siv .siv-block-report:first-child,
  &.current-restaurant .siv-block-report:last-child {
    background-color: $hint-background;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: -1px;
      width: calc(100% + 2px);
      height: 4px;
      background-color: $accent-color;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
}
