@import "styles/theme.scss";

.admin-tasks-controls {
  padding: 16px 0;
  position: sticky;
  top: -16px;
  background-color: #fff;
  z-index: 9;
  margin-left: -24px;
  padding-left: 24px;
  margin-right: -24px;
  padding-right: 24px;

  .ant-col {
    padding-right: 16px;

    &:last-child {
      padding-right: 0;
    }

    .ant-select {
      width: 100%;
    }
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: $old-row-hover;
}
