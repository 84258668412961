@import 'styles/theme.scss';

.button-download {
  padding: 0 8px !important;
  height: 24px;
  margin-top: 8px;
  button {
    padding: 0;
    height: 24px;
  }
  path {
    fill: $text-link;
  }
  button:disabled {
    path {
      fill: $text-faded;
    }
  }
}
