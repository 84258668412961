.print-report-template.ready-report {
  width: 100%;

  & > thead {
    & > tr:first-child {
      display: none;
    }

    & > tr:nth-child(2) {
      td {
        font-weight: normal;
        white-space: nowrap;
      }
    }

    td {
      &.row-day {
        padding-top: 24px;
        border-bottom: 2px solid #000;
      }

      &.row-header {
        font-weight: bold;
        padding: 10px;
        border-bottom: none;
      }
    }
  }

  & > tbody {
    td {
      padding: 3px 10px;
      border-bottom: 1px solid #ccc;
    }
  }
}

.print-report:first-child {
  .print-report-template.ready-report {
    & > thead > tr:first-child {
      display: table-row;
    }
  }
}

@media print {
  .print-report-template.ready-report {
    & > thead > tr:first-child {
      display: table-row;
    }
  }
}
