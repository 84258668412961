@import "styles/theme.scss";

.interactive-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-end;
  padding: 0 20px;

  & > * {
    flex: 0 0 auto;
    padding-right: 24px;

    &:first-child {
      flex: 0 0 310px;
    }

    &:nth-child(2) {
      flex: 0 0 200px;
    }

    &:nth-child(3) {
      flex: 0 0 270px;
    }

    &:nth-child(4) {
      flex: 1 1 100%;

      .ant-checkbox-wrapper {
        width: 180px;
      }
    }

    .ant-select {
      width: 100%;
    }
  }

  .dates-fake {
    transform: translateY(6px);
    display: block;
  }

  .ant-calendar-picker-container {
    top: 40px !important;
  }

  .controls-button {
    display: block;
    color: $text-link;
    cursor: pointer;
    line-height: 1.5;

    &:hover {
      text-decoration: underline;
    }

    svg {
      vertical-align: middle;
      margin-right: 10px;
      margin-top: -4px;

      * {
        fill: $text-link;
      }
    }
  }
}
