@import "styles/theme.scss";

.templates-list {
  height: 100%;
  flex: 1 1 100%;
  margin: 0 -24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .ant-list-item {
    height: auto;
    min-height: 40px;
    padding-right: 8px;

    &.inactive {
      color: $text-faded;
    }
  }

  .filter-container {
    padding: 16px 30px 24px;

    .ant-select {
      width: 100%;
    }
  }

  .list-header {
    flex: 0 0 auto;
    padding: 0 30px 7px;
    white-space: nowrap;
    border-bottom: 1px solid $border-color;
  }

  .list-footer {
    flex: 0 0 auto;
    padding: 15px 30px 16px;
  }
}
