@import "styles/theme.scss";

.top-star {
  cursor: default;
  display: block;

  .ant-spin-spinning {
    margin-left: -19px;
    margin-right: 9px;
    height: 18px;
  }

  .anticon {
    font-size: 10px;
    margin-left: -20px;
    margin-right: 10px;
    cursor: pointer;

    svg {
      fill: $app-background;
      transition: fill 0.2s ease;
    }

    &:hover {
      svg {
        fill: $text-faded;
      }
    }

    &.selected {
      svg {
        fill: $star-color;
      }
    }
  }
}

.top-star-error {
  .ant-tooltip-inner {
    background-color: $bad-light;
    color: $text-color;
    font-size: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 7px 10px;
  }
  .ant-tooltip-arrow {
    border-right-color: $bad-light;
  }
}
