@import "styles/theme.scss";

.kfc-calendar {
  .ant-layout-content {
    display: flex;
    flex-direction: column;

    .ant-card-body {
      padding: 0;
    }

    .tabbed-content > .ant-card > .ant-card-body {
      display: flex;
      flex-direction: column;
    }
  }

  .inner-content.fixed {
    flex: 1 1 100%;
  }
}

.kfc-calendar-popup {
  max-width: 450px;

  .ant-modal-body {
    p.text-helper {
      margin-bottom: 0;

      & + .ant-btn {
        margin-top: 1em;
      }
    }

    h3 {
      font-size: 20px;
      line-height: 24px;
      padding-top: 8px;
      margin-bottom: 16px;
    }

    .ant-form-item.narrow {
      max-width: 80px;

      &.fi-type-date {
        max-width: 140px;
      }
    }
  }
  .ant-row.actions .ant-form-item {
    margin-bottom: 0;
  }
  .ant-checkbox-group {
    .ant-checkbox {
      input,
      .ant-checkbox-inner {
        width: 0;
        height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
      }
    }

    .ant-checkbox-group-item {
      flex: 1 1 100%;
      border: 1px solid $text-link;
      border-radius: 4px;
      color: $text-link;
      transition: color 0.2s ease, background-color 0.2s ease;

      span:last-child {
        display: block;
        text-transform: uppercase;
      }

      &.ant-checkbox-wrapper-checked {
        background-color: $text-link;
        color: $text-light;
      }

      &.ant-checkbox-wrapper-disabled {
        opacity: 0.75;

        span {
          color: $text-light;
        }
      }
    }
  }
  .ant-row.time .ant-time-picker {
    width: 100%;
  }

  .ant-form .ant-form-item.fi-type-link {
    padding-top: 0;

    .time-icon:before {
      content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuMTY2NjkgNS4zMzM5OEM2Ljg5MzM1IDUuMzMzOTggNi42NjY2OSA1LjU2MDY1IDYuNjY2NjkgNS44MzM5OFY4Ljk2MDY1QzYuNjY2NjkgOS4xOTM5OCA2Ljc4NjY5IDkuNDA3MzIgNi45ODAwMiA5LjUyNzMyTDkuNDA2NjkgMTEuMDIwN0M5LjYyNjY5IDExLjE1NCA5LjkxMzM1IDExLjA5NCAxMC4wNTM0IDEwLjg4MDdDMTAuMjA2NyAxMC42NTQgMTAuMTMzNCAxMC4zNDczIDkuOTAwMDIgMTAuMjA3M0w3LjY2NjY5IDguODY3MzJWNS44MzM5OEM3LjY2NjY5IDUuNTYwNjUgNy40NDAwMiA1LjMzMzk4IDcuMTY2NjkgNS4zMzM5OFoiIGZpbGw9IiMxRjg2RkYiLz48cGF0aCBkPSJNMTEuOTQ2NyA4LjAwMTNDMTEuOTggOC4yMjEzIDEyIDguNDQxMyAxMiA4LjY2Nzk3QzEyIDExLjI2OCA5LjkzMzM0IDEzLjMzNDYgNy4zMzMzNCAxMy4zMzQ2QzQuNzMzMzQgMTMuMzM0NiAyLjY2NjY4IDExLjI2OCAyLjY2NjY4IDguNjY3OTdDMi42NjY2OCA2LjA2Nzk3IDQuNzMzMzQgNC4wMDEzIDcuMzMzMzQgNC4wMDEzQzcuODAwMDEgNC4wMDEzIDguMjQ2NjggNC4wNjc5NyA4LjY2NjY4IDQuMTk0NjRWMi44MjEzQzguMjQwMDEgMi43MjEzIDcuNzkzMzQgMi42Njc5NyA3LjMzMzM0IDIuNjY3OTdDNC4wMDAwMSAyLjY2Nzk3IDEuMzMzMzQgNS4zMzQ2NCAxLjMzMzM0IDguNjY3OTdDMS4zMzMzNCAxMi4wMDEzIDQuMDAwMDEgMTQuNjY4IDcuMzMzMzQgMTQuNjY4QzEwLjY2NjcgMTQuNjY4IDEzLjMzMzMgMTIuMDAxMyAxMy4zMzMzIDguNjY3OTdDMTMuMzMzMyA4LjQ0MTMgMTMuMzIgOC4yMjEzIDEzLjI5MzMgOC4wMDEzSDExLjk0NjdaIiBmaWxsPSIjMUY4NkZGIi8+PHBhdGggZD0iTTE0LjY2NjcgMy4zMzM5OEgxMy4zMzMzVjIuMDAwNjVDMTMuMzMzMyAxLjYzMzk4IDEzLjAzMzMgMS4zMzM5OCAxMi42NjY3IDEuMzMzOThDMTIuMyAxLjMzMzk4IDEyIDEuNjMzOTggMTIgMi4wMDA2NVYzLjMzMzk4SDEwLjY2NjdDMTAuMyAzLjMzMzk4IDEwIDMuNjMzOTggMTAgNC4wMDA2NUMxMCA0LjM2NzMyIDEwLjMgNC42NjczMiAxMC42NjY3IDQuNjY3MzJIMTJWNi4wMDA2NUMxMiA2LjM2NzMyIDEyLjMgNi42NjczMiAxMi42NjY3IDYuNjY3MzJDMTMuMDMzMyA2LjY2NzMyIDEzLjMzMzMgNi4zNjczMiAxMy4zMzMzIDYuMDAwNjVWNC42NjczMkgxNC42NjY3QzE1LjAzMzMgNC42NjczMiAxNS4zMzMzIDQuMzY3MzIgMTUuMzMzMyA0LjAwMDY1QzE1LjMzMzMgMy42MzM5OCAxNS4wMzMzIDMuMzMzOTggMTQuNjY2NyAzLjMzMzk4WiIgZmlsbD0iIzFGODZGRiIvPjwvc3ZnPg==);
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}

.fc-event-container {
  padding: 0 3px;
}

.fc-event {
  padding: 4px 8px;
  background-color: #d6e9ff;
  border-color: #d6e9ff;
  color: $text-color;

  &:hover {
    color: $text-color;
  }

  &.fc-e-past,
  &.fc-e-done {
    border-color: $shaded-background;
    background-color: $shaded-background;
    color: $text-faded;
    pointer-events: none;
  }

  &.fc-e-missed {
    border-color: #ffa7a7;
    background-color: #ffa7a7;
    color: $text-light;
  }

  &.fc-e-summary {
    background-color: transparent;
    border-color: transparent;
  }

  &.calendar-event {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
