.ppr-table {
    border-radius: 4px;
    background: #fff;
    width: 100%;
    position: relative;

    .ant-skeleton-paragraph {
        margin: 0;
    }

    tr td {
        height: 33px;
    }
    td,
    th {
        border: 1px solid #ebebeb;
        vertical-align: middle;
        text-align: center;
        font-weight: 400;
    }
    th {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        line-height: 14px;
        padding: 9px 12px;
    }
    td {
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.87);
        padding: 7px 12px;
    }

    &.small {
        th {
            padding: 2px 4px;
        }
        td {
            padding: 4px;
        }
    }
    
    th.bold, td.bold {
        font-weight: 700;
    }

    .bg-color-red {
        background: #A70000!important;
        color: #fff;
    }

    .color-red {
        color: #A70000!important;
    }

    .color-blue {
        color: #1F86FF;
    }

    .text-justify {
        text-align: justify;
    }

    .bg-gray {
        background: #f2f2f2;
    }

    .color-black {
        color: #000;
    }

    &.table-vertical {
        th {
            border-top: none;
        }
        td:first-child, th:first-child {
            border-left: none;
        }
        td:last-child, th:last-child {
            border-right: none;
        }
        tr:last-child td {
            border-bottom: none;
        }
    }

    &.table-horizontal {
        tr:first-child  {
            th, td {
                border-top: none;
            }
        }
        th:first-child {
            border-left: none;
        }
        td:last-child {
            border-right: none
        }
        tr:last-child  {
            th, td {
                border-bottom: none;
            }
        }
    }
}

.table-show-scroll {
    display: block;
    overflow-x: scroll;
    border-collapse: separate;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        border-top: 0.5px solid #ebebeb;
        height: 6px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
}