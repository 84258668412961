$first-col-width: 200px;
$cell-width: 80px;
$divider-width: 8px;

%sticky {
    position: sticky;
    z-index: 2;
}

.ppr-partner-info {
    position: relative;

    &::-webkit-scrollbar-track {
        margin-left: calc(#{$first-col-width} + #{$divider-width} + #{$cell-width} * 5);
        width: calc(100% - calc(#{$first-col-width} + #{$divider-width} + #{$cell-width} * 5));
    }

    * {
        border-width: 0.5px!important;
    }

    tr.empty {
        td {
            &:nth-child(n+1):nth-child(-n+5) {
                border-right: none;
            }
            &:nth-child(n+2):nth-child(-n+5) {
                border-left: none;
            }
        }
    }
    
    th, td {
        width: unset!important
    }
    td.ac-name {
        text-align: left;
    }
    .ac-name {
        min-width: 200px!important;
    }
    .col-name {
        width: 200px!important;
        white-space: nowrap;
        text-align: left;
    }
    th:first-child, td:first-child {
        @extend %sticky;
        min-width: $first-col-width!important;
        max-width: $first-col-width!important;
        left: 0;
        &:not(.bg-color-red) {
            background-color: #fff;
        }
    }

    th:nth-child(7), td:nth-child(7) {
        @extend %sticky;
        min-width: $divider-width;
        max-width: $divider-width;
        padding: 0;
        background-color: #ebebeb;
        left: calc(#{$first-col-width} + #{$cell-width} * 5);
    }

    @for $i from 0 to 5 {
        th:nth-child(#{$i + 2}), td:nth-child(#{$i + 2}) {
            @extend %sticky;
            left: calc(#{$first-col-width} + (#{$cell-width} * #{$i}));
            min-width: $cell-width!important;
            max-width: $cell-width!important;
            &:not(.bg-color-red) {
                background-color: #fff;
            }
        }
    }
}