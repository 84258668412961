@import "styles/theme.scss";

.statistics_tab {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 24px;

  .spinner {
    height: 30px;
  }

  td {
    overflow: hidden;
  }
}

