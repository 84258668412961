@import "styles/theme.scss";

.kfc-planning-deep {
  overflow-x: hidden;
  overflow-y: scroll;

  .planning-deep-scroll-table {
    overflow-x: scroll;
  }

  .ant-table {
    table {

      .accent-color {
        color: $accent-color;
        cursor: pointer;
      }

      .predict-date-picker {
        width: 140px;
      }

      .ant-picker {
        .ant-picker-clear {
          color: $accent-color;
        }
      }

      th {
        &.ant-table-column-has-actions.ant-table-column-has-sorters.numeric:nth-child(3),
        &.ant-table-column-has-actions.ant-table-column-has-sorters.numeric:nth-child(4),
        &.ant-table-column-has-actions.ant-table-column-has-sorters.numeric:nth-child(5),
        &.ant-table-column-has-actions.ant-table-column-has-sorters.numeric:nth-child(6) {
          text-align: right !important;
          padding-right: 8px !important;
        }

        &.ant-table-column-has-actions.ant-table-column-has-sorters.numeric:nth-child(7) {
          text-align: left !important;
          padding-right: 8px !important;
        }
      }
    }
  }
}
