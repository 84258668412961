.ppr-hr-data {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    tbody {
        th,td {
            width: unset!important;
        }
        th[rowspan="2"] {
            border-bottom: none;
            
        }
        > tr {
            border-left: 1px solid #ebebeb;
        }

        td {
            width: 70%!important;
            text-align: center;
            word-break: break-word;
        }
    }
    
}