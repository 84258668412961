.gauge {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gauge_title {
  font-size: 22px;
  font-weight: 400;
  transform: translateY(12px);
}
