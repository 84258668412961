$first-col-width: 300px;
$cell-width: 50px;
$id-width: 80px;
$divider-width: 8px;

%sticky {
    position: sticky;
    z-index: 2;
    background-color: #fff;
}

.ppr-partner-area-info {
    margin-bottom: 24px;

    &::-webkit-scrollbar-track {
        margin-left: calc(#{$first-col-width} + #{$cell-width} * 6);
        width: calc(100% - calc(#{$first-col-width} + #{$cell-width} * 6));
    }

    tr:last-child th, tr:last-child td {
        border-bottom: 1px solid #ebebeb!important;
    }
    * {
        border-width: 0.5px!important;
    }
    tbody {
        tr {
            th:first-child {
                text-align: left;
            }
            &:last-child, &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4) {
                td:first-child {
                    text-align: left;
                }
            }
        }
        td:not(:first-child) {
            width: $cell-width;
        }
    }
    thead {
        tr:nth-child(3) {
            th:first-child {
                text-align: left;
            }
        }
    }
    
    tr:not(.restaurant-number) {
        th:first-child, td:first-child {
            width: $first-col-width;
        }
    }
    th.id {
        width: $cell-width!important;
    }
    tr.restaurant-number {
        th:first-child {
            width: $id-width;
        }
        th:nth-child(2) {
            width: calc(#{$first-col-width} - #{$id-width});
            text-align: left;
        }
    }
    .ac-name {
        min-width: 200px;
        white-space: nowrap;
        text-align: left;
    }
    .rcl-code {
        width: 50px;
    }
    thead {
        tr:first-child {
            td:nth-child(n+1):nth-child(-n+3) {
                @extend %sticky;
            }
            td:nth-child(1) {
                left: 0;
                min-width: $first-col-width;
                max-width: $first-col-width;
            }
            td:nth-child(2) {
                left: $first-col-width;
                min-width: calc(#{$cell-width} * 4);
                max-width: calc(#{$cell-width} * 4);
            }
            td:nth-child(3) {
                left: calc(#{$first-col-width} + #{$cell-width} * 4);
                min-width: calc(#{$cell-width} * 2);
                max-width: calc(#{$cell-width} * 2);
            }
        }
        tr:nth-child(2) {
            th:nth-child(n+1):nth-child(-n+4) {
                @extend %sticky;
            }
            th:nth-child(1) {
                left: 0;
            }
            @for $i from 2 to 5 {
                th:nth-child(#{$i}) {
                    left: calc(#{$first-col-width} + (#{$cell-width} * 2) * #{$i - 2})
                }
            }
        }
        tr:nth-child(3) {
            th:nth-child(n+1):nth-child(-n+8) {
                @extend %sticky;
            }
            th:nth-child(1) {
                left: 0;
            }
            th:nth-child(2) {
                left: $id-width;
            }
            @for $i from 3 to 9 {
                th:nth-child(#{$i}) {
                    left: calc(#{$first-col-width} + #{$cell-width} * #{$i - 3})
                }
            }
        }
        tr:nth-child(1), tr:nth-child(2) {
            td:last-child {
                border-right: 1px solid #ebebeb
            }
            th:nth-last-child(2) {
                text-align: right;
            }
        }
    }
    
    tr.restaurant-number {
        th:nth-child(1) {
            @extend %sticky;
            left: 0;
        }
        th:nth-child(2) {
            @extend %sticky;
            left: $id-width;
        }
        @for $i from 3 to 9 {
            td:nth-child(#{$i}) {
                @extend %sticky;
                left: calc(#{$first-col-width} + #{$cell-width} * #{$i - 3})
            }
        }
    }
    tbody {
        tr:last-child {
            td:nth-child(1) {
                @extend %sticky;
                left: 0;
            }
            td:nth-child(2) {
                @extend %sticky;
                left: $first-col-width;
            }
        }
        tr:nth-last-child(2) {
            td:nth-child(1) {
                @extend %sticky;
                left: 0;
            }
            td:nth-child(2) {
                @extend %sticky;
                left: $first-col-width;
            }
            td:nth-child(3) {
                @extend %sticky;
                left: calc(#{$first-col-width} + #{$cell-width} * 3);
            }
        }
        tr:nth-last-child(3) {
            td:nth-child(1) {
                @extend %sticky;
                left: 0;
            }
            @for $i from 2 to 5 {
                td:nth-child(#{$i}) {
                    @extend %sticky;
                    left: calc(#{$first-col-width} + (#{$cell-width} * 2) * #{$i - 2})
                }
            }
        }
        tr:nth-last-child(4) {
            td:nth-child(1) {
                @extend %sticky;
                left: 0;
            }
            @for $i from 2 to 8 {
                td:nth-child(#{$i}) {
                    @extend %sticky;
                    left: calc(#{$first-col-width} + #{$cell-width} * #{$i - 2})
                }
            }
        }
    }
}