.ppr-director-header {
    background: #fff;
    padding-left: calc(25px + 16px);
    .ppr-director-header-inner {
        border-top: 1px solid #ebebeb;
        display: flex;
        align-items: center;
        padding: 24px 0;
        .header-title {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            margin-right: 48px;
            text-transform: capitalize;
            min-width: 130px;
            color: #000;
        }

        .header-picker {
            align-items: center;
            .reports-controls,
            .reports-controls-header {
                height: 24px;
                width: 34px;
                padding: 0;
            }
            .dates-fake {
                display: block;
                border: 1px solid #1F86FF;
                border-left-width: 0;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                .anticon {
                    margin: 0;
                }
            }
        }
    }
}
