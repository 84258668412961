@import "styles/theme.scss";

.osat_tab {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 24px;
}

.spinner {
  margin: 10px 0;
}
