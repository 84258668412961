@import "styles/theme.scss";

.card-chart {
  width: 100%;
  height: 100%;
  margin-top: 12px;

  .rv-xy-plot__series {
    pointer-events: none;

    & > path {
      pointer-events: none;
    }

    & > g {
      pointer-events: all;
    }
  }

  .rv-xy-plot {
    padding-bottom: 4px;
    box-sizing: content-box;
    padding-top: 12px;

    &__axis__tick__line {
      stroke-width: 3px !important;
      stroke: #b8b8b8 !important;
      stroke-linecap: round;
    }
  }

  .rv-crosshair {
    &__inner {
      display: none;
      left: 0;
      right: auto;
      top: 100%;
      margin-top: 9px;
      background-color: $accent-color;
      color: $text-light;
      padding: 0 5px;
      border-radius: 10px;
      transform: translateX(-50%);
      font-size: 10px;
      white-space: nowrap;
    }

    &__line {
      box-sizing: border-box;
      background: transparent;
      border-top: 208px solid $text-helper;
    }
  }

  .rv-hint {
    transform: translateY(-50%);
    margin-top: 12px;

    .hint {
      display: block;
      background-color: $neutral-light;
      color: $text-color;
      padding: 5px 10px;
      border-radius: $border-radius;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      font-size: 10px;

      &:before {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        z-index: 99999;
      }

      &.bad {
        background-color: $bad-light;
      }

      &.good {
        background-color: $good-light;
      }

      span {
        display: block;
      }
    }

    &.rv-hint--horizontalAlign-left {
      transform: translate(-10px, -50%);

      .hint {
        &:before {
          content: "";
          left: 100%;
          border-width: 7px 0 7px 10px;
          border-color: transparent transparent transparent $neutral-light;
        }

        &.good:before {
          border-color: transparent transparent transparent $good-light;
        }

        &.bad:before {
          border-color: transparent transparent transparent $bad-light;
        }
      }
    }

    &.rv-hint--horizontalAlign-right {
      transform: translate(10px, -50%);

      .hint {
        &:before {
          content: "";
          right: 100%;
          border-width: 7px 10px 7px 0;
          border-color: transparent $neutral-light transparent transparent;
        }

        &.good:before {
          border-color: transparent $good-light transparent transparent;
        }

        &.bad:before {
          border-color: transparent $bad-light transparent transparent;
        }
      }
    }
  }

  &.overlap-start {
    .rv-xy-plot__axis__ticks {
      .rv-xy-plot__axis__tick {
        &:first-child {
          text {
            text-anchor: end;
          }
        }
      }
    }

    &-equal {
      .rv-xy-plot__axis__ticks {
        .rv-xy-plot__axis__tick {
          &:first-child {
            text {
              fill: transparent !important;
            }
          }
        }
      }
    }

    &-deep {
      .rv-xy-plot__axis__ticks {
        .rv-xy-plot__axis__tick {
          &:nth-child(2) {
            text {
              transform: translate(30px, 12px);
            }
          }
        }
      }
    }
  }

  &.overlap-end {
    .rv-xy-plot__axis__ticks {
      .rv-xy-plot__axis__tick {
        &:last-child {
          text {
            text-anchor: start;
          }
        }
      }
    }

    &-equal {
      .rv-xy-plot__axis__ticks {
        .rv-xy-plot__axis__tick {
          &:last-child {
            text {
              fill: transparent !important;
            }
          }
        }
      }
    }

    &-deep {
      .rv-xy-plot__axis__ticks {
        .rv-xy-plot__axis__tick {
          &:nth-last-child(2) {
            text {
              transform: translate(-30px, 12px);
            }
          }
        }
      }
    }
  }
}
