.show-data {
    height: calc(100vh - 80px - 41px - 24px - 24px)!important;
    .ant-card {
        min-height: 100%;
        .checkbox-wrapper {
            width: 552px;
            margin-top: 16px;
            padding-bottom: 12px;
            border-bottom: 1px solid #EBEBEB;
        }
    }
    .ant-spin-sm > .ant-spin-dot {
        margin-top:-8px
    }
}