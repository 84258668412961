@import "styles/theme.scss";

.kfc-spoiler {
  & > h4 {
    cursor: pointer;

    .anticon {
      vertical-align: middle;
      transform: rotate(-180deg);
      transition: transform 0.2s ease;
      margin: -2px 8px 0;

      svg,
      svg * {
        fill: $text-helper;
      }
    }
  }

  &.open {
    & > h4 {
      .anticon {
        transform: rotate(0);
      }
    }
  }
}
