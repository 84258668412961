.chart {
  width: 23%;
  height: 100%;
  max-width: 310px;
  text-align: center;
}

.title {
  font-size: 22px;
  font-weight: 400;
}

.legend {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 14px;
  padding-left: 5%;

  .legend_item {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
  }

  .disabled {
    opacity: 0.35;
  }

  .legend_circle {
    width: 10px;
    height: 10px;
    min-width: 10px;
    border-radius: 50%;
  }
}

@media (min-width: 2000px) {
  .legend {font-size: 16px;}
}
