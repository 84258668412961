@media print {
  .global-sider,
  .app-header,
  .ant-layout-content > .view-type-switch {
    display: none;
  }

  .ant-layout {
    background-color: transparent;
  }

  .ant-layout-header {
    height: auto;
  }

  .ant-layout-content {
    & > .inner-content {
      padding: 0;
    }

    .tabbed-content {
      margin: 0;
    }
  }

  .ant-layout.ant-layout-has-sider {
    & > .ant-layout,
    & > .ant-layout-content {
      overflow: visible;
    }
  }
}
