.print-report .print-report-template.stations {
  td:last-child {
    border: none !important;
  }

  td:nth-last-child(2) {
    padding-right: 10px;
  }

  & > tbody {
    td {
      text-align: right;
      padding: 2px 0;
      border-bottom: 1px solid #eee;

      &:first-child {
        text-align: left;
      }
    }

    tr:first-child {
      font-weight: bolder;
    }

    tr:last-child {
      font-weight: bolder;

      td {
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
      }
    }
  }
}

.print-report {
  .print-report-comment {
    margin-top: 16px;
  }
}
