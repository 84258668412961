@import "styles/theme.scss";

.performance-card,
.rating-performance {
  .compare-goals {
    tr:first-child {
      font-weight: bold;
    }
  }

  .card-chart {
    margin-top: 16px;

    .rv-crosshair__line {
      border-top: 100px solid $text-helper;
    }
  }
}
