@import "styles/theme.scss";

.icon-link {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1.3;
  color: $text-link;
  margin-left: 32px;
  cursor: pointer;

  .anticon {
    margin-right: 7px;

    svg {
      &,
      path {
        fill: $text-link;
      }
    }
  }

  &:hover {
    color: $text-link-light;

    svg {
      &,
      path {
        fill: $text-link-light;
      }
    }
  }
}
