@import "styles/theme.scss";

.logo-container {
  flex: 0 0 $header-height;
  width: $sider-width;
  height: $header-height;
  background-color: $primary-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-left: -5px;
  }

  &.full {
    width: 100%;
    flex: 0 0 100%;

    img {
      margin: 0;
    }
  }
}
