@import "styles/theme.scss";

.data-value {
  white-space: nowrap;

  &.bad {
    color: $error-color;
  }

  &.good {
    color: $success-color;
  }
}

.ant-dropdown-menu-item {
  .data-value {
    float: right;
    margin-left: 16px;
  }
}
