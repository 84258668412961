.print-report .print-report-template.workedtime {
  & > thead {
    td {
      text-align: center;
    }
  }
  & > tbody {
    td {
      text-align: center;
      padding: 2px 0;
      border: 1px solid #eee;
    }
  }
}

.print-report {
  .print-report-comment {
    margin-top: 16px;
  }
}
