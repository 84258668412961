@import "styles/theme.scss";

.date-picker-week-dropdown {
  left: 0 !important;

  & > .ant-picker-panel-container {
    box-shadow: none;
    width: 280px;
  }
}

.week-field {
  visibility: hidden;
  //display: none;
  position: absolute;
  top: 20px;
}

.kfc-generate-schedule {
  width: 280px !important;

  .ant-modal-body {
    padding: 16px 0 0;
    height: 300px;

    .ant-form .ant-form-item {
      max-width: 280px;
    }

    & > div[style] {
      position: static !important;

      .ant-calendar-picker-container {
        position: static !important;
        transform: translateY(-52px) !important;
        margin-bottom: -53px;
        animation: none;
        border-top: 1px solid $shaded-background;
        padding-top: 24px;
        background-color: #fff;

        .ant-calendar-week-number {
          width: 280px;
          box-shadow: none;
          border: none;

          .ant-calendar-body {
            padding: 8px 0;

            tr.ant-calendar-active-week {
              background-color: $good-light;
            }

            th:nth-child(2),
            td:nth-child(2) {
              padding-left: 24px;
            }

            th:last-child,
            td:last-child {
              padding-right: 24px;
            }
          }
        }
      }
    }
  }

  .extra-footer {
    text-align: left;
    color: $text-color;

    .extra-actions .link {
      margin-right: 16px;
    }
  }

  .buttons-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -26px;

    &:first-child {
      min-width: 269px;
    }

    .ant-btn {
      margin: 0 0 5px 0 !important;
    }
  }
}

.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
}

.ant-calendar-header {
  .ant-calendar-century-select,
  .ant-calendar-decade-select,
  .ant-calendar-year-select,
  .ant-calendar-month-select {
    color: $text-link;
    font-weight: normal;
  }
}
