.print-report-template.statistics-summary {
  width: 100%;
  table-layout: fixed;

  & > thead {
    & > tr:nth-child(2) {
      td {
        text-align: left;
        border-bottom: 2px solid #000;
        font-weight: normal;
        white-space: nowrap;

        &:empty {
          border-bottom: none;
        }
      }
    }
  }

  & > tbody {
    td {
      padding: 5px;
      text-align: right;
      border-bottom: 1px solid #ccc;
      white-space: nowrap;

      &:first-child {
        padding-left: 0;
        text-align: left;
        width: 18% !important;
      }

      &:last-child {
        padding-right: 0;
      }

      &:empty {
        width: 2%;
      }

      &.offset {
        padding-top: 20px;
      }
    }

    tr:first-child,
    tr:nth-child(2),
    tr:nth-child(3) {
      td {
        font-weight: bold;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
