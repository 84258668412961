@import "styles/theme";

.staff-fixed {
  .fixed-search {
    width: 250px;
    margin-bottom: 24px;
    margin-top: 8px;
  }

  .report-wrap {
    .ant-table-wrapper {
      margin-top: 0;
    }

    .ant-table-cell {
      .ant-select-selector, .ant-picker {
        border: 1px solid transparent;
      }
    }
    .ant-table-cell-row-hover {
      .ant-select-selector, .ant-picker {
        border-color: $dark-border;;
      }
    }
  }

  .ant-table table {
    width: auto;

    .ant-table-tbody > .ant-table-expanded-row-level-1 > td {
      border: none;
      background: none;

      .ant-table-tbody > .ant-table-row-level-0:first-child td {
        border-top: 1px solid $border-color;
      }
    }

    .ant-table-tbody > .ant-table-row-level-0:first-child td {
      border-top: none;
    }

    .ant-table-row-expand-icon-collapsed::after {
      transform: rotate(180deg);
    }

    .ant-table-row-expand-icon-expanded::after {
      transform: rotate(0deg);
    }
  }
}
