@import "styles/theme.scss";

.login-card {
  width: 440px;
  height: 500px;

  .ant-card-body {
    height: 100%;
    padding: 60px;
  }

  .ant-layout,
  .ant-layout-header {
    background-color: transparent;
  }

  .ant-layout-header {
    height: auto;
  }
}
