@import "styles/theme.scss";

.ant-layout-content > .view-type-switch {
  .ant-tabs-bar {
    border-bottom: none;
  }
}

.view-type-switch {
  margin-top: 1px;
  background-color: $panel-background;

  .view-type-switch {
    margin-top: 0;
  }

  & + .report-wrap {
    margin-top: 24px;
  }

  &.wide {
    .ant-tabs-nav-list {
      width: 100%;
    }
  }

  .ant-tabs-bar {
    margin-bottom: 0;
  }

  .ant-tabs-nav-container-scrolling {
    .ant-tabs-nav {
      padding: 0;
    }
  }

  .ant-tabs {
    color: $text-faded;
    height: 100%;
  }

  .ant-tabs-nav-wrap {
    padding: 0 25px;
    //margin-bottom: 0;
  }

  .ant-tabs-nav {
    //padding: 0 25px;
    margin-bottom: 0;
    background-color: $panel-background;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      width: 100%;
    }

    .ant-tabs-tab {
      flex: 1 1 0;
      text-align: center;
      margin: 0;
      padding: 11px 16px;
      justify-content: center;
      font-size: 14px;
      color: $text-helper;

      &.ant-tabs-tab-active {
        color: $text-color;
      }

      .ant-tabs-tab-btn {
        color: inherit;
        text-shadow: none;
      }
    }

    .ant-tabs-ink-bar {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &.left {
    .ant-tabs-nav {
      width: auto;
      height: 100%;
    }
  }

  &.fixed {
    height: 100%;
    margin: 0 -24px;

    & > .ant-tabs {
      min-height: 100%;
      display: flex;
      flex-direction: column;

      & > .ant-tabs-bar {
        margin: 0 20px;
      }

      & > .ant-tabs-nav .ant-tabs-tab {
        padding: 0 16px 10px;
      }

      & > .ant-tabs-content-holder > .ant-tabs-content {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        height: 100%;

        & > .ant-tabs-tabpane {
          flex: 0 1 100%;
          flex-direction: column;
          padding: 20px 5px 0 20px;

          &-active {
            flex: 1 0 100%;
            display: flex;
          }

          & > div[style]:not([role]) {
            flex: 1 1 100%;
          }
        }
      }
    }
  }
}
