.local-time {
  text-align: right;
  margin-left: auto;
  position: relative;
  display: flex;
  gap: 10px;

  & + .restaurant-title {
    margin-left: 32px;
  }

  p,
  h3 {
    line-height: 1;
    margin: 0 0 8px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    font-weight: bolder;
  }
}
