@import "styles/theme.scss";

.dashboard-cards-container {
  .ant-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;

    & > div {
      padding-bottom: $small-padding;
    }
  }
}
