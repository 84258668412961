.session-timeout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.38);
  padding: 9px 0;
  margin-top: 8px;
  color: transparentize($color: #ffffff, $amount: 0.4);
  font-size: 11px;

  svg {
    opacity: 0.6;
    margin-right: 0.5em;
    transform: translateY(-1px);
  }
}
