@import "styles/theme.scss";

.staff-schedule-table {
  max-width: unset;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: sticky;
  top: 26px;
  z-index: 10;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: content-box;
  background-color: #fff;

  .turnover-changed {
    display: block;
    margin-left: -24px;
    color: $text-helper;

    .anticon {
      margin-right: 8px;
    }

    svg * {
      fill: $text-helper;
    }
  }

  table {
    colgroup {
      display: none;
    }

    th,
    td {
      width: 60px;

      &:first-child {
        width: 210px;
      }
      &:nth-child(2) {
        width: 120px;
      }
      &:nth-child(3) {
        width: 182px;
      }
    }
  }

  td.col-hour {
    white-space: nowrap;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-select {
    font-size: 12px;

    .ant-select-selection {
      color: $text-helper;
    }
  }
}
