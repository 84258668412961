@import "styles/theme.scss";

.force-mc {
  font-weight: normal;
  color: $primary-color;
}

.mc-warning {
  transform: scale(1);
  transition: transform 0.4s cubic-bezier(0.62, 2.66, 0.22, 0.47) 0.2s,
    top 0.2s ease;
  margin-top: 5px;
  z-index: 999;

  &.ant-tooltip-hidden {
    display: block;
    transform: scale(0);
    transition: transform 0.2s ease;

    &.zoom-big-fast-enter {
      opacity: 1;
      transform: scale(0);
      transition: transform 0.2s ease;
    }
  }

  .ant-tooltip-inner {
    background-color: $primary-color;
  }
  .ant-tooltip-arrow:before {
    background-color: $primary-color;
  }
}
