.tasks-editor {
  .ant-card-body {
    width: 100%;
    display: flex;
    flex-direction: column;

    .report-table {
      flex: 1 1 100%;

      .ant-table-thead .ant-table-cell {
        background: transparent;
      }
    }
  }

  .report-table {
    padding-bottom: 24px;

    table {
      width: 100%;

      td,
      th {
        padding-left: 16px;

        &:first-child {
          min-width: 120px;
        }

        &:last-child,
        &:nth-last-child(2),
        &:nth-last-child(3) {
          width: 200px;
        }

        &:nth-child(3) {
          white-space: normal;
        }

        &:nth-child(4) {
          white-space: normal;
        }
      }
    }

    .ant-table-tbody > tr > td:first-child {
      white-space: normal;
    }

    thead tr {
      position: sticky;
      top: 0;
    }
  }
}

.ant-select{
  .ant-select-arrow{
    display: none;
  }
}

.ant-select:hover{
  .ant-select-arrow{
    display: inline-block;
  }
}