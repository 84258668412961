@import "styles/theme.scss";

.siv-block-report {
  padding-bottom: 24px;
  cursor: pointer;
  height: 210px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: transparentize($color: $shaded-background, $amount: 0.7);
  }

  .siv-chart {
    position: relative;
    padding: 16px 0;
    height: 128px;

    .rv-xy-plot {
      margin: 0 auto;
      opacity: 0.5;
    }

    .siv-overall {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    h3 {
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      color: $text-helper;
    }

    h4 {
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      color: $text-helper;
    }
  }

  p {
    margin: 0;
  }

  .siv-tasks-count {
    margin-bottom: 8px;
    height: 1.2em;
  }

  .all-done {
    transform: translateY(-2px);
    background-color: $text-faded;
    margin-left: 0;

    &.done {
      background-color: $success-color;
    }
  }

  .tasks-delta {
    display: inline-block;
    margin-left: 0.5em;

    &.good {
      color: $success-color;
    }

    &.bad {
      color: $error-color;
    }
  }
}
