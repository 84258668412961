.ppr-employee-data {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    th {
        width: 25%;
    }
    th, td {
        /* width: unset!important; */
        &:nth-child(3), &:nth-child(4) {
            width: 80px;
        }
    }
    th:first-child {
        width: 80px;
    }
    th:nth-child(2) {
        width: 280px;
    }
    th:nth-child(6) {
        width: 80px;
    }
    th:nth-child(5) {
        width: 600px;
    }
    td:nth-child(n+5):nth-child(-n+16) {
        width: 50px;
    }
}