@import "styles/theme.scss";

.dishes-list {
    height: 100%;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: $default-padding;

    .add-row-control {
        display: flex;
        width: 165px;
        height: 37px;
    }

    .ant-list-item {
        height: auto;
        padding: 10px 0 10px 16px;
        color: $text-color;

        .item-title {
            margin-right: 8px;
        }

    .combo-tag {
      display: inline-block;
      background-color: $shaded-background;
      text-transform: uppercase;
      padding: 0 6px;
      border-radius: 4px;
      font-size: 11px;
    }

    small {
      display: block;
      color: $text-helper;
      font-size: 12px;
      margin-top: 2px;
    }

    .ant-row {
      width: 100%;
    }

    .anticon {
      opacity: 0;
      transition: opacity 0.2s ease;
      display: inline-block;

      &.remove-icon {
        transform: translateY(-3px);

        svg * {
          fill: $error-color;
        }
      }
    }

    &:hover {
      .anticon {
        opacity: 1;
      }
    }
  }
}