@import "styles/theme.scss";

.controls-pager {
  display: flex;
  flex-direction: row;

  .pager-arrow {
    display: inline-block;
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    border: 1px solid $text-faded;
    position: relative;
    transition: border-color 0.2s ease;

    &:after {
      content: "";
      width: 8px;
      height: 8px;
      border: 2px solid $text-faded;
      border-top: none;
      border-right: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      margin-left: 2px;
      transition: border-color 0.2s ease;
    }

    &.arrow-prev {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: none;
    }

    &.arrow-next {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-left: -1px;
      border-left-color: transparent;

      &:after {
        transform: translate(-50%, -50%) rotate(-135deg);
        margin-left: -2px;
      }
    }

    &.active {
      border-color: $text-link;
      cursor: pointer;

      &:after {
        border-color: $text-link;
      }
    }

    &.disabled {
      border-color: $text-faded;
      cursor: not-allowed;
      opacity: 0.5;

      &:after {
        border-color: $text-faded;
      }
    }
  }

  .pager-action {
    display: inline-block;
    flex: 1 0 34px;
    min-width: 34px;
    height: 24px;
    border: 1px solid $text-link;
    cursor: pointer;
    position: relative;
    transition: border-color 0.2s ease;
    text-align: center;
    line-height: 22px;
    font-size: 12px;
    color: $text-helper;
    padding: 0 8px;

    &.no-action {
      color: $text-link;
    }

    &.disabled {
      border-color: $text-faded;
      cursor: not-allowed;
      opacity: 0.5;
    }

    .anticon {
      margin-top: 2px;
    }

    & + .pager-arrow.active {
      border-left: none;
    }

    svg,
    svg * {
      fill: $text-link;
    }
  }
}
