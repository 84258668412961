.tasks-report {
  padding: 16px 0 0 32px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .reports-controls {
    flex: 0 0 auto;
  }

  .managers-tasks-list {
    flex: 1 1 100%;
  }
}
