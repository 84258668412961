.ppr-director {
    min-width: 1050px;
    min-height: 300px;
    padding-bottom: 60px;
    display: block!important;
    position: relative;

    .ppr-controls-header {
        background: #fff;
        display: flex;
        align-items: center;
        .header-title {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
        }

        .header-picker {
            align-items: center;
            .reports-controls, .reports-controls-header {
                height: 24px;
                padding: 0;
            }
            .dates-fake#text {
                display: none;
            }
        }
    }
}