.print-report-template.statistics-days {
  width: 100%;
  table-layout: fixed;

  & > thead {
    & > tr:nth-child(2) {
      td {
        white-space: nowrap;
        vertical-align: bottom;
        padding: 5px 0;
        border-bottom: 2px solid #000;
        font-weight: normal;

        &:empty,
        &:first-child {
          border-bottom: none;
        }
      }
    }

    & > tr:nth-child(3) {
      td {
        font-weight: bold;
        border-bottom: none;
        padding-top: 8px;
        text-align: right;
      }
    }
  }

  & > tbody {
    td {
      padding: 3px;
      text-align: right;
      border-bottom: 1px solid #ccc;

      &:first-child {
        color: #999;
        text-align: left;
        width: 5%;
      }

      &:last-child {
        color: #999;
      }

      &:empty {
        width: 3%;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
