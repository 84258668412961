@import "styles/theme.scss";

.time-tag {
  background-color: transparent;
  border: none;
  margin-left: -10px;
  cursor: default;
  color: $text-helper;
  padding: 0 10px;
  font-size: 14px;
}
