@import "styles/theme.scss";

.tasks-full-report {
  flex: 0 0 calc(100% - 24px);
  display: flex;
  flex-direction: column;

  .repeat-sign {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    margin-top: 4px;
  }

  .result-footer {
    position: fixed;
    left: 111px;
    padding: 0 40px;
    bottom: 0;
    background-color: $panel-background;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;

    .add-action-plan {
      display: block;
      margin: 16px 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      .anticon {
        margin-right: 8px;
      }
    }
  }

  .full-report-staff,
  .full-report-deviations {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h5 {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    p {
      margin: 0 0 6px 0;
      font-size: 14px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.87);
    }

    h6,
    small {
      display: block;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 16px;
    }

    .deviation {
      margin-bottom: 24px;

      small {
        margin-top: 4px;
      }
    }
  }

  .full-report-staff {
    padding-left: 0;
  }

  .tasks-full-report-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $border-color;
    position: relative;
    z-index: 3;

    .header-date {
      padding: 16px 40px;
      border-right: 1px solid $border-color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 40px;

      .reports-controls {
        padding: 0 16px;
        width: 148px;
        margin: 0;
        transform: translateY(6px);
        z-index: 1;

        .reports-controls-header {
          padding: 0;

          div[style] > div > div {
            z-index: 99;
          }
        }
      }

      .arrow-next {
        margin-left: 0px;
        border-left-color: $text-faded;
        pointer-events: none;

        &.active {
          pointer-events: all;
        }
      }
    }

    .full-report-states {
      display: flex;
      flex-direction: row;
      margin: 0 16px;

      li {
        padding: 0 24px;
        border-right: 1px solid $border-color;
        color: $text-color;

        &:last-child {
          border-right: none;
        }

        span {
          margin-left: 0.5em;

          &.highlight {
            display: inline-block;
            vertical-align: middle;
            padding: 2px 8px;
            border-radius: 4px;

            &.good {
              background-color: transparentize(
                $color: $success-color,
                $amount: 0.5
              );
            }

            &.bad {
              background-color: transparentize(
                $color: $error-color,
                $amount: 0.5
              );
              color: #fff;
            }

            &.mild {
              background-color: $mild-color;
            }
          }
        }
      }
    }
  }

  .siv-blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 40px;
    flex: 0 0 auto;

    .siv-block {
      margin-left: 24px;
      flex: 0 0 calc(25% - 24px);

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .empty-siv {
    padding: 24px 24px;
    display: block;
  }

  .full-report-bottom {
    padding: 0;
    margin: 0 40px;
    border-top: 1px solid $border-color;
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;

    & > * {
      flex: 0 0 33.3%;
      border-right: 1px solid $border-color;

      &:nth-last-child(2),
      &:last-child {
        border-right: none;
      }
    }

    .selected-tasks {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      li {
        padding: 0;
        border-right: 1px solid $border-color;
        flex: 0 0 auto;
        box-sizing: content-box;

        .task-group {
          padding: 24px 16px 16px 24px;

          .group-tasks {
            margin-left: -24px;
            margin-right: -16px;

            .manager-task {
              cursor: pointer;

              &:hover {
                h5 {
                  color: $text-link;
                }
              }
            }

            .manager-task,
            .empty-group {
              display: block;
              padding-left: 24px;
              padding-right: 16px;

              &[class*="mod-sub"]:not(.mod-sub-todo) {
                padding-left: 48px;

                &:before {
                  left: 24px;
                }
              }
            }
          }
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .selected-arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: $panel-background;
    transform: translate(-50%, 15px) rotate(45deg);
    border: 1px solid $border-color;
    border-right: none;
    border-bottom: none;
  }
}
