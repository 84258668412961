.positions {
    .ant-checkbox.ant-checkbox-disabled {
        .ant-checkbox-inner {
            background-color: #fff;
            border-color: #d9d9d9 !important;
        }
    }
    .ant-checkbox-checked.ant-checkbox-disabled {
        .ant-checkbox-inner {
            background-color: #1890ff;
            border-color: #1890ff !important;
            &::after {
                border-color: #fff !important;
            }
        }
    }
    aside {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.87);
    }
}
