.schedule-summary {
  margin-top: 10px;
  width: auto !important;

  td {
    padding-right: 16px;

    &:first-child {
      font-weight: bold;
    }
  }
}
