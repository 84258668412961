.chart-editor {
    padding-top: 16px;
    padding-bottom: 16px;
    .ant-tabs-content {
        min-height: 100px;
    }

  .staff-manning-table {
    .ant-table-content::-webkit-scrollbar {
      display: none;
    }
  }
}
