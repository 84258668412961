@import "../../../styles/theme.scss";

.custom-tab {
    display: flex;
    align-items: center;
    user-select: none;
    .ant-btn {
        padding: 0;
    }
    .custom-tab-icon {
        margin-left: 8px;
        margin-bottom: -4px;
        i {
            margin-right: 8px;
        }
    }

    .custom-tab-title {
        padding-left: 8px;
    }
}
.extended-tabs {
    .ant-tabs-tab {
        padding-right: 0;
    }
}

.ant-tabs-tab {
    .custom-tab-icon {
        cursor: not-allowed;
        button {
            path {
                fill: $text-link;
            }
        }
        button:disabled {
            path {
                fill: $text-faded;
            }
        }
    }
}

.ant-tabs-tab .anticon {
    margin-right: 0px;
}

/* .ant-tabs-tab.ant-tabs-tab-active {
    .custom-tab-icon {
        cursor: pointer;
        button {
            path {
                fill: $text-link;
            }
        }
    }
} */
