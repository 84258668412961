@charset "UTF-8";
@import "styles/theme.scss";

.ant-card-body > .report-table > .report-wrap,
.ant-card:not(.report-table) > .ant-card-body > .ant-tabs,
.ant-card:not(.report-table) > .ant-card-body > .view-type-switch > .ant-tabs {
  margin-left: -24px;
  margin-right: -24px;
  box-sizing: content-box;
  max-width: unset;

  & .ant-tabs-tab {
    padding: 12px 16px 12px 16px;
    text-align: center;
    justify-content: center;
    margin: 0
  }

  & .ant-tabs-tab-btn {
    color: inherit;
    text-shadow: none;
  }

  & > .ant-tabs-bar,
  & > .ant-tabs-nav {
    padding-left: 24px;
    padding-right: 24px;
  }
}

td .gross-rest {
  background-color: #e0e0e0;
  padding: 5px;
}

.report-wrap {
  td .user-avatar {
    margin-top: 0;
  }

  td.spoiler-row ~ td {
    font-size: 0;
    color: transparent;
    background-color: transparent;
    pointer-events: none;

    * {
      display: none;
    }
  }

  .ant-alert-info {
    background-color: transparent;
    border: none;
    margin-left: 0;
    padding-left: 24px;
  }

  .ant-table-placeholder {
    margin-top: 1px;
  }

  .only-header {
    .ant-table-placeholder {
      display: none;
    }

    .ant-table-thead > tr:first-child > th:first-child {
      padding-left: 57px;
    }
  }

  .ant-table-header {
    background-color: transparent;
  }

  .ant-table {
    .delta-turnover {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }

    table.ant-table-thead,
    table.ant-table-tbody {
      border-collapse: separate;
      width: auto;
      max-width: 100%;

      td {
        vertical-align: middle;

        .new-rest {
          &:after {
            content: "Новый";
            display: inline-block;
            padding: 2px 6px;
            border-radius: 4px;
            background-color: #8fc2ff;
            color: #fff;
            font-size: 11px;
            margin-left: 8px;
          }
        }
      }

      td,
      th {
        &.col-sep {
          border-left: 1px solid $border-color;
          padding-right: 15px;
        }

        &.nopad {
          padding: 0;
        }
      }
    }
  }

  //.ant-picker {
  //  width: 60px;
  //  margin-left: -5px;
  //  margin-right: -7px;
  //  border: none;
  //}

  .view-type-drop-dd {
    max-height: 200px;
    overflow: auto;
    min-width: 200px !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  td {
    .view-type-drop {
      padding: 0;
      min-height: 0;

      & > span.link {
        margin: -5px 0 -5px -12px;
      }
    }

    .ant-input[type="number"],
    .ant-input-number {
      width: 80px;
    }

    .ant-calendar-picker,
    .ant-input[type="number"],
    .ant-input[type="text"],
    .ant-input-number {
      margin-left: -5px;
    }

    .ant-time-picker-input,
    .ant-calendar-picker-input,
    .ant-select-selection,
    .ant-input[type="number"],
    .ant-input[type="text"],
    .ant-input-number {
      border-color: transparent;
      background-color: transparent;
      cursor: default;
      color: $text-color;
      margin: 2px 0px;

      &[disabled],
      &[disabled]:hover {
        color: $text-faded !important;
        -webkit-text-fill-color: $text-faded !important;
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }

    .ant-select-selection__rendered {
      margin-left: 7px;
      margin-right: 7px;
    }

    .ant-select {
      width: 100%;
      transform: translateX(-8px);

      &.ant-selectt-focused .ant-select-selection {
        border-color: #d9d9d9;
        background-color: #fff;
      }
    }
    .ant-select-selector, .ant-picker, .show-popup-wrapper, .ant-input {
      border: 1px solid transparent !important;
      &:hover {
        border: 1px solid #40a9ff !important;
        transition: border 1s
      }
    }

    .ant-select-selector, .ant-picker {
      border: 1px solid transparent !important;
      &:hover {
        border: 1px solid #40a9ff !important;
        transition: border 1.3s
      }
    }

    .ant-input[type="text"] {
      margin-left: -12px;
    }

    .ant-select-selection {
      margin-left: 0;

      .ant-select-arrow-icon {
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &:hover {
        .ant-select-arrow-icon {
          opacity: 1;
        }
      }
    }

    .ant-select-disabled {
      .ant-select-selection {
        &,
        &:hover {
          color: $text-color;
          border-color: transparent;

          .ant-select-arrow-icon {
            opacity: 0;
          }
        }
      }
    }
  }

  .ant-input-number.currency {
    width: 100%;
    min-width: 80px;

    .ant-input[type="number"] {
      width: 100%;
      border-color: #d9d9d9;
      background-color: #fff;

      & + .ant-input-group-addon {
        transform: translateX(-10px);
      }
    }

    &.ant-input-number-disabled {
      opacity: 0.5;
    }
  }

  .ant-table-row-hover,
  .ant-table-row:hover {
    .ant-time-picker-input,
    .ant-calendar-picker-input,
    .ant-select-selection,
    .ant-input[type="number"],
    .ant-input[type="text"],
    .ant-input-number {
      &:not(.ant-input-number-disabled) {
        border-color: #d9d9d9;
        background-color: #fff;
      }
    }
  }

  //.ant-time-picker-input {
  //  padding-left: 7px;
  //  padding-right: 7px;
  //}

  .ant-time-picker-icon,
  .ant-time-picker-clear {
    display: none;
  }

  td > p {
    small {
      display: block;
      color: $text-helper;
    }
  }

  td[colspan] ~ td {
    display: none;
  }

  .ant-table-tbody tr > td.ant-table-row-expand-icon-cell {
    padding-right: 3px;

    & + td {
      padding-left: 5px;
    }
  }

  tr.ant-table-row.ant-table-row-level-0 {
    & > td {
      cursor: pointer;

      .collapse-header {
        font-size: 12px;
        color: $text-helper;
      }
    }
  }

  .ant-table-row-expand-icon {
    border: none;
    position: relative;
    background-color: transparent;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 4px 0 4px;
      border-color: $text-faded transparent transparent transparent;
      position: absolute;
      left: 0;
      top: 50%;
      background: transparent;
    }

    &:before {
      display: none;
    }

    &.ant-table-row-expanded:after {
      transform: rotate(180deg);
    }
  }

  tr.ant-table-expanded-row,
  tr.ant-table-expanded-row:hover {
    background-color: transparent;

    & > td {
      padding: 0 !important;

      & > .ant-table-wrapper {
        padding: 0;
        margin: 15px 0 20px -30px;

        th:first-child,
        td:first-child {
          padding-left: 55px;
        }
      }

      &:last-child {
        padding-right: 0 !important;
      }
    }
  }

  .remove-row-control,
  .add-row-control {
    opacity: 0;
  }

  tr > td {
    .select-wrapper {
      display: block;
      height: 100%;

    }

  }

  tr:hover > td {
    & > .remove-row-control,
    & > .add-row-control {
      opacity: 1;
    }


    .show-popup-wrapper {
      background-color: #fff;

      &:after {
        opacity: 1;
      }
    }
  }
}

.remove-row-control {
  opacity: 1;
  transition: opacity 0.2s ease;
  float: right;
  cursor: pointer;
  font-size: 14px;
  color: $text-faded;
  white-space: nowrap;

  &.left {
    float: none;
  }

  .anticon {
    margin-right: 5px;
    vertical-align: middle;

    svg,
    svg * {
      fill: $text-faded;
    }
  }

  &:hover {
    color: $error-color;

    .anticon svg,
    .anticon svg * {
      fill: $error-color;
    }
  }
}

.render-as-label {
  color: $text-helper;
  font-size: 12px;
  border-bottom: none !important;
  display: block;
  width: 100%;
  position: relative;

  &.spoiler {
    height: 21px;
    line-height: 1.8;
    cursor: pointer;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      border-color: $text-faded transparent transparent transparent;
      transition: transform 0.3s ease;
      transform-origin: 4px 2px 0;
      margin-right: 4px;
      margin-left: -14px;
      transform: rotate(0);
      position: absolute;
      left: -2px;
      top: 50%;
      margin-top: -2px;
    }

    &.open,
    &:focus {
      &:before {
        transform: rotate(-180deg);
      }
    }
  }

  &.inner {
    padding-left: 24px;
  }
}

.schedule-cell {
  display: block;
  height: 12px;
  margin-left: -1px;
  background-color: $green-line;
  position: relative;

  .fixed-icon {
    width: 24px;
    height: 24px;
    background-color: $green-line;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    svg {
      width: 15px;
      height: 15px;
    }

    svg,
    svg * {
      fill: #fff;
    }
  }

  &.start {
    float: right;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &.time-0 {
      width: 96%;
    }

    &.time-30 {
      width: 45%;
    }

    &.time-15 {
      width: 70%;
    }

    &.time-45 {
      width: 20%;
    }
  }

  &.pre {
    &.time-0 {
      width: 95%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.start {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      &.time-end-15,
      &.time-end-30,
      &.time-end-45 {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &.end {
    &.time-0 {
      display: none;
    }

    &.time-15 {
      width: 20%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.time-30 {
      width: 45%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.time-45 {
      width: 70%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.time-59 {
      width: 95%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

td.col-sep.nopad {
  padding: 0 !important;
}

.ant-table-scroll {
  table .ant-table-fixed-columns-in-body[colspan] {
    visibility: visible;
  }
}

.ant-table-fixed-left,
.ant-table-fixed-right {
  box-shadow: none;

  .ant-table-body-inner {
    transform: translateX(-1px);
    margin-right: 0;
    padding-right: 0;
  }
}

.col-hoverable {
  .ant-table-body {
    overflow-y: hidden;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row),
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row),
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row),
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) {
    & > td {
      background-color: transparent;

      &.ant-table-cell-fix-left {
        background-color: $panel-background;
      }

      &.col-hover {
        position: relative;

        &:hover {
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 9999px;
            background-color: #e3ecec;
            pointer-events: none;
            z-index: 0;
            opacity: 0.2;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.ant-table-tbody > tr {
  & > td {
    transition: none;
  }

  &.row-error,
  &.row-error > td,
  &.row-error:hover > td,
  &.row-error.ant-table-row-hover > td {
    background-color: $bad-light;

    .schedule-cell {
      background-color: $error-color;

      .fixed-icon {
        background-color: $error-color;
        border-color: $bad-light;
      }
    }
  }

  &.row-inactive,
  &.row-inactive > td &.row-inactive:hover > td,
  &.row-inactive.ant-table-row-hover > td {
    color: $text-helper;

    .ant-calendar-picker-input,
    .ant-input[type="number"],
    .ant-input[type="text"],
    .ant-input-number,
    .ant-select-selection {
      color: $text-helper;
    }
  }

  &.row-current,
  &.row-current > td &.row-current:hover > td,
  &.row-current.ant-table-row-hover > td {
    background-color: $hint-background !important;
  }

  &.row-warning,
  &.row-warning > td,
  &.row-warning:hover > td,
  &.row-warning.ant-table-row-hover > td {
    background-color: $warning-color;
    color: $text-helper;

    input,
    .ant-select-selection {
      color: $text-helper;
    }

    .schedule-cell {
      background-color: $text-faded;
    }
  }

  &.row-warning:hover > td,
  &.row-warning.ant-table-row-hover > td {
    color: $text-color;

    input,
    .ant-select-selection {
      color: $text-color;
    }
  }


  &.row-overworked,
  &.row-overworked > td,
  &.row-overworked:hover > td,
  &.row-overworked.ant-table-row-hover > td {
    .schedule-cell {
      background-color: #f5a623;

      .fixed-icon {
        background-color: #f5a623;
        border-color: $bad-light;
      }
    }
  }

  &.row-underage,
  &.row-underage > td,
  &.row-underage:hover > td,
  &.row-underage.ant-table-row-hover > td {
    background-color: #f8eefe;

    .schedule-cell {
      background-color: #cd8bf6;

      .fixed-icon {
        background-color: #cd8bf6;
      }
    }
  }
}

.report-table {
  h2 {
    margin-top: 8px;
  }

  .aside-container {
    display: flex;
    flex-direction: row;

    & > * {
      flex: 1 1 100%;
    }

    & > aside {
      padding: 28px 40px;

      & > span {
        display: inline-block;
        width: 220px;
      }
    }
  }

  &.offset + .report-table {
    margin-top: 44px;
  }

  &.left-off {
    .ant-table-tbody {
      tr {
        th,
        td {
          &:first-child {
            padding-left: 16px;
          }
        }
      }
    }
  }

  .ant-card-body {
    padding: 0;

    .ant-tabs-bar {
      padding: 16px 0 0;
      margin: 0;
    }
  }

  .ant-table-wrapper {
    &:first-child {
      margin-top: 20px;
    }
  }

  .ant-table-empty .ant-table-body {
    overflow: hidden !important;
  }

  .ant-table-empty .ant-table-scroll .ant-table-body {
    overflow-x: scroll !important;
  }

  .ant-table-thead,
  .ant-table-tbody {
    & > tr {
      &.empty {
        & > td {
          color: $text-helper;
        }
      }

      &.drop-over-downward td {
        border-bottom: 40px solid transparent;
      }

      &.drop-over-upward td {
        border-top: 40px solid transparent;
      }

      &.dragging {
        display: none;
      }

      & > td,
      & > th {
        text-align: left;
        padding: 7px 16px 7px 16px;
        white-space: nowrap;

        &:first-child {
          padding-left: 32px;
        }

        &:last-child {
          padding-right: 24px;
          white-space: normal;
        }

        &.numeric,
        &.align-right {
          text-align: right;
        }

        &.numeric {
          white-space: nowrap;
        }

        &.align-center {
          text-align: center;
          padding-left: 16px;

          .remove-row-control {
            float: none;
          }
        }
      }

      & > th {
        &.nopad,
        &.col-hover {
          text-align: center;
        }
      }
    }
  }

  .ant-table-thead {
    & > tr {
      &:nth-child(2) {
        & > th {
          padding-top: 0 !important;
          padding-bottom: 0 !important;

          & > div {
            transform: translateY(-10px);
            height: 0;
          }

          &.ant-table-column-has-actions.ant-table-column-has-sorters {
            &.numeric,
            &:first-child,
            &:last-child,
            &.numeric:last-child {
              padding-left: 42px;
              padding-right: 0 !important;
            }

            &.ant-table-column-sort {
              padding-left: 24px;
              padding-right: 0 !important;

              &:last-child {
                padding-right: 0 !important;

                &.numeric {
                  padding-left: 24px;
                }
              }
            }
          }
        }
      }

      & > th {
        color: $text-helper;
        font-size: 12px;
        border-bottom: none !important;

        &.space-cell {
          padding: 0 !important;
        }

        &[colspan] {
          padding: 0;
          padding-left: 24px;
          text-align: center;
        }

        .timeHead {
          background-color: $shaded-background;
          margin-left: 5px;
          padding: 2px 7px;
          border-radius: 20px;
          font-size: 10px;
          color: $text-color;
          display: inline-block;
        }

        .short-head {
          width: 90px;
          overflow: hidden;
          display: inline-block;
          vertical-align: middle;
          text-overflow: ellipsis;
        }

        &.ant-table-column-has-actions.ant-table-column-has-sorters {
          padding-left: 24px;
          padding-right: 12px !important;
          transition: color 0.2s ease;

          &:hover {
            background-color: initial;
            color: $text-color;
          }

          &.numeric {
            padding-left: 42px;
            padding-right: 0 !important;
          }

          &:first-child {
            padding-left: 24px;
            padding-right: 12px !important;
          }

          &:last-child {
            padding-left: 24px;
            padding-right: 42px !important;

            &.numeric {
              padding-left: 42px;
              padding-right: 24px !important;
            }
          }

          &.ant-table-column-sort {
            padding-left: 24px;
            padding-right: 0 !important;

            &:last-child {
              padding-right: 24px !important;

              &.numeric {
                padding-left: 24px;
              }
            }

            .ant-table-column-sorter {
              width: 12px;
              margin-left: 5px;

              .anticon {
                visibility: hidden;

                &.active {
                  visibility: visible;
                }

                svg {
                  fill: $text-color;
                }
              }
            }
          }
        }

        .ant-table-column-sorters {
          white-space: nowrap;

          &:hover {
            &::before {
              background-color: initial;
            }
          }
        }

        .ant-table-column-sorter {
          position: static;
          display: inline-block;
          vertical-align: middle;
          margin-top: -1px;
          width: 0;
          transition: none;

          .anticon {
            visibility: hidden;
            color: $text-color;

            &.active {
              visibility: visible;
            }
          }


          .off {
            display: none;
          }

          .on {
            color: $text-faded;
            height: auto;
            display: inline-block;
            margin: 0;
          }
        }
      }
    }
  }

  .ant-table-tbody {
    color: $text-color;
    font-size: 14px;

    & > tr:first-child {
      td {
        border-top: 1px solid $border-color;
      }
    }

    .cell-good {
      color: $success-color;
    }

    .cell-bad {
      color: $error-color;
    }
  }

  .ant-tabs-nav {
    padding: 0 24px;

    & > div {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .ant-tabs-tab {
      flex: 1 1 0;
      text-align: center;
    }
  }

  .filter-container {
    padding: 20px 20px 0;
    width: 324px;
    display: inline-block;
  }
}

.percent-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    margin-right: 16px;
    flex: 0 0 50px;
    text-align: right;
  }

  .ant-progress-inner {
    height: 16px;
    flex: 1 1 100%;
    background-color: $shaded-background;
    border-radius: 4px;
  }

  .ant-progress-bg {
    height: 16px !important;
    border-radius: 4px;
  }
}

.hit-percentage {
  color: $text-faded;
}

.show-popup-wrapper {
  .show-popup-icon {
    display: inline-block;
    vertical-align: middle;
    margin: 0 8px;
  }

  .show-popup-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    width: 1em;
  }
}

.show-popup-wrapper {
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px 40px 4px 8px;
  background-color: transparent;
  position: relative;
  transition: border-color 0.2s ease, background 0.2s ease;
  height: 30px;

  &:after {
    content: "";
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 3px;
    background-color: $text-helper;
    border-radius: 4px;
    box-shadow: 5px 0 0 $text-helper, -5px 0px 0px $text-helper;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
}

.check-date {
  .ant-checkbox-wrapper + .ant-picker {
    display: none;
    margin-left: 10px;
  }

  .ant-checkbox-wrapper-checked + .ant-picker {
    display: inline-block;
  }
}

.cell-lock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  svg {
    margin-right: 0.5em;
    margin-top: -2px;
  }
}

.cell-range {
  color: $text-helper;
}

.td-popover {
  display: block;
  position: relative;
  white-space: nowrap;

  em {
    display: inline-block;
    max-width: calc(100vw - 1300px);
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    margin-right: 24px;
    margin-top: 4px;
  }

  &:after {
    content: url("data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSI4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xLjcxIDcuMjlMNC4zIDQuN2ExIDEgMCAwMDAtMS40MUwxLjcxLjdBMSAxIDAgMDAwIDEuNDF2NS4xOGExIDEgMCAwMDEuNzEuN3oiIGZpbGw9IiMwMDAiIGZpbGwtb3BhY2l0eT0iLjYiLz48L3N2Zz4=");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    transform-origin: top;
  }

  &.ant-popover-open,
  &:hover {
    color: $text-link;

    &:after {
      content: url("data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjgiIGZpbGw9Im5vbmUiPjxwYXRoIGQ9Ik0xLjcxIDcuMjlMNC4zIDQuN2ExIDEgMCAwMDAtMS40MUwxLjcxLjdBMSAxIDAgMDAwIDEuNDF2NS4xOGExIDEgMCAwMDEuNzEuN3oiIGZpbGw9IiMxRjg2RkYiLz48L3N2Zz4=");
    }
  }

  &.ant-popover-open:after {
    transform: rotate(90deg) translateY(-50%);
  }
}

.ant-popover {
  max-width: 370px;

  &.table-cell-popover {
    max-width: 200px;
    font-size: 12px;

    p {
      margin: 4px 0;
    }
  }
}
