.print-report-header {
  border-bottom: 1px solid #999;
  width: 100%;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 16px;

  h3 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
  }

  td {
    &:first-child {
      width: 100%;
      text-align: left !important;
    }

    &:nth-last-child(2) {
      font-size: 14px !important;
      font-weight: normal !important;
    }

    &:last-child {
      font-size: 14px !important;
      padding-left: 16px !important;
      font-weight: normal !important;
    }
  }
}

td .print-report-header {
  margin-bottom: 0;
}
