@import "styles/theme.scss";

.turnover-chart {
  .ant-alert.ant-alert-no-icon {
    border: none;
    background-color: $mild-color;
    color: $text-color;
    font-size: 14px;
    width: 165px;
    padding: 7px 12px;
    transform: translateY(-8px);

    &:before {
      content: "";
      position: absolute;
      left: -5px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: $mild-color;
    }
  }
}
