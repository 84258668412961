.print-report-template.schedule-table {
  & > tbody {
    tr:first-child {
      td.first-line {
        border-top: none;
        padding-top: 20px;
      }
    }

    td {
      padding: 2px 30px 2px 5px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }

      &:nth-child(3) {
        text-align: right;
        width: 9.5em;
      }

      &:nth-child(4) {
        padding-left: 20px;
      }

      &:nth-child(5) {
        text-align: right;
        padding-right: 60px;
      }

      &.first-line {
        border-top: 1px solid #ccc;
      }
    }
  }

  & > thead {
    td {
      &:last-child {
        text-align: right;
      }
      &:nth-child(5) {
        text-align: right;
        padding-right: 24px;
      }
      &:nth-child(4) {
        padding-left: 20px;
      }
      &:nth-child(3) {
        padding-left: 10px;
      }
    }
  }
}
