.restaurant-chart-filter {
    display: flex;
    margin-bottom: 16px;

    .ant-spin-container {
        display: flex;
    }

    .filter-item-row {
        display: flex;
        align-items: center;
        label {
            max-width: 90px;
            margin-right: 8px;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.87);
        }
        .ant-select {
            margin-right: 24px;
        }
        &.checkbox {
            cursor: pointer;
            user-select: none;
            label {
                cursor: pointer;
                max-width: 80px;
            }
        }
    }
}