@import "styles/theme.scss";

.staff-rating {
  margin-top: -24px;

  .cut-width {
    display: block;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .section-cards {
    height: 100%;

    .section-cards-aside {
      padding-top: 24px;
      width: auto;
      flex: 0 0 540px;
      width: 540px;
    }

    .section-cards-content {
      border-left: 1px solid $border-color;
      padding: 24px;
      padding-right: 0;
      width: 50%;
      flex: 1 1 50%;

      .ant-form {
        padding-right: 24px;
      }

      .max-rating {
        &:before {
          content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMy4xOTIwOSA2Ljg1ODdMMC43OTg3NiA0LjQ2NTM2QzAuNTM4NzYgNC4yMDUzNiAwLjUzODc2IDMuNzg1MzYgMC43OTg3NiAzLjUyNTM2QzEuMDU4NzYgMy4yNjUzNiAxLjQ3ODc2IDMuMjY1MzYgMS43Mzg3NiAzLjUyNTM2TDMuNjY1NDIgNS40NDUzNkw4LjI1MjA2IDAuODU4Njk2QzguNTEyMDYgMC41OTg2OTYgOC45MzIwNiAwLjU5ODY5NiA5LjE5MjA2IDAuODU4Njk2QzkuNDUyMDYgMS4xMTg3IDkuNDUyMDYgMS41Mzg3IDkuMTkyMDYgMS43OTg3TDQuMTMyMDkgNi44NTg3QzMuODc4NzYgNy4xMTg3IDMuNDUyMDkgNy4xMTg3IDMuMTkyMDkgNi44NTg3WiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
          width: 13.5px;
          height: 13.5px;
          border-radius: 50%;
          vertical-align: middle;
          display: inline-block;
          background-color: $success-color;
          margin: -2px 8px 0 0;
          line-height: 13px;
          text-align: center;
        }
      }
    }
  }

  .ant-table table {
    width: 100%;

    td:first-child {
      padding-left: 24px;
    }

    th,
    td {
      &:nth-child(2) {
        width: 100%;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:last-child {
        padding-left: 16px !important;
      }
    }
  }

  tr:first-child td {
    font-weight: bold;
  }

  .status-group {
    h3 {
      font-size: 16px;
      line-height: 20px;
      margin-top: 32px;
      margin-bottom: 16px;
    }

    .status-line {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 16px;

      span {
        flex: 1 1 100%;
      }

      &:before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $dark-border;
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        flex: 0 0 10px;
        margin-top: 5px;
        line-height: 10px;
      }

      &.status-good {
        &:before {
          content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMuODEyNzkgNy4wODk3NkwxLjkwNTQxIDUuMTIzNDJDMS42OTgyIDQuOTA5ODEgMS42OTgyIDQuNTY0NzQgMS45MDU0MSA0LjM1MTEyQzIuMTEyNjIgNC4xMzc1MSAyLjQ0NzM0IDQuMTM3NTEgMi42NTQ1NSA0LjM1MTEyTDQuMTkwMDIgNS45Mjg1OEw3Ljg0NTQ1IDIuMTYwMjFDOC4wNTI2NiAxLjk0NjYgOC4zODczOCAxLjk0NjYgOC41OTQ1OSAyLjE2MDIxQzguODAxOCAyLjM3MzgyIDguODAxOCAyLjcxODg5IDguNTk0NTkgMi45MzI1MUw0LjU2MTk0IDcuMDg5NzZDNC4zNjAwNCA3LjMwMzQxIDQuMDIgNy4zMDM0MSAzLjgxMjc5IDcuMDg5NzZaIiBmaWxsPSIjZmZmIi8+PC9zdmc+);
          background-color: $success-color;
        }
      }

      &.status-bad {
        &:before {
          content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuMTk2MjUgNy4xOTYyNUM3LjAwMTI1IDcuMzkxMjUgNi42ODYyNSA3LjM5MTI1IDYuNDkxMjUgNy4xOTYyNUw1LjA0NjI1IDUuNzUxMjVMMy42MDEyNSA3LjE5NjI1QzMuNDA2MjUgNy4zOTEyNSAzLjA5MTI1IDcuMzkxMjUgMi44OTYyNSA3LjE5NjI1QzIuNzAxMjUgNy4wMDEyNSAyLjcwMTI1IDYuNjg2MjUgMi44OTYyNSA2LjQ5MTI1TDQuMzQxMjUgNS4wNDYyNUwyLjg5NjI1IDMuNjAxMjVDMi43MDEyNSAzLjQwNjI1IDIuNzAxMjUgMy4wOTEyNSAyLjg5NjI1IDIuODk2MjVDMy4wOTEyNSAyLjcwMTI1IDMuNDA2MjUgMi43MDEyNSAzLjYwMTI1IDIuODk2MjVMNS4wNDYyNSA0LjM0MTI1TDYuNDkxMjUgMi44OTYyNUM2LjY4NjI1IDIuNzAxMjUgNy4wMDEyNSAyLjcwMTI1IDcuMTk2MjUgMi44OTYyNUM3LjM5MTI1IDMuMDkxMjUgNy4zOTEyNSAzLjQwNjI1IDcuMTk2MjUgMy42MDEyNUw1Ljc1MTI1IDUuMDQ2MjVMNy4xOTYyNSA2LjQ5MTI1QzcuMzg2MjUgNi42ODEyNSA3LjM4NjI1IDcuMDAxMjUgNy4xOTYyNSA3LjE5NjI1WiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
          background-color: $error-color;
        }
      }
    }
  }
}
