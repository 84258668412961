@import "styles/theme.scss";

.calendar-events-list {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-input-search {
    margin-top: 4px;
    margin-bottom: 8px;
  }

  .plan-summary {
    font-size: 12px;
  }

  .return-to-month {
    display: inline-block;
    float: right;
    font-size: 12px;
    padding-top: 6px;
    cursor: pointer;
  }

  h3 {
    font-size: 16px;
    margin: 0;
  }

  header {
    flex: 0 0 auto;
    border-bottom: 1px solid $border-color;
    padding-bottom: 4px;
  }

  .calendar-events-content {
    flex: 0 1 auto;
    padding: 12px 0;

    h4 {
      font-size: 12px;
      color: $text-color;
    }
  }

  .calendar-events-group {
    padding-right: 24px;

    .calendar-event {
      cursor: pointer;
      margin-bottom: 8px;
      transition: opacity 0.2s ease;

      & > span {
        &:first-child {
          padding-right: 16px;
          word-break: break-word;
        }
        &:last-child {
          white-space: nowrap;
        }
      }

      &:active {
        opacity: 0.3;
      }
    }
  }

  footer {
    flex: 0 0 auto;
    border-top: 1px solid $border-color;
    padding-top: 24px;
    min-height: 46px;
  }
}
