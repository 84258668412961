@charset "UTF-8";
@import "styles/theme.scss";

.guest-vote-controls {
  display: flex;
  padding: 6px 25px;
  background-color: $panel-background;
  margin-top: 1px;

  &__select{
    width: 200px;
  }

  .ant-picker {
    color: $text-helper;

    .ant-picker-active-bar {
      display: none;
    }

    .ant-picker-suffix {
      order: -1;
      margin-right: 8px;
    }

    .ant-picker-range-separator {
      color: transparent;
      padding: 0 4px;

      &:after {
        content: "–";
        color: $text-link;
      }
    }

    &.month {
      .ant-picker-input {
        max-width: 98px;
      }
    }

    &.date {
      .ant-picker-input {
        max-width: 85px;
      }
    }

    &.year {
      .ant-picker-input {
        max-width: 48px;
      }
    }

    &.active {
      color: $text-link;

      .ant-picker-suffix {
        color: inherit;
      }

      .ant-picker-input {
        & > input {
          text-align: center;
          color: inherit;
        }
      }
    }

    &.passive {
      max-width: 140px;
      .ant-picker-input:first-child {
        display: block;
      }

      .ant-picker-range-separator + .ant-picker-input {
        opacity: 0;
      }

      .ant-picker-range-separator {
        display: none;
      }

    }
  }
}
