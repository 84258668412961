@import "styles/theme.scss";

.positions-editor {
  .ant-layout,
  .ant-layout-header,
  .ant-layout-content,
  .ant-layout-footer {
    background-color: transparent;
    padding: 0;
    height: auto;
    line-height: 1.2;
  }

  .ant-card-body {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;

    .report-table {
      flex: 1 1 100%;

      table {
        width: auto;
      }

      .ant-table-cell {
        vertical-align: top;
      }

      .ant-select {
        transform: translateY(2px);
      }

      tr td:first-child {
        cursor: move;
      }

      tr.row-inactive td:first-child {
        cursor: default;
      }
    }
  }

  .ant-layout {
    height: 100%;
  }

  .ant-layout-footer {
    padding: 24px;
  }
}
