@import "styles/theme.scss";

html,
body {
  font-family: "PT Sans", sans-serif;
}

.content-layout {
  overflow-x: hidden;
}

body > iframe {
  position: absolute;
  left: -9999px;
  top: -9999px;
  height: 1px;
  width: 1px;
}

.kfc-app,
.kfc-login {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-color;
}

h2 {
  font-size: 20px;
  line-height: 1.2;
}

b {
  font-weight: bold;
}

a:hover {
  color: $text-color;
}

.capitalize {
  text-transform: capitalize;
}

.text-flush {
  text-transform: none;
}

.copyright {
  text-align: center;
}

.text-left {
  text-align: left;
}

.ant-layout {
  background-color: $app-background;

  &-header {
    background-color: $faded-background;
    padding: 0;
    display: flex;
    flex-direction: row;
    height: $header-height;
  }
}

.ant-tabs-nav {
  .ant-tabs-tab {
    &-active {
      font-weight: bold;
    }
  }

  .ant-tabs-ink-bar {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    bottom: 2px;
  }
}

.ant-card {
  border-radius: 4px;

  & + .ant-card {
    margin-top: 1px;
  }

  &.tabbed {
    .ant-card-body {
      padding: 16px 24px 24px;
    }
  }

  .ant-card-body {
    padding: 12px 24px;
  }
}

.ant-table-thead,
.ant-table-tbody {
  & > tr {
    &.ant-table-row-hover:not(.ant-table-expanded-row),
    &:hover:not(.ant-table-expanded-row) {
      & > td {
        background-color: $hover-background;
      }
    }
  }
}

.ant-table-placeholder {
  border: none;
}

.global-sider {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
}

.auto-sider {
  flex: 0 0 300px !important;
  min-width: 230px !important;
  max-width: 100% !important;
  width: auto !important;
}

.ant-card + .ant-row {
  margin-top: 5px;
}

.v-scroll-track {
  right: 0;
  width: 4px !important;
  background-color: #fff;
  top: 0;
  bottom: 0;
  padding: 0 5px;
  box-sizing: content-box;
}

.v-scroll-thumb {
  width: 7px !important;
  background-color: $text-faded;
  box-sizing: content-box;
  border-radius: 6px;
}

.h-scroll-track {
  bottom: 0;
  height: 4px !important;
  background-color: #fff;
  left: 0;
  right: 0;
  padding: 5px 0;
  box-sizing: content-box;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 7px;
    border-radius: 6px;
    background-color: $shaded-background;
    left: 0;
    top: 50%;
    margin-top: -1px;
    pointer-events: none;
  }
}

.h-scroll-thumb {
  height: 7px !important;
  background-color: $text-faded;
  box-sizing: content-box;
  margin-top: 1px;
  border-radius: 6px;
  z-index: 9;
}

.ant-card:hover {
  .v-scroll-track,
  .h-scroll-track {
    opacity: 1;
  }
}

.ant-btn.ant-btn-ghost,
.ant-btn.ant-btn-remove {
  border: none;

  &[disabled] {
    background-color: transparent;
  }
}

.ant-btn.ant-btn-remove {
  color: $error-color;

  &:hover {
    color: $error-color;
  }

  &[disabled] {
    color: $text-faded;

    &:hover {
      color: $text-faded;
    }
  }
}

.kfc-popup {
  max-width: 390px;

  &.wide {
    max-width: unset;
  }

  .ant-modal-header {
    padding: 24px 32px 0;
    border: none;
  }

  .ant-modal-title {
    font-size: 20px;
    line-height: 1.2;
    color: $text-color;
    padding-right: 24px;
  }

  .ant-modal-close-x {
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin-top: 11px;
    margin-right: 10px;

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .ant-modal-body {
    color: $text-color;
    padding: 16px 32px 0;

    &:empty {
      padding: 0;
    }
  }

  .ant-modal-footer {
    padding: 16px 32px 32px;
    border: none;

    .danger {
      background-color: $error-color;
    }

    .wide-btn {
      width: 100%;
      margin: 0;
      height: 32px;
    }
  }

  .ant-row.actions .fi-type-button {
    margin-bottom: 0;
  }

  &.no-footer {
    .ant-modal-footer {
      padding: 0 0 24px;
    }
  }

  &.no-margin {
    margin: 0;
  }

  hr.shaded-hr {
    width: 100%;
    border: none;
    height: 1px;
    background-color: $shaded-background;
    margin: 8px 0;
  }
}
.kfc-tabbed-page {
  height: 100%;
  flex: 1 1 100%;

  .tabbed-content {
    flex: 1 0 100%;

    & > * {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;

      & > :first-child:last-child {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
  }

  .tabbed-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    padding-right: 15px;

    & > div {
      flex: 1 1 100%;
    }

    & > .tabbed-footer {
      flex: 0 0 auto;
      justify-content: flex-start;
      padding: 20px 0 5px;
    }
  }

  .tabbed-footer {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 15px 25px 5px;

    &.right {
      flex: 0 0 auto;
      justify-content: flex-end;
    }

    &.nopad {
      padding: 15px 0 5px;
    }

    &.sticky {
      position: sticky;
      bottom: 0;
    }

    .icon-link {
      margin-left: 0;
    }
  }
}

.fixed-container {
  .ant-layout-content {
    display: flex;
    flex-direction: column;
  }

  .inner-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 1 500px;
  }
}

.scroll-container {
  &:hover {
    .scroll-track {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
  }

  .scroll-track {
    bottom: -3px;
    height: 14px !important;
    width: 100%;
    background-color: $shaded-background;
    opacity: 0;
    transition: opacity 0.5s ease 1s;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    will-change: opacity;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 7px;
      border-radius: 6px; 
      background-color: $shaded-background;
      transform: translateY(-50%);
      z-index: 998;
    }
  }

  .scroll-thumb {
    height: 7px !important;
    background-color: $text-faded;
    box-sizing: content-box;
    margin-top: -1px;
    border-radius: 6px;
    z-index: 999;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 10px;
      transform: translateY(-50%);
    }
  }
}

.ant-select-dropdown {
  min-width: 80px;
}

.ant-form {
  .ant-select-selection__rendered {
    line-height: 2;
  }

  .ant-form-item-label {
    margin-bottom: 0;
  }

  .ant-form-item-control {
    line-height: 1.2;
    padding: 5px 0;
  }

  .fi-type-hidden {
    height: 0;
    overflow: hidden;
  }

  .fi-type-label label {
    white-space: normal;
  }

  .ant-form-item-label > label::after {
    display: none;
  }

  .ant-radio-group {
    padding: 6px 0 0;
  }
}

.ant-calendar-header {
  border-bottom: none;

  .ant-calendar-next-year-btn,
  .ant-calendar-prev-year-btn {
    display: none;
  }

  .ant-calendar-next-month-btn,
  .ant-calendar-prev-month-btn {
    width: 24px;
    height: 24px;
    border: 1px solid $text-link;
    top: 50%;
    margin-top: -12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    &:before {
      content: "";
      width: 6px;
      height: 6px;
      display: block;
      border: none;
      border-right: 1px solid $text-link;
      border-top: 1px solid $text-link;
      transform: rotate(45deg);
      margin-top: 9px;
      margin-left: 2px;
    }

    &:after {
      display: none;
    }
  }

  .ant-calendar-prev-month-btn {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:before {
      transform: rotate(225deg);
      margin-top: 9px;
      margin-left: 4px;
    }
  }
}

.ant-calendar-disabled-cell .ant-calendar-date {
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.ant-calendar-time .ant-calendar-day-select {
  color: $text-link;
  font-weight: normal;
}

.ant-calendar-time-picker-select {
  overflow-y: auto;
}

.section-cards {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  align-content: stretch;

  .section-cards-aside {
    flex: 0 1 480px;

    & > .ant-card {
      height: 100%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid $border-color;

      & > .ant-card-body {
        height: 100%;
      }
    }
  }

  .section-cards-content {
    flex: 1 1 100%;

    & > .ant-card {
      height: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      & > .ant-card-body {
        height: 100%;
      }
    }
  }
}

.ant-list {
  .ant-list-item {
    padding-left: 32px;
    cursor: pointer;
    position: relative;
    color: $text-color;
    height: 40px;

    &:last-child {
      border-bottom: 1px solid $border-color;
    }

    &:hover {
      background-color: $hint-background;
    }

    &.current {
      cursor: default;
      font-weight: bolder;
    }

    &.new {
      color: $text-helper;

      .anticon {
        margin-right: 8px;

        svg,
        svg * {
          fill: $text-helper;
        }
      }

      .user-avatar {
        .anticon {
          margin-right: 0;
        }
      }

      &:after {
        display: none;
      }
    }

    &.draft,
    &.transfer {
      color: $text-helper;

      .anticon {
        margin-right: 10px;
        transform: translateY(-3px);

        svg,
        svg * {
          fill: $text-helper;
        }
      }

      .user-avatar {
        .anticon {
          margin-right: 0;
          transform: translate(-50%, -50%);
        }
      }

      &:after {
        display: none;
      }
    }
  }
}

.ant-list-item-content-single {
  justify-content: space-between;
}

.ant-alert {
  margin: 24px 8px;
  max-width: 560px;

  &.ant-alert-warning {
    background-color: $warning-color;
    border: none;
  }
}

.ant-tabs {
  .ant-alert {
    margin: 48px 32px;
  }

  h2 + .ant-alert {
    margin: 48px 0;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: $text-color;

    & > .ant-tabs-tab-btn {
      color: inherit;
    }
  }
}

.ant-dropdown-link {
  font-size: 14px;
  white-space: nowrap;
}

.ant-dropdown-menu-item .link {
  color: $text-color;
}

.ant-calendar-input-wrap {
  display: none;
}

.ant-spin-spinning {
  height: 1em;
  line-height: 1;
}

.text-faded {
  color: $text-faded;
}

.text-helper {
  color: $text-helper;

  & ~ .ant-btn {
    &.offset-top {
      margin-top: 16px;
    }
    &.offset-left {
      margin-left: 16px;
    }
  }
}

.ant-picker, .ant-picker-dropdown {
  font: inherit !important;
}

.ant-picker:hover .ant-picker-suffix:not(:last-child) {
  opacity: 1;
}

.ant-picker-dropdown .ant-picker-week-panel-row-selected td.ant-picker-cell:before,
.ant-picker-dropdown .ant-picker-week-panel-row-hover td.ant-picker-cell:before {
  background: inherit;
}

.ant-picker-dropdown tbody .ant-picker-week-panel-row-selected td.ant-picker-cell .ant-picker-cell-inner {
  color: #fff;

  &:before {
    border: 1px solid #fff;
  }
}

.ant-picker-dropdown .ant-picker-week-panel-row-hover td.ant-picker-cell .ant-picker-cell-inner {
  color: inherit;
}

.ant-btn.ant-btn-secondary {
  border: none;
  color: $text-link;
}

.ant-popover-inner-content {
  p:last-child {
    margin-bottom: 0;
  }
}

.ant-popover {
  &.warning {
    .ant-popover-arrow {
      border-top-color: #ffe58f;
      border-right-color: #ffe58f;
    }

    .ant-popover-inner {
      background-color: #ffe58f;
      color: #000;
      font-size: 20px;

      .ant-popover-inner-content {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}

.ant-modal-wrap {
  z-index: 1031;
}

.ant-input-number:focus input,
.ant-input-number input:focus {
  background-color: $panel-background;
}

.no-b {
  font-weight: normal;
}

.all-done {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: $success-color;
  margin: 0 8px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 4px;
    border: 1.5px solid #fff;
    border-right: none;
    border-top: none;
    transform: translate(-55%, -70%) rotate(-45deg);
  }
}

.repeat-sign {
  transform: translateY(2px);
  display: block;
  text-align: right;

  &:before {
    content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMy45OTk3OSA2Ljc4MDg2TDEuNjg2NDUgNC40Njc1M0MxLjQyNjQ1IDQuMjA3NTMgMS4wMDY0NSA0LjIwNzUzIDAuNzQ2NDUzIDQuNDY3NTNDMC40ODY0NTMgNC43Mjc1MyAwLjQ4NjQ1MyA1LjE0NzUzIDAuNzQ2NDUzIDUuNDA3NTNMMy41MzMxMiA4LjE5NDE5QzMuNzkzMTIgOC40NTQxOSA0LjIxMzEyIDguNDU0MTkgNC40NzMxMiA4LjE5NDE5TDExLjUyNjUgMS4xNDA4NkMxMS43ODY1IDAuODgwODU5IDExLjc4NjUgMC40NjA4NTkgMTEuNTI2NSAwLjIwMDg1OUMxMS4yNjY1IC0wLjA1OTE0MDYgMTAuODQ2NSAtMC4wNTkxNDA2IDEwLjU4NjUgMC4yMDA4NTlMMy45OTk3OSA2Ljc4MDg2WiIgZmlsbD0iIzAwMCIgZmlsbC1vcGFjaXR5PSIuNiIvPjwvc3ZnPg==);
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    margin-top: -2px;
  }
}
