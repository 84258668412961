.turnover-controls {
  a {
    display: inline;
    margin-left: 0.5em !important;
  }

  .control-links {
    padding-left: 16px;
  }
}
