@import "styles/theme.scss";

.app-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  width: 100%;
  background-color: $panel-background;

  & > *:last-child {
    padding-right: 0;
  }
}
