.kfc-tasks-cards {
  max-height: 100%;

  .section-cards-aside {
    flex: 0 0 55%;
    max-width: 55%;

    &:last-child {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }

  .section-cards-content {
    flex: 0 0 45%;
    max-width: 45%;
  }

  .reports-controls {
    margin-top: 16px;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;

    & > .view-type-switch {
      flex: 0 0 auto;
    }

    & > .report-table {
      flex: 1 1 100%;
      overflow: hidden;
      padding-right: 0;

      & > .report-wrap {
        max-height: 100%;
        display: flex;
        flex-direction: column;

        & > .reports-controls {
          flex: 0 0 auto;
        }
      }
    }
  }
}
