@import "styles/theme.scss";

.planning-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-end;

  &-info-icon{
    display: flex;
    gap: 4px;
    align-items: center;
  }

  & > * {
    flex: 0 0 auto;
    padding-right: 24px;
  }

  .ant-input-number.turnover {
    width: 100%;
  }

  label {
    display: block;
    font-size: 12px;
    color: $text-helper;
    line-height: 2;
  }

  .planning-button {
    display: block;
    margin-top: 30px;
    color: $text-link;
    cursor: pointer;
    line-height: 1.5;

    &.disabled {
      cursor: not-allowed;
    }

    &:hover {
      &:not(.disabled) {
        text-decoration: underline;
      }
    }

    svg {
      vertical-align: middle;
      margin-right: 10px;
      margin-top: -4px;

      * {
        fill: $text-link;
      }
    }
  }

  .ant-upload-disabled {
    pointer-events: none;

    .planning-button {
      color: $text-faded;

      svg,
      svg * {
        fill: $text-faded;
      }
    }
  }
}
