.print-report-template.managers-report {
  width: 100%;
  table-layout: fixed;

  & > thead {
    & > tr:first-child {
      border: none;
    }

    & > tr:nth-child(2) {
      td {
        vertical-align: top;
        border-bottom: none;
        font-weight: normal;

        &:nth-child(2) {
          white-space: nowrap;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-last-child(4),
        &:nth-last-child(5),
        &:nth-last-child(6) {
          text-align: left;

          span {
            display: block;
            border-bottom: 1px solid #000;
            margin-left: -5px;
            margin-right: -5px;
          }
        }

        &:nth-child(2),
        &:nth-last-child(6) {
          span {
            margin-left: 0;
          }
        }

        &:nth-child(8),
        &:nth-last-child(4) {
          span {
            margin-right: 0;
          }
        }
      }
    }

    & > tr:nth-child(3) {
      td {
        font-weight: bold;
        text-align: right;
        padding: 0 5px 5px;

        &:nth-last-child(4),
        &:nth-last-child(5),
        &:nth-last-child(6) {
          text-align: left;
        }
      }
    }
  }

  & > tbody {
    td {
      padding: 5px;
      text-align: right;
      border-bottom: 1px solid #ccc;

      .float-center {
        display: inline-block;
        white-space: nowrap;
        transform: translateX(-50%);
        margin-left: 50%;
      }

      &:first-child {
        width: 15%;
        white-space: nowrap;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        white-space: nowrap;
        width: 12%;
      }

      &:first-child,
      &:last-child,
      &:nth-last-child(2) {
        text-align: left;
      }

      &:nth-last-child(4),
      &:nth-last-child(5),
      &:nth-last-child(6) {
        text-align: center;
      }

      &:nth-last-child(2) {
        width: 8%;
      }

      &:nth-last-child(3),
      &:nth-child(9) {
        width: 4%;
      }
    }

    & > tr:last-child {
      td {
        font-weight: bold;
        border-top: 2px solid #000;
        border-bottom: none;
      }
    }
  }
}
