.statistics_tab {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 24px;
}

.metrics_switcher{
  display: flex;
  gap: 12px;
}
