@import "styles/theme.scss";

.reports-controls {
  .reports-controls-header {
    padding: 8px;
  }

  .reports-controls-list {
    margin-left: -24px;
    margin-right: -24px;
    border-top: 1px solid $border-color;
  }

  .ant-list-item {
    .item-controls {
      float: right;
      padding-right: 16px;
      line-height: 1.8;
      display: none;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
        svg {
          cursor: not-allowed;
        }
        svg,
        svg * {
          fill: rgba(0, 0, 0, 0.3);
        }
      }

      svg,
      svg * {
        fill: $text-link;
      }
    }

    &.current {
      background-color: transparent;
      font-weight: bold;

      .item-controls {
        display: block;
      }
    }
  }

  .ant-picker {
    //visibility: hidden;
    opacity: 0;
    margin-top: -20px;
    margin-left: -20px;
    //display: block;
    pointer-events: none;
    display: flex;
  }

  .dates-fake {
    color: $text-link;
    cursor: pointer;

    .anticon {
      margin-right: 8px;
    }
  }
}
