.edit-ac-comment-modal {
    .header {
        display: flex;
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        div:first-child {
            margin-right: 6px;
        }
        .name {
            color: rgba(0, 0, 0, 0.48);
        }
    }
    .textarea-wrapper {
        position: relative;
        .count-block {
            position: absolute;
            bottom: 8px;
            right: 8px;
            color: rgba(0, 0, 0, 0.4);
            text-align: right;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
        textarea {
            resize: none;
        }
    }
    .ant-modal-footer {
        .ant-btn:first-child {
            border: 0;
        }
        .ant-btn {
            padding-left: 32px;
            padding-right: 32px;
        }
    }
}
