@import "styles/theme.scss";

h2.restaurant-title {
  font-size: 14px;
  font-weight: normal;
  margin: 5px 0 0;
  color: $text-color;
}

.restaurants-drop {
  padding-right: 0;

  .ant-empty-image {
    height: 5px;
  }

  .ant-dropdown-link {
    border: 1px solid $border-color;
    min-width: 280px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px !important;
    color: $text-color !important;

    &.ant-dropdown-open {
      color: $text-link !important;
    }
  }
}
