@import "styles/theme.scss";

.photos-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 24px;
  text-align: center;
  background-color: $shaded-background;
  font-weight: bold;
  border: none;
  border-radius: 50%;
  opacity: 0.5;
  transition: opacity 0.1s ease;
  margin: 0 $default-padding;
  padding: 0;
  position: relative;
  cursor: pointer;
  outline: none !important;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;
    color: transparent;
    border-top: 2px solid #151515;
    border-right: 2px solid #151515;
    transform: translate(-55%, -50%) rotate(45deg);
  }

  &:hover {
    opacity: 1;
  }

  &.photos-btn-prev {
    span {
      transform: translate(-45%, -51%) rotate(-135deg);
    }
  }
}

.slider {
  cursor: default !important;

  ul {
    cursor: default !important;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
