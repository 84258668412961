@import "styles/theme.scss";

$sel-bg: #f5f5f5;

.rating-inner.rating-categories {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .category-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .category-table {
    padding-bottom: 20px;
  }

  .rat-arrow {
    position: absolute;
    left: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: rotate(45deg) translateX(-15px);
    z-index: 1;
    background-color: #fff;
  }

  &.count-2 > * {
    width: 50%;
    max-width: 50%;
  }

  &.count-3 > * {
    width: 30%;
  }

  & > * {
    flex: 0 1 auto;
    max-width: 30%;
    min-width: 30%;
    position: relative;

    &:first-child:last-child {
      max-width: 50%;
    }

    .ant-table-fixed-header
      > .ant-table-content
      > .ant-table-scroll
      > .ant-table-body {
      background-color: transparent;
    }

    .sync-scroll-container-v {
      position: absolute;
      right: 0;
      top: 0;

      .v-scroll-thumb {
        opacity: 0;
        transition: opacity 0.5s 1s ease;
      }
    }

    &:hover {
      .sync-scroll-container-v .v-scroll-thumb {
        opacity: 1;
        transition: opacity 0.2s ease;
      }
    }

    .center-container {
      min-width: 300px;
    }

    &:first-child {
      margin-right: 16px;
    }

    &:nth-child(2) {
      padding-right: 16px;
      box-sizing: content-box;
      border-left: 1px solid #ddd;
      border-top: 1px solid #ddd;
      margin-top: 120px;

      .rat-arrow {
        border: 1px solid #ddd;
        border-top-color: transparent;
        border-right-color: transparent;
      }

      &:last-child {
        border-left-color: $sel-bg;
        border-top-color: $sel-bg;

        .rat-arrow {
          border-left-color: $sel-bg;
          border-bottom-color: $sel-bg;
        }
      }
    }

    &:last-child {
      min-width: 500px;
      background-color: $sel-bg;

      .ant-table-placeholder {
        background-color: $sel-bg;
      }

      .sync-scroll-container-v .v-scroll-track {
        background-color: $sel-bg;
      }

      .v-scroll-thumb {
        border-right-color: $sel-bg;
        border-left-color: $sel-bg;
      }

      &:first-child {
        background-color: transparent;

        .sync-scroll-container-v .v-scroll-track {
          background-color: $panel-background;
        }

        .v-scroll-thumb {
          border-right-color: $panel-background;
          border-left-color: $panel-background;
        }
      }

      .rat-arrow {
        background-color: $sel-bg;
        border: 1px solid $sel-bg;
        border-top-color: transparent;
        border-right-color: transparent;
      }
    }
  }

  span.link:not(.ant-dropdown-link){
    padding-top: 11px;
    display: inline-block;
    position: relative;
    z-index: 2;

    img {
      margin: -2px 4px 0;
      will-change: filter, opacity;
      transition: filter 0.2s ease, opacity 0.2s ease;
    }

    &:hover {
      img {
        filter: brightness(0);
        opacity: 0.7;
      }
    }
  }

  .single-partner {
    padding: 12px 24px 0;
    margin-bottom: 9px;
    color: rgba(0, 0, 0, 0.87);
  }

  .report-table {
    height: 100%;
    overflow-y: auto;
    min-height: 300px;
    position: relative;
    z-index: 2;

    & {
      .ant-form-item {
        margin: 0;
      }
    }

    &.category-table {
      height: calc(100% - 120px);
    }

    & > p {
      margin-top: 32px;
      padding: 0 24px;
    }

    .report-wrap {
      .ant-table-wrapper {
        margin-top: 0;
      }

      table {
        table-layout: fixed;
        width: 100%;
      }
    }

    .ant-table-header {
      overflow: hidden !important;
      height: 30px;
      margin-bottom: 0 !important;
      border-bottom: 1px solid $border-color;
      margin-right: 14px;
    }

    td:first-child {
      span {
        transform: translateY(2px);
      }
    }

    th,
    td {
      padding-left: 8px !important;
      padding-right: 16px !important;

      &:first-child {
        padding-left: 24px !important;
      }

      &:last-child {
        padding-right: 24px !important;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 80px;
      }

      .ant-input-number {
        margin-right: -12px;
        min-width: 75px;
        transform: translateY(-1px);

        input {
          text-align: right;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td,
        th {
          &:first-child {
            width: auto;

            span {
              display: inline-block;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      & > tr:first-child > td {
        font-weight: normal;
      }

      & > tr.row-current > td {
        font-weight: bolder;
      }

      a {
        color: $text-color;
      }

      .row-inactive {
        td,
        a {
          color: $text-faded;
        }
      }

      .row-current {
        background-color: transparent !important;

        td {
          font-weight: bolder;
        }
      }
    }
  }
}
