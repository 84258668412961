@import "styles/theme.scss";

.kfc-schedule {
  td {
    vertical-align: middle;
  }

  .ant-table-content {
    overflow: hidden !important;
  }

  .spinner-container {
    min-height: calc(100vh - 230px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.chart {
      min-height: 300px;
    }

    &.table {
      min-height: 160px;
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker-borderless{
    padding: 0;
  }

  .report-table .ant-table-tbody > tr:nth-child(2) > td.col-max {
    border: 1px solid #d3ae8b !important;
  }

  .ant-table-scroll {
    overflow-y: hidden;
  }
  .inner-content .ant-card-body {
    padding-top: 0;

    & > .view-type-switch {
      position: sticky;
      top: -16px;
      z-index: 10;

      .ant-tabs-bar {
        background-color: #fff;
      }
    }
  }

  .ant-table-fixed-left {
    z-index: 9;
  }

  .report-table {
    .report-wrap {
      td {
        .view-type-drop > span.link {
          font-size: 12px;
        }

        .ant-time-picker-input {
          height: 28px;
          line-height: 20px;
          transform: translateY(1px);
        }

        .assign-btn {
          padding: 3px 12px;
        }

        .ant-select {
          transform: translate(0, 1px);

          .ant-select-selection--single {
            height: 28px;
          }

          .ant-select-selection__rendered {
            line-height: 26px;
          }
        }
      }
    }
    .ant-table-tbody,
    .ant-table-thead {
      & > tr {
        height: 32px !important;

        &.ant-table-measure-row {
          height: 0 !important;
        }
        th {
          padding-bottom: 0;
          border: none;
          vertical-align: bottom;
          line-height: 2;
          background-color: $panel-background;

          &::before {
            content: '';
            display: none;
          }
        }

        td {
          height: 24px;
          padding-top: 1px;
          padding-bottom: 1px;
        }

        th,
        td {
          padding-left: 4px;
          font-size: 14px;

          &.align-center {
            padding-right: 8px;
            padding-left: 8px;
          }

          &:first-child {
            padding-left: 32px;
          }

          &.nopad {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }

    .ant-table-fixed-left tr {
      font-size: 12px;

      td:last-child,
      td[colspan] {
        border-right: 1px solid $border-color;
      }
    }
  }

  .tabbed-footer {
    background-color: #fff;
    padding: 6px 32px;
    border-top: 1px solid $border-color;
    margin: -2px -24px -16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    opacity: 0;
    pointer-events: none;
    position: absolute;

    &.sticky {
      opacity: 1;
      pointer-events: all;
      position: sticky;
      z-index: 9;
    }

    .sync-scroll-container {
      transform: translateY(calc(50% - 10px));
    }
  }
}

.saving-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
}
