@import "styles/theme.scss";

.detail-dropdown {
  color: $text-helper;

  button {
    -webkit-appearance: none;
    border: none;
    display: inline;
    background-color: transparent;
    cursor: pointer;
    transition: color 0.2s ease;
    color: $text-color;

    &:focus {
      outline: none;
      color: $text-link;
      text-decoration: none;
    }

    &:hover {
      color: $text-link;
    }
  }
}

.detail-menu.ant-dropdown-menu {
  padding: 0;

  .ant-dropdown-menu-item.detail-active {
    background-color: $hint-background;
  }
}
