@import "styles/theme.scss";

.compare-table {
  padding: 5px 0;

  .ant-table {
    color: $text-color;
    margin-top: 5px;
    table-layout: fixed;
  }

  .ant-table-tbody > tr > td {
    padding: 5px 0;
    border: none;

    &:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    &:last-child {
      text-align: right;
      padding-left: 10px;
    }
  }
}

.compare-container {
  &.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .compare-table {
      min-width: 230px;
      margin-right: 70px;
    }
  }
}
