@import "styles/theme.scss";

.user-avatar {
  display: block;
  margin: 10px auto 0;
  background-color: $primary-color;
  color: $text-light;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  overflow: hidden;
  object-fit: cover;
  position: relative;

  &.with-icon {
    background-color: transparent;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img,
  .anticon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
