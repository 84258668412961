.login-form {
  .ant-form-item {
    margin: 30px 0;

    .ant-input {
      height: 32px;

      &::placeholder {
        color: #7c7c7c;
      }
    }
  }

  .login-form-button {
    width: 100%;
    height: 32px;
  }

  .form-message {
    height: 50px;
    position: relative;
    top: -20px;
  }
}
