.kfc-auth-error-message {
  height: 100%;

  .unsaved {
    position: static;
  }

  .logout-button {
    margin-top: 16px;
    padding: 4px 16px;
    border: 1px solid;
    border-radius: 4px;
  }
}
