@import "styles/theme.scss";

.load-more-button {
  display: block;
  margin: 12px auto;
  width: 240px;
  height: 32px;
  color: $text-link;

  &:hover {
    background-color: $button-background;
    color: $text-light;
  }
}
