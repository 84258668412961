.happiness_gauges {
    display: flex;
    width: 100%;
    gap: 6px;
    margin-bottom: 25px;

    & > div:first-child {
        margin-right: 35px;
    }
}
