@import "styles/theme.scss";

.add-dish-list {
  height: 200px;
}

.kfc-add-dish {
  max-width: 430px;

  .ant-divider-horizontal {
    margin-bottom: 0;
  }

  .ant-modal-body {
    padding-right: 0;
    padding-left: 0;

    .filter-container {
      padding-right: $default-padding;
      padding-left: $default-padding;
    }
  }

  .list-overspin {
    padding: 24px 40px 24px 32px;

    .ant-checkbox-wrapper {
      font-size: 12px;
    }
  }

  .ant-list-item {
    height: auto;
    border: none !important;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;

    .ant-row {
      width: 100%;
      align-content: center;
      align-items: center;

      .ant-checkbox-wrapper {
        width: 100%;
      }

      .item-title {
        margin-right: 8px;
      }

      .combo-tag {
        display: inline-block;
        background-color: $shaded-background;
        text-transform: uppercase;
        padding: 0 6px;
        border-radius: 4px;
        font-size: 11px;
      }

      .ant-checkbox + span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
        max-width: 90%;
        color: $text-color;

        small {
          display: block;
          color: $text-helper;
        }
      }

      .ant-col {
        &:last-child {
          text-align: right;
          padding-top: 15px;
        }

        &.no-alt {
          .ant-checkbox + span {
            padding-top: 0;
          }
          & + .ant-col {
            padding-top: 0;
          }
        }
      }
    }
  }
}
