@import "styles/theme.scss";

.ant-modal-wrap {
  z-index: 1031;
}

.kfc-origin-modal {
  max-width: 100%;

  .center-container {
    min-height: 60px;
  }

  .report-table {
    .v-scroll-track {
      z-index: 5;
    }
  }

  .ant-table {
    thead {
      position: sticky;
      top: 0;
      background-color: #fff;
      border-bottom: 1px solid #e8e8e8;
      z-index: 2;

      th {
        background-color: #fff;

        &::before {
          display: none;
        }
      }
    }
    tbody {
      tr.row-selected td {
        background-color: #eff8ff;
      }
      td:first-child {
        max-width: 280px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .ant-empty {
    color: $text-faded;
    padding-top: 24px;

    .ant-empty-image {
      display: none;
    }
  }

  .rest-list {
    height: 200px;
  }

  .ant-divider-horizontal {
    margin-bottom: 0;
  }

  .ant-modal-body {
    padding-right: 0;
    padding-left: 0;

    .filter-container {
      padding-right: $default-padding;
      padding-left: $default-padding;

      p {
        color: $text-faded;
        padding: 0 8px;
      }
    }
  }

  .ant-radio-group {
    padding: 0 24px;

    .ant-radio-wrapper {
      display: block;
      padding: 12px 0;
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 32px;

    .ant-checkbox {
      top: 0;
    }
  }
}
