.kfc-settings {
  .time-table,
  .shifts-table,
  .manning-duration,
  .breaks-card {
    .ant-table table {
      width: auto;
    }
  }

  .breaks-card .tabbed-footer .add-row-control {
    margin-top: 44px;
  }

  .report-table.offset:last-child {
    margin-bottom: 20px;
  }
}

.add-rate-modal {
  .ant-modal-header {
    border: none;
    padding-bottom: 0;
  }
  .ant-modal-footer {
    border: none;
    padding-top: 0;
  }
}
