.print-report-template.short-schedule {
  td {
    padding: 10px 5px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &.employee-name {
      padding: 0;
      border-bottom: 1px solid #999;

      span {
        display: block;
        padding: 15px 0 5px;
        font-weight: bold;
        page-break-after: avoid !important;
        page-break-inside: avoid !important;
      }
    }

    b {
      font-weight: normal;
      color: #666;
      display: block;
    }

    em {
      display: block;
      font-style: normal;
    }
  }
}
