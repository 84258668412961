@import "styles/theme.scss";

.ant-card.page-title {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  & + .ant-card {
    border-radius: 0;
    margin-top: 1px;

    & + .ant-card {
      margin-top: 1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 1;
    margin-bottom: 0;
    flex: 0 0 auto;
  }

  a {
    display: block;
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    line-height: 2;
    font-size: 12px;
    border: 1px solid $text-link;
    text-align: center;
    border-radius: 12px;
    margin-right: 16px;
    position: relative;

    &:hover {
      color: $text-link;
    }

    .anticon {
      position: absolute;
      left: 47%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .ant-card-body {
    display: flex;
    flex-direction: row;
    align-items: center;

    .back-title-content {
      flex: 1 1 100%;
    }
  }

  .icon-link {
    .anticon {
      vertical-align: bottom;
    }
  }

  .button-download {
    padding: 0 8px!important;
    height: 24px;
    margin-top: 8px;
    button {
      padding: 0;
      height: 24px;
    }
    path {
      fill: $text-link;
    }
    button:disabled {
      path {
        fill: $text-faded;
       }
    }
  }
}
