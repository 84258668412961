@import "styles/theme.scss";

.kfc-rating {
  .inner-content {
    flex: 1 1 100%;
    padding-bottom: 24px;

    & > .ant-card {
      min-height: 100%;
      margin: 0;

      .ant-tabs .ant-alert {
        margin-left: 0;
        margin-top: 32px;
      }
    }
  }

  .currency-switch .view-type-drop .ant-dropdown-link {
    border-right: 1px solid $border-color;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .rating-inner {
    min-height: 300px;
    padding: $proportion-padding;

    .top-left {
      max-width: 225px;
      color: $text-helper;
      font-size: 12px;
    }
  }

  .report-table .ant-table-thead > tr > td:first-child,
  .report-table .ant-table-thead > tr > th:first-child,
  .report-table .ant-table-tbody > tr > td:first-child,
  .report-table .ant-table-tbody > tr > th:first-child {
    padding-left: $default-padding;
  }

  .report-table .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.numeric:nth-of-type(9),
  .report-table .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.numeric:nth-of-type(5){
    padding-left: 20px!important;
  }

  .report-table .ant-table .delta-turnover {
    align-items: start;
    i {
      padding-top: 4px;
    }
  }

  .report-table .ant-table-tbody > tr > td:nth-of-type(2){
    white-space: normal!important;
    p{
      width: 300px;
    }
  }
  .rating-performance ~ div .report-table .ant-table-tbody > tr > td:nth-of-type(2){
      white-space: nowrap!important;
  }

  .delta-header-turnover-center{
    text-align: center;
  }

  .report-table .ant-table-tbody > tr > td{
    vertical-align: middle
  }

  .report-table .ant-table-tbody > tr > td .percent,
  .report-table .ant-table-tbody > tr > td.numeric,
  .report-table .ant-table-thead > tr > th.numeric{
    text-align: center!important;
  }

  .report-table .ant-table-tbody > .ant-table-row {
    font-weight: bold;

    & ~ .ant-table-row {
      font-weight: normal;
    }
  }

  .report-table {
    position: relative;

    .ant-table-cell-fix-left-last:last-child::after {
      width: 0 !important;
    }
  }

  .rating-height-limit-report-table .report-table .ant-card-body .ant-table{
    min-height: 510px;
  }

  .report-table .ant-card-body .ant-table-body::-webkit-scrollbar {
    position: fixed;
    bottom: 0;
    background-color: #e5e5e5;
    height: 8px;
    width: 8px;
    border-radius: 5px;
  }

  .report-table .ant-card-body .ant-table-body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  .report-table .ant-card-body .ant-table-content thead{
    position: sticky;
    z-index: 2;
    background-color: #fff;
    top: 0;
  }
  .ant-table-cell-fix-left:not(.ant-table-cell-fix-left-last){
    left:0px !important
    }
  .fix-restaurants{
    left: 102px !important;
  }

  .percent-bar {
    position: relative;

    span{
      position: absolute;
      left: 3px;
      z-index: 1;
      top: 2px;
    }
  }

  .rating-time {
    h2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 16px;

      span:last-child {
        padding-right: $default-padding;
      }
    }

    .report-table {
      .ant-table-tbody > tr:first-child {
        font-weight: normal;
      }

      td:last-child {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  .rating-performance,
  .rating-turnover {
    display: flex;
    justify-content: space-between;
    .currency-switch {
        align-items: flex-start;
        margin-left: 14px;
      }
  }

  .flush-filters-button{
    min-width: 220px;

  }

  .rating-performance__filters,
  .rating-turnover__filters {
      width: 1600;
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
      flex-wrap: wrap;
      min-width: 910px;
    }
    .rat-cats {
      width: calc(100vw - 214px)
    }
  .rating-turnover {
    .ant-table-tbody > tr:first-child td:nth-child(2) ~ td {
      font-size: 0;
      color: transparent;
    }

    .rating-turnover__filters{
      display: flex;
      gap: 10px;

      .ant-select {
        height: fit-content;
      }
    }
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;

    .inner-content.fixed {
      flex: 1 1 100%;
      padding-bottom: 24px;
      overflow: hidden;

      .ant-card,
      .ant-card-body,
      .ant-tabs,
      .ant-tabs-content,
      .ant-tabs-tabpane-active {
        height: 100%;
        overflow: hidden;
      }

      .ant-card {
        margin: 0;
      }

      .ant-tabs {
        display: flex;
        flex-direction: column;
      }

      .rating-categories {
        height: 100%;
        .ant-table-body{
          margin-top: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.cswitch-after {
  margin: 16px;
  max-width: 225px;
  color: $text-helper;
  font-size: 12px;
}

.category-table{
  .ant-table-thead{
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
  }
}

.rat-rest{
  margin-top: 50px;
  padding-bottom: 32px;

  // .ant-table-body {
  //  overflow: visible!important;
  // }

  .ant-table-header {
    background: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 4;
    width: 100%;
  }

  .ant-table-scroll{
    overflow: visible!important;
  }

}

.rating-inner.rating-categories .rat-rest .report-table .ant-table-tbody > tr:first-child > td,
.rating-inner.rating-categories .rat-rest .report-table .ant-table-tbody > tr:first-child > td:nth-child(3) input,
.rating-inner.rating-categories .rat-rest .report-table .ant-table-tbody > tr:first-child > td:nth-child(3) .ant-input-number-disabled {
  pointer-events: none;
  color: #000;
  opacity: 1;
  font-weight: 600;
}

.rating-inner.rating-categories .rat-rest .sync-scroll-container-v{
  display: none;
}
