@import "styles/theme.scss";

.users-editor {
  .ant-layout,
  .ant-layout-header,
  .ant-layout-content,
  .ant-layout-footer {
    background-color: transparent;
    padding: 0;
    height: auto;
    line-height: 1.2;
  }

  .ant-card-body {
    width: 100%;
    display: flex;
    flex-direction: column;

    .report-table {
      flex: 1 1 100%;
    }
  }

  .ant-layout {
    height: 100%;
  }

  .ant-layout-content {
    margin: 0 -24px;
  }

  .report-table {
    padding-bottom: 24px;

    .ant-table-content > table {
      width: 100%;

      & tbody > tr > td {
        &:last-child {
          padding: 0 12px;

          .anticon {
            padding: 12px;
          }
        }
      }

      & > tbody > tr > td,
      & > thead > tr > th {
        &:nth-last-child(2) {
          white-space: nowrap;
          max-width: 150px;
        }

        &:first-child {
          width: 16.66666667%;
          max-width: 16.66666667%;
          min-width: 16.66666667%;
        }

        &:nth-child(2) {
          width: 16.66666667%;
          max-width: 16.66666667%;
          min-width: 16.66666667%;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          width: 240px;
          max-width: 240px;
          min-width: 240px;
          padding-left: 16px;
        }

        span.link {
          color: $text-color;

          &:hover {
            color: $text-link;
          }
        }
      }
    }

    .ant-table-tbody > tr > td:first-child {
      white-space: normal;
    }

    thead {
      position: sticky;
      top: 0;
    }

    .check-date {
      .ant-picker {
        border: none;
        background: transparent;
      }

      input[disabled] {
        color: $text-color !important;
        -webkit-text-fill-color: $text-color !important;
      }
    }
  }

  .user-controls {
    opacity: 0;
    pointer-events: none;

    .anticon {
      transform: scale(1.2) translateY(-1px);

      svg * {
        fill: $text-link;
      }

      &.disabled svg * {
        fill: $text-helper;
      }
    }
  }

  tr:hover {
    .user-controls {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.stopsync-info {
  .stopsync-user {
    font-weight: bold;
    margin: 0 0 0.5em 0;
  }

  .stopsync-comment {
    font-size: 0.9em;
    margin: 0;
  }
}
