@import "styles/theme.scss";

.dropdown_wrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center; 
    margin: 24px 0;
    flex:1;

    .view-type-drop {
      padding: 0;
      margin: 0;


      .ant-dropdown-link {
        display: flex;
        justify-content: space-between;
        width: 180px;
        border: 1px solid $border-color;
        
         &:not(.ant-dropdown-open ){
        color: $text-helper ;
      }
      }
     
    }
    .support_text {
        font-size: 12px;
        color: $text-helper;
        width: 230px;
    }
  }