.ppr-restaurant {
    min-width: 900px;
    > .ant-card-body {
        padding-top: 32px;
    }

    .ppr-subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 24px;
        height: 18px;
    }

    hr {
        max-width: 80%;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        max-width: 80%;
    }

    .main {
        display: flex;
        > .ant-col {
            align-items: stretch;
            padding-left: 28px!important;
            &:not(:last-child) {
                border-right: 1px solid #EBEBEB;
            }
            &:first-child {
                padding-left: 20px!important;
            }
        }
        .ant-select {
            max-width: 100px;
        }
        .ant-input {
            max-width: 200px;
        }
    }
}