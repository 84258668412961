$border-radius: 4px;

$font-size: 14px;
$font-small: 12px;
$sider-icon-size: 32px;

$default-padding: 24px;
$proportion-padding: 12px 24px;
$small-padding: 16px;

$header-height: 80px;

$sider-width: 88px;
$max-form-width: 580px;

$breakpoint: 1300px;
