@import "styles/theme.scss";

.ant-legacy-form {
  .ant-form-explain {
    font-size: 10px;
    color: $error-color;
    padding-top: 4px;
    margin-bottom: -20px;
  }

  .yesno-radio {
    display: flex;
    flex-direction: row;
    padding-top: 9px !important;

    label {
      margin-right: 4px;
    }
  }

  .has-error {
    .ant-input-number,
    .ant-input,
    .ant-select-selection,
    .ant-input:hover,
    .ant-radio-inner {
      border-color: $error-color;
      background-color: $bad-light;
    }

    .ant-calendar-picker-icon::after,
    .ant-time-picker-icon::after,
    .ant-picker-icon::after,
    .ant-select-arrow,
    .ant-cascader-picker-arrow {
      color: $error-color;
    }

    span.ant-radio + * {
      color: $error-color;
    }
  }

  hr {
    border: none;
    background-color: $shaded-background;
    height: 1px;
    clear: both;
    margin: 35px 0 19px;
    max-width: $max-form-width;
  }

  .fi-sub {
    .ant-legacy-form-item {
      padding: 0 !important;
      font-size: 12px;
      margin-top: -15px;
    }
  }

  .fake-label {
    padding-top: 17px;
  }

  .fi-hidden {
    width: 1px;
    height: 1px;
    flex: 0 0 1px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }

  .ant-row.password + .fi-sub .ant-legacy-form-item {
    transform: translateY(-16px);
    margin-bottom: -16px;
  }

  .ant-row .ant-col .ant-legacy-form-item.fi-type-checkbox {
    padding-top: 0;
  }

  .ant-legacy-form-item.fi-type-checkbox + .ant-legacy-form-item.fi-type-checkbox {
    margin-top: -16px;
  }

  .ant-legacy-form-item.fi-type-info {
    font-size: 12px;
  }

  .row-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding-top: 4px;

    & > .ant-legacy-form-item {
      margin-right: 16px;
      margin-bottom: 0;
      flex: 0 0 40%;

      &.fi-type-checkbox + .fi-type-checkbox {
        margin-top: 0;
      }
    }
  }

  .ant-legacy-form-item {
    max-width: $max-form-width;
    margin-bottom: 15px;

    &.fi-type-hidden {
      margin: 0;
    }

    &.fi-type-link {
      padding-top: 24px;
    }

    &-required::before {
      display: none;
    }
  }

  .ant-legacy-form-item-label {
    text-align: left;
    line-height: 1.2;
    margin-bottom: 5px;

    label {
      font-size: 12px;
      color: $text-helper;
    }
  }

  .ant-input,
  .ant-input-number,
  .ant-select-selection,
  .ant-btn {
    height: 32px;
    width: 100%;
  }

  textarea.ant-input {
    height: 6em;
    resize: none;
  }

  .ant-select-selection__rendered {
    line-height: 3;
  }

  .ant-select-disabled .ant-select-selection,
  .ant-input[disabled],
  .ant-input-number-disabled {
    background-color: $neutral-light;
    color: $text-helper;
    -webkit-text-fill-color: $text-helper;
  }

  .ant-calendar-picker {
    width: 100%;
  }

  .ant-time-picker-icon,
  .ant-time-picker-clear,
  .ant-calendar-picker-icon,
  .ant-calendar-picker-clear {
    display: none;
  }

  .ant-checkbox-group {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;

    .ant-checkbox-wrapper {
      display: flex;
      gap: 5px;

      .ant-checkbox + span {
        padding: 0;
        transform: translateY(-2px);
      }
    }
  }
}

.ant-checkbox-wrapper {
  line-height: 1.5715;
}

.ant-checkbox-checked .ant-checkbox-inner {
  &:after {
    border-width: 1.5px;
  }
}

td.ant-calendar-week-number-cell,
.ant-calendar-week-number-header {
  display: none;
}
