@import "styles/theme.scss";

.chart-time {
  position: relative;

  &.chart-time-focused .ant-time-picker {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  &:before {
    /*content: '';*/
    background-color: $text-faded;
    width: 5px;
    height: 6px;
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -3px;
  }

  &.night:before {
    background-color: $night-color;
  }

  &.morning:before {
    background-color: $morning-color;
  }

  &.day:before {
    background-color: $day-color;
  }

  &.evening:before {
    background-color: $evening-color;
  }
}

.ant-time-picker-panel-select
  li.ant-time-picker-panel-select-option-disabled:focus {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.25);
}

.ant-time-picker-panel-input-wrap {
  &.has-error {
    &,
    .ant-time-picker-panel-input {
      animation: errorFlash 0.6s ease 1;
    }
  }
}

@keyframes errorFlash {
  0% {
    background-color: transparent;
  }
  40% {
    background-color: $bad-light;
  }
  60% {
    background-color: $bad-light;
  }
  100% {
    background-color: transparent;
  }
}
