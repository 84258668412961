.kfc-admin {
  display: flex;
  flex-direction: column;

  .inner-content {
    display: flex;
    flex-direction: column;

    &.fixed {
      flex: 1 1 100%;
    }
  }

  .tabbed-content,
  .ant-layout-content {
    display: flex;
    flex-direction: column;
  }

  .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .section-cards-content .ant-card-body {
    padding-right: 0;
  }
}
