@import "styles/theme.scss";

.task-detail-row {
    label {
        font-size: 12px;
        color: $text-helper;
    }
    .photos-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 8px;
        width: 100%;
        .photo-wrapper {
            margin-right: 8px;
            margin-bottom: 8px;
            width: calc(25% - 6px);
            cursor: pointer;
            transition: opacity 0.2s;
            border-radius: 8px;
            height: 0;
            padding-top: calc(25% - 6px);
            position: relative;
            &:nth-child(4n) {
                margin-right: 0;
            }
            &:hover {
                opacity: 0.9;
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: lightgray;
                border-radius: 8px;
                background-image: linear-gradient(
                    90deg,
                    rgba(#fff, 0),
                    rgba(#fff, 0.5),
                    rgba(#fff, 0)
                );
                background-size: 20px 100%;
                background-repeat: no-repeat;
                background-position: left -40px top 0;
                animation: shine 2s ease infinite;
            }
            .photo {
                border-radius: 8px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
}

@keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}

.task-status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .task-status-icon {
        display: inline-block;
        width: 14px;
        height: 14px;
        background-color: transparentize($color: #000000, $amount: 0.62);
        border-radius: 14px;
        margin: 4px 12px 0 0;
        position: relative;
        line-height: 11px;
        text-align: center;

        &:before {
            width: 9px;
            height: 11px;
            display: inline-block;
            overflow: hidden;
            vertical-align: middle;
        }

        &.late {
            background-color: transparent;
        }

        &.success {
            background-color: $success-color;
        }

        &.fail {
            background-color: $primary-color;
        }

        &.late {
            &:before {
                width: 13px;
                height: 12px;
                content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuOTc5OTcgMTEuOTk4NUgxMi4wMkMxMy4wNDY2IDExLjk5ODUgMTMuNjg2NiAxMC44ODUyIDEzLjE3MzMgOS45OTg0OUw4LjE1MzMgMS4zMjUxNkM3LjYzOTk3IDAuNDM4NDkgNi4zNTk5NyAwLjQzODQ5IDUuODQ2NjQgMS4zMjUxNkwwLjgyNjYzNiA5Ljk5ODQ5QzAuMzEzMzAyIDEwLjg4NTIgMC45NTMzMDIgMTEuOTk4NSAxLjk3OTk3IDExLjk5ODVaTTYuOTk5OTcgNy4zMzE4MkM2LjYzMzMgNy4zMzE4MiA2LjMzMzMgNy4wMzE4MiA2LjMzMzMgNi42NjUxNlY1LjMzMTgyQzYuMzMzMyA0Ljk2NTE2IDYuNjMzMyA0LjY2NTE2IDYuOTk5OTcgNC42NjUxNkM3LjM2NjY0IDQuNjY1MTYgNy42NjY2NCA0Ljk2NTE2IDcuNjY2NjQgNS4zMzE4MlY2LjY2NTE2QzcuNjY2NjQgNy4wMzE4MiA3LjM2NjY0IDcuMzMxODIgNi45OTk5NyA3LjMzMTgyWk03LjY2NjY0IDkuOTk4NDlINi4zMzMzVjguNjY1MTZINy42NjY2NFY5Ljk5ODQ5WiIgZmlsbD0iI0VGOTY1QyIvPjwvc3ZnPg==);
            }
        }

        &.success {
            &:before {
                content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMy4xOTIwOSA2Ljg1ODdMMC43OTg3NiA0LjQ2NTM2QzAuNTM4NzYgNC4yMDUzNiAwLjUzODc2IDMuNzg1MzYgMC43OTg3NiAzLjUyNTM2QzEuMDU4NzYgMy4yNjUzNiAxLjQ3ODc2IDMuMjY1MzYgMS43Mzg3NiAzLjUyNTM2TDMuNjY1NDIgNS40NDUzNkw4LjI1MjA2IDAuODU4Njk2QzguNTEyMDYgMC41OTg2OTYgOC45MzIwNiAwLjU5ODY5NiA5LjE5MjA2IDAuODU4Njk2QzkuNDUyMDYgMS4xMTg3IDkuNDUyMDYgMS41Mzg3IDkuMTkyMDYgMS43OTg3TDQuMTMyMDkgNi44NTg3QzMuODc4NzYgNy4xMTg3IDMuNDUyMDkgNy4xMTg3IDMuMTkyMDkgNi44NTg3WiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
            }
        }

        &.fail,
        &.canceled {
            &:before {
                content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik02Ljg2NTM0IDYuODY1MzRDNi42MDUzNCA3LjEyNTM0IDYuMTg1MzUgNy4xMjUzNCA1LjkyNTM1IDYuODY1MzRMMy45OTg2OCA0LjkzODY4TDIuMDcyMDEgNi44NjUzNEMxLjgxMjAxIDcuMTI1MzQgMS4zOTIwMSA3LjEyNTM0IDEuMTMyMDEgNi44NjUzNEMwLjg3MjAxMiA2LjYwNTM0IDAuODcyMDEyIDYuMTg1MzUgMS4xMzIwMSA1LjkyNTM1TDMuMDU4NjggMy45OTg2OEwxLjEzMjAxIDIuMDcyMDFDMC44NzIwMTIgMS44MTIwMSAwLjg3MjAxMiAxLjM5MjAxIDEuMTMyMDEgMS4xMzIwMUMxLjM5MjAxIDAuODcyMDEyIDEuODEyMDEgMC44NzIwMTIgMi4wNzIwMSAxLjEzMjAxTDMuOTk4NjggMy4wNTg2OEw1LjkyNTM1IDEuMTMyMDFDNi4xODUzNSAwLjg3MjAxMiA2LjYwNTM0IDAuODcyMDEyIDYuODY1MzQgMS4xMzIwMUM3LjEyNTM0IDEuMzkyMDEgNy4xMjUzNCAxLjgxMjAxIDYuODY1MzQgMi4wNzIwMUw0LjkzODY4IDMuOTk4NjhMNi44NjUzNCA1LjkyNTM1QzcuMTE4NjggNi4xNzg2OCA3LjExODY4IDYuNjA1MzQgNi44NjUzNCA2Ljg2NTM0WiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
                margin-left: 1px;
                margin-top: 1px;
            }
        }
    }

    p {
        margin-bottom: 2px;
    }
}

.task-details .ant-card-body {
    padding: 0;
}

.task-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 24px;
    max-height: 90vh;

    .task-details {
      overflow-y: auto;
    }

    .task-details-footer {
        padding-top: $default-padding;
    }
}

.wide-task-details {
    display: flex;
    flex-direction: row;

    h2 {
        margin-bottom: $default-padding;
    }

    .task-details-container {
        flex: 0 1 370px;
    }
}

.kfc-popup {
    &.ultra-wide {
        width: 1056px !important;
        max-width: 90%;

        .slider {
            max-width: 65%;

            ul {
                display: block;
                height: 100% !important;
            }

            .slider-slide {
                height: 100% !important;
                background-color: $shaded-background;
            }
        }
    }

    &.kfc-task-details {
      max-width: 90vw;
      max-height: 90vh;
      .ant-modal-content, .ant-modal-body {
        max-width: 90vw;
        max-height: 90vh;
      }
        .deviations-list {
            height: 334px;
            margin-top: 40px;

            .ant-list .ant-list-item {
                display: block;
                height: auto;
                border: none;
                padding: 8px 0;
                margin-bottom: 8px;
                pointer-events: none;

                & > span {
                    display: block;
                }

                .item-tile {
                    font-size: 14px;
                    line-height: 18px;
                }

                .item-description {
                    font-size: 12px;
                    line-height: 14px;
                    color: #7d7d7d;
                    margin-top: 5px;
                }
            }
        }

        .deviations-list > div[style] {
            height: calc(100% - 30px) !important;

            & + .ant-divider-horizontal {
                margin-top: 0;
            }
        }

        .ant-modal-body {
            padding: 0;

            .center-container {
                min-height: 200px;
            }
        }

        .ant-modal-footer {
            display: none;
        }

        .ant-modal-close-x {
            width: $default-padding;
            height: $default-padding;
            line-height: $default-padding;
        }
    }
}
