.kfc-schedule-turnover {
  p {
    margin-bottom: 4px !important;
  }

  .info-text {
    font-family: "PT Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 8px;
    margin-top: -15px;
    margin-bottom: 15px;
  }

  .turnover-error {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .footer {
    display: flex;
    justify-content: center;

    .footer-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      .buttons {
        display: flex;
        align-items: center;

        .buttons-error {
          padding: 0px 10px 5px 5px;
        }
      }
    }
  }

  .ant-form.ant-form-horizontal {
    .table-turnover {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;

      .item-turnover {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .date-turnover {
          min-width: 70px;
        }

        .main-turnover {
          width: 150px;
        }

        .forecast-turnover {
          width: 150px;
        }

        .difference-turnover {
          width: 70px;
          display: flex;
          justify-content: center;
        }
      }
    }

    .ant-row.ant-form-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      margin-bottom: 0px !important;
    }
  }
}
