.osat_gauges {
  display: flex;
  width: 100%;
  gap: 6px;
  margin-bottom: 25px;
}

.title_container {
  display: flex;
  gap: 5px;
  align-items: center;
}
