@import "styles/theme.scss";

.kfc-schedule-mobile {
  height: 100%;

  .staff-schedule-chart .report-wrap {
    height: 100%;
    overflow: hidden;

    colgroup {
      display: table-column-group;
    }

    .ant-table-fixed {
      colgroup {
        display: none;
      }
    }

    table th,
    td {
      width: 56px !important;

      &:first-child {
        width: 180px !important;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 52px !important;
      }

      &[colspan] {
        width: auto !important;
      }
    }

    table th:last-child {
      padding-right: 50px;
    }
  }

  .report-table .ant-table-tbody > tr > td {
    &:last-child {
      white-space: nowrap;
    }
  }

  table {
    table-layout: fixed;
  }

  .ant-table-fixed-left {
    z-index: 8;
  }

  .ant-table-body-inner,
  .ant-table-scroll .ant-table-body {
    max-height: 90vh !important;
  }

  .tabbed-footer {
    display: none;
  }

  .staff-schedule-chart,
  .report-wrap,
  .pager-wrap,
  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-content {
    height: 100%;
  }

  .ant-table-scroll {
    height: auto;

    .ant-table-body {
      height: 100%;
      margin-bottom: 0;
    }
  }

  .ant-table {
    .render-as-label {
      color: $text-color;

      &.spoiler {
        color: $text-helper;

        &:before {
          display: none;
        }
      }
    }

    td,
    th {
      box-sizing: border-box !important;
    }

    td {
      padding: 2px 8px !important;

      &:first-child {
        padding-left: 16px !important;
      }

      &.nopad {
        padding: 2px 0 !important;
      }

      .schedule-cell {
        height: 8px;
      }
    }

    tr:first-child {
      th {
        padding: 14px 8px 3px;

        &:first-child {
          padding-left: 16px;
        }

        &.col-sep {
          border-left: none;
        }
      }
    }
  }
}
