@import "styles/theme.scss";

.inner-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .calendar-switch {
    flex: 0 0 auto;
  }

  .currency-switch {
    flex: 0 0 auto;
    margin-right: $default-padding;
  }
}
