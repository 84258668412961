@import "styles/theme.scss";

.staff-card {
  padding: 13px 20px 16px 0;

  & > .ant-row {
    max-width: 610px;
    margin-bottom: 9px;

    &.ant-form-item {
      max-width: $max-form-width;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      & > label:after {
        display: none;
      }
    }

    .ant-form-item-control input[type="text"] {
      max-width: 380px;
    }

    &.buttons {
      margin-bottom: 0;
    }

    .infoIcon {
      margin-left: -24px;
      padding-top: 28px;
    }

    .ant-calendar-picker-icon {
      display: block;
      right: auto;
      left: 10px;
      color: $text-helper;
    }

    .ant-calendar-picker-input {
      padding-left: 30px;
    }

    .fi-field-isUnderage {
      padding-top: 24px !important;
    }

    .fi-field-birthDate {
      .ant-calendar-year-select {
        display: none;
      }
      .ant-calendar-month-select {
        pointer-events: none;
      }
    }
  }
}
