.guest-vote-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 1810px;
  margin-bottom: 5px;
}

.gust-vote-filter {
  min-width: 220px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 5px 5px 0;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 5px 0 0 5px;
}