.summary-report{
  padding: 20px 0;
  display: block;

  & > h2 {
    display: flex;
    align-items: flex-start;
  }

  &-middle{
    .ant-table-content {
      overflow: scroll !important;
    }

    .ant-table-content{
      position: relative;
      max-height: 600px;
    }

    .ant-table-thead {
      position: sticky;
      top:0;
      z-index: 15;

      & > tr > th {
        vertical-align: middle;
        text-align: center;
      }
    }
  }

  &-middle,
  &-bottom {
    width: 100%;
    margin-top: 20px;

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td{
      padding: 4px 16px;
    }
  }

  &-bottom{
    flex-direction: column;
    gap: 10px;
    &:last-child{
      max-width: 400px;
    }
  }

  .ant-table-cell{
    white-space: nowrap;
  }

  &-reverse-table{
    padding-top: 10px;
    display: flex;
    gap: 20px;
    width: 100%;

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td{
      padding: 4px 16px;
    }

    &:last-child{
      width: 700px;
    }

  }

  &-transfer-table-cell{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-tooltips{
    display: flex;
    align-items: flex-start;
    gap: 3px;

    i {
      padding-top: 4px;
    }
  }
}

span.cross-black-button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .remove-row-control .anticon {
    margin: 0;
    & i > svg * {
        width: 20px;
        height: 20px;
    }

  }

  & > span {
    padding: 0;
    margin-left: auto;
  }
}

span.transfer-icon{
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  & .anticon > svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    fill: #b8b8b8;
  }
}

.check-icon {
  opacity: 1;
  transition: fill 0.2s ease;
  float: right;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  width: 24px;
  height: 24px;
  background: none;
  border: none;

  &:hover {
    .anticon * {
      fill: #036718!important;
    }
  }


  .anticon svg,
  .anticon *{
    fill: #b8b8b8;

    &:hover{
      fill: #036718;
    }
  }
}

.button-download{
  padding: 0 8px;
}


@media print {
  html, body, #root, .App, .kfc-app, .kfc-app > .ant-layout {
    height: 100%!important;
    width: 100%!important;
  }

  .fixed-container .inner-content{
    overflow-y: visible!important;
    overflow-x: visible!important;
  }

  div.print-report-sreport{
    overflow: visible;
  }

  .ant-table-bordered .ant-table-tbody > tr > td,
  .ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td{
    border-right: 2px solid #e8e8e8;
  }
  .ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td,
  .ant-table-bordered .ant-table-thead > tr:not(:last-child) > th{
    border-bottom: 2px solid #e8e8e8;
  }

  .summary-report {
    display: block;
    width: 100%;
    height: 100%;
  }

  .summary-report > h2,
  .summary-report-bottom,
  .button-download {
    display: none;
  }
}
