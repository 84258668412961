@import "styles/theme.scss";

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &.offset {
    padding: $default-padding;
  }
}
