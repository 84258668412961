@charset "UTF-8";
@import "styles/theme.scss";

.staff-list {
  height: 100%;
  flex: 1 1 100%;
  margin: 0 -24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > * {
    flex: 1 1 100%;
  }

  & > .filter-container {
    flex: 0 0 auto;
    padding: 5px 8px 24px 32px;
  }

  & > .add-row-control {
    flex: 0 0 auto;
    padding: 0 32px 12px;
    border-bottom: 1px solid $border-color;
  }

  .ant-list {
    margin-right: 14px;
    padding-bottom: 10px;

    .ant-list-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 4px;
      padding-bottom: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .staff-item-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }

      .cancel-transfer {
        margin-left: auto;
        color: #1f86ff;
        opacity: 0;
        transform: translateY(5px);

        svg * {
          fill: #1f86ff;
        }
      }

      .user-avatar {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin: 0;
        vertical-align: middle;
        margin-right: 16px;
        background-color: $text-link-light;

        &.with-icon {
          background-color: transparent;
        }
      }

      &:hover {
        .cancel-transfer {
          opacity: 1;
        }
      }

      &.current {
        background-color: transparent;
        font-weight: bold;
      }

      &.dismissed {
        color: $text-faded;
        text-decoration: line-through;
      }

      &.outstaff .staff-item-inner .staff-item-text {
        max-width: 175px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.transfer:hover {
        &.outstaff .staff-item-inner .staff-item-text {
          max-width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .staff-item-inner .staff-item-text {
          max-width: 175px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.outstaff .staff-item-inner:after {
        content: "ВС";
        display: inline-block;
        vertical-align: middle;
        margin-top: -1px;
        margin-left: 5px;
        background-color: $shaded-background;
        color: $text-color;
        font-size: 10px;
        line-height: 1.4;
        border-radius: 4px;
        width: 28px;
        height: 14px;
        text-align: center;
        font-weight: normal;
      }
    }
  }

  .list-header {
    flex: 0 0 auto;
    padding: 0 16px 15px 30px;
    white-space: nowrap;

    .ant-dropdown {
      width: 100%;
      left: 0 !important;

      .ant-dropdown-menu-item {
        padding: 8px 8px 8px 34px;
      }
    }

    .ant-spin-spinning {
      transform: scale(0.75);
    }

    span.link.w-drop {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      &:hover {
        color: $text-link;
      }

      & > *:first-child {
        margin-right: 8px;
      }

      & > *:last-child {
        margin-left: auto;
        transition: transform 0.2s ease;
      }

      &.ant-dropdown-open {
        & > *:last-child {
          transform: rotate(180deg);
        }
      }
    }
  }

  .list-footer {
    flex: 0 0 auto;
    padding: 15px 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid #ebebeb;

    & > *:not(:first-child) {
      margin-top: 16px;
    }
  }
}
