@import "styles/theme.scss";

.unsaved {
  position: fixed;
  left: $sider-width;
  right: 0;
  bottom: 0;
  background-color: $mild-color;
  color: #000;
  padding: 15px;
  text-align: center;
  z-index: 50;

  .ant-btn {
    margin-left: 15px;
    background-color: transparent;
    color: $text-link;
    border-color: $text-link;
  }
}
