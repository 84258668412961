.print-report-template.daily-schedule-table {
  table-layout: fixed;
  max-width: 960px;

  .print-report-header {
    margin-bottom: 0;

    td:last-child {
      display: none;
    }
  }

  & > thead {
    & > tr > td {
      font-weight: bolder;
      border-bottom: 2px solid #000;
      border-top: 2px solid #000;
    }
    & > tr:first-child {
      & > td {
        padding-bottom: 0 !important;
        border-bottom: none;
        border-top: none;
      }
    }
  }

  & > tbody {
    & > tr:first-child,
    & > tr:nth-child(3) {
      td {
        height: 6em;
        vertical-align: middle;
        text-align: center;
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(-60deg);
        transform: rotate(90deg) translateX(0.5em);
        padding: 5px 0;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        white-space: nowrap;
        font-weight: normal;

        &:first-child,
        &:nth-child(2) {
          text-align: left;
          font-weight: bold;
          border-right: none;
          width: 18%;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 11%;
          font-weight: bold;
        }

        &:nth-child(3) {
          width: 7%;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          transform: none;
          text-align: left;
          vertical-align: bottom;
          padding-right: 8px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          text-align: right;
        }
      }
    }
    td {
      text-align: right;
      padding: 0 8px 0 0;
      border-bottom: 1px solid #999;
      border-right: 1px solid #999;
      white-space: nowrap;

      &:first-child,
      &:nth-child(2) {
        text-align: left;
        border-right: none;
        width: 15%;
      }

      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        font-weight: bold;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 7%;
      }

      &.spoiler {
        font-weight: bold;
        padding-top: 5px;
        border-right: none;

        &.with-hours {
          text-align: right;
          font-weight: normal;
          color: #999;

          &:first-child {
            color: #000;
            font-weight: bold;
            border-right: none;
            text-align: left;
          }
        }
      }

      &.time {
        font-weight: bold !important;
      }

      &.pre-spoiler,
      &.spoiler {
        border-bottom: 2px solid #000;
      }

      &.shift {
        padding-right: 0;

        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          font-weight: normal;
          padding-right: 5px;
        }

        .schedule-cell {
          -webkit-print-color-adjust: exact;
          background-color: #939598;
          height: 8px;

          &.next-day,
          &.prev-day {
            position: relative;

            &:before {
              content: "";
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
              color: #fff;
              width: 4px;
              height: 4px;
              border: 1px solid #fff;
              border-top-color: transparent;
              border-left-color: transparent;
            }
          }

          &.prev-day {
            &:before {
              transform: translate(-50%, -50%) rotate(-225deg);
            }
          }
        }
      }
    }
  }
}
