.sub-user-info {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  padding: 24px 0 12px;

  & > * {
    flex: 0 0 auto;
  }

  .user-avatar {
    margin: auto 8px auto 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
  }
}
