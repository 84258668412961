@import "styles/theme.scss";

.schedule-controls {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $border-color;
  font-size: 14px;

  .generator-container {
    padding: 12px 25px;
    border-left: 1px solid $border-color;
  }

  .compact-button {
    min-width: 162px;
    height: 100%;
    padding: 0 20px;
    margin: 0 40px 0 auto;
    border-radius: 0;
  }
}
