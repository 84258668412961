@import "styles/theme.scss";

.granted-list {
  height: 270px;
}

.kfc-granted-restaurants {
  max-width: 430px;
  min-height: 430px;

  .ant-divider-horizontal {
    margin-bottom: 0;
  }

  .ant-modal-body {
    padding-right: 0;
    padding-left: 0;

    .filter-container {
      padding-right: 32px;
      padding-left: 32px;

      .ant-radio-group {
        margin-top: $default-padding;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .granted-list > div[style] {
    height: calc(100% - 30px) !important;

    & + .ant-divider-horizontal {
      margin-top: 0;
    }
  }

  .list-overspin {
    padding: 10px 40px 10px 32px;

    .ant-checkbox-wrapper {
      font-size: 12px;
    }
  }

  .ant-list-item {
    height: auto;
    border: none !important;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .ant-checkbox-wrapper {
      flex: 1 1 100%;
    }

    .item-title {
      margin-right: 8px;
    }

    .item-aside {
      flex: 0 0 auto;
      padding-right: 32px;
      font-size: 12px;
      color: #000;
      opacity: 0.6;
    }
  }
}
