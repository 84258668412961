@charset "UTF-8";
@import "styles/theme.scss";

.kfc-tasks {
  display: flex;
  flex-direction: column;

  .section-cards,
  .inner-content.fixed {
    flex: 1 1 100%;
  }

  .kfc-tasks-cards,
  .ant-layout-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .ant-layout-content {
    overflow: auto;
  }

  .ant-card-body {
    padding: 20px 0;
  }

  .inner-content > :last-child {
    margin-bottom: 0;
    padding-bottom: 24px;
  }

  .report-table {
    padding: 0 $default-padding;

    .ant-table-thead > tr > th[colspan] {
      text-align: left;
    }

    &.tasks-summary .ant-table {
      th {
        white-space: normal;
      }

      td {
        vertical-align: middle;
        white-space: nowrap;
        text-align: right;

        &:first-child {
          width: 80px;
          text-align: left;
        }
        &:nth-child(2) {
          padding-left: 0;
          text-align: left;
        }

        .user-avatar {
          display: inline-block;
          vertical-align: middle;
          background-color: $text-link-light;
        }

        .rgm:after {
          content: "Д";
          display: inline-block;
          vertical-align: middle;
          margin-top: -1px;
          margin-left: 5px;
          background-color: $shaded-background;
          color: $text-color;
          font-size: 10px;
          line-height: 1.4;
          border-radius: 4px;
          width: 28px;
          height: 14px;
          text-align: center;
          font-weight: normal;
        }

        small {
          display: block;
          font-weight: normal;
          color: $text-faded;
          font-size: 12px;
        }
      }
    }
  }
}
