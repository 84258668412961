@import "styles/theme.scss";

.kfc-generate-progress {
  max-width: 500px;

  .progress-message {
    color: $text-faded;
    font-size: 12px;
    display: flex;
    justify-content: space-between;

    aside {
      white-space: nowrap;
    }
  }

  &.error {
    .ant-progress-bg {
      background-color: $primary-color !important;
    }

    .progress-message {
      color: $error-color;

      aside {
        display: none;
      }
    }
  }
}
