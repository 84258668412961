@import "styles/theme.scss";

.user-bottom {
  margin-top: auto;
}

.user-info {
  text-align: center;
  cursor: pointer;

  .user-name {
    color: $text-light;
    opacity: 0.6;
    font-size: 10px;
  }

  &:hover {
    .user-name {
      opacity: 1;
    }
  }
}

.user-popover {
  .ant-popover-inner {
    background-color: $hint-background;
  }

  .ant-popover-arrow {
    border-bottom-color: $hint-background;
    border-left-color: $hint-background;
  }

  .anticon {
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 5px;

    svg,
    svg * {
      fill: $text-link;
      transition: fill 0.2s ease;
    }
  }

  a:hover {
    .anticon {
      svg,
      svg * {
        fill: $text-color;
      }
    }
  }
}

.logout-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}
