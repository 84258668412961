$first-col-width: 150px;

%sticky {
    position: sticky;
    z-index: 2;
    background-color: #fff;
}

.ppr-area-restaurant-info {
    border-radius: 4px;
    background: #fff;
    max-width: 100%;
    width: fit-content;

    tr th:first-child,
    tr td:first-child {
        border-left: unset;
    }

    tr th:last-child,
    tr td:last-child {
        border-right: unset;
    }

    thead tr:first-child th:first-child {
        border-top: unset;
    }

    tbody tr:last-child td,
    tbody tr:last-child th {
        border-bottom: unset;
    }

    th,
    td {
        padding: 8px 6px;
        vertical-align: middle;
        text-align: center;
        border: 1px solid #ebebeb;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        &.no-border {
            border: unset;
        }
        &.bold {
            font-weight: 700;
        }
    }

    th {
        color: rgba(0, 0, 0, 0.6);
    }
    td {
        color: rgba(0, 0, 0, 0.87);
    }
    thead {
        tr {
            th:first-child,
            td:first-child {
                min-width: $first-col-width;
                max-width: $first-col-width;
            }
        }
    }

    .status-top {
        border-bottom: unset;
    }
    .status-bottom {
        border-top: unset;
    }

    .manager-row {
        &.first {
            .manager-field {
                border-bottom: unset;
            }
        }
        &.last {
            .manager-field {
                border-top: unset;
            }
        }
        .manager-field {
            &.name,
            &.position {
                border-right: unset;
                text-align: start;
            }
            &.rcl,
            &.comment {
                border-left: unset;
                text-align: end;
                .ant-btn {
                    padding: 0;
                }
            }
        }
    }

    .restaurant-name {
        border-right: unset;
        border-top: unset;
        text-align: start;
        min-width: 200px;
        max-width: 200px;
        text-align: left;
    }
    .restaurant-id {
        border-left: unset;
        border-top: unset;
        padding: 0 0 0 4px;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.6);
    }

    .structure-row {
        td {
            max-width: calc(200px / 3)!important;
            min-width: calc(200px / 3)!important;
        }
        &:nth-child(2) {
            td {
                border-bottom: unset;
                padding: 6px 0 0 0;
            }
        }
        &:last-child {
            td {
                border-top: unset;
                padding: 6px 0 6px 0;
            }
        }
    }
}
