.restaurant-structure {
    width: 44px;
    tr {
        th {
            padding: 0 auto;
            width: 40px;
        }
    }

    tr:first-child {
        th {
            border-bottom: 1px solid #ebebeb;
        }
    }
}