@import "styles/theme.scss";

@import "styles/theme.scss";

.category-card {
  max-width: 705px;

  .add-row-control {
    padding: 8px 16px;
    display: inline-block;
    border: 1px solid $text-link;
    border-radius: $border-radius;
    margin-top: 15px;

    &:hover {
      border-color: $text-color;
    }
  }

  h2 {
    margin: 20px 0 32px;
    padding-right: $default-padding;
    flex: 0 0 auto;
    align-items: center;
    padding-right: 16px;

    & > * {
      vertical-align: middle;
    }

    .ant-input {
      margin-right: $default-padding;
      width: auto;
    }

    & > span {
      line-height: 32px;
      padding-right: $default-padding;
    }

    .anticon {
      cursor: pointer;
      margin-left: 8px;

      svg * {
        fill: rgba(0, 0, 0, 0.6);
      }

      &.remove-icon {
        svg * {
          fill: $error-color;
        }
      }
    }
  }

  &.view-type-switch.fixed {
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .ant-form {
    width: auto;
    flex: 1 1 auto;
    overflow-x: hidden;
    min-height: 100%;
    position: relative;
    padding-bottom: 48px;

    .buttons {
      & > .ant-col:first-child {
        button {
          margin-left: 5px;
        }
      }
    }

    .field-row {
      &:last-child {
        position: absolute;
        left: 0;
        bottom: 8px;
        width: 100%;

        .ant-row {
          margin-bottom: 0;
        }

        .fi-type-button {
          margin-top: 0;
        }
      }

      .fi-type-button {
        margin-top: 15px;
      }

      .field-column {
        padding: 16px 0;

        .ant-row {
          margin-bottom: 0;

          .ant-form-item-label {
            padding-top: 8px;
          }

          &:first-child {
            .ant-form-item-label {
              padding-top: 0;
            }
          }
        }

        .fi-type-label {
          .ant-form-item-label {
            padding-top: 0;
          }
        }

        .fi-type-checkbox .ant-form-item-control {
          margin-top: 0;
        }
      }
    }

    hr {
      margin: 0;
      max-width: 100%;
    }

    .field-row .fi-type-checkbox .ant-form-item-control {
      margin-top: 24px;
    }

    .field-row + .field-row {
      .fi-type-checkbox .ant-form-item-control {
        margin-top: 0;
      }
    }
  }
}
