.tabbed-footer {
  position: relative;

  .sync-scroll-container {
    position: absolute;
    left: 0;
    bottom: 50%;
    transform: translateY(calc(50% - 5px));
  }
}
