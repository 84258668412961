@import "styles/theme.scss";

.currency-switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .view-type-drop {
    padding: 0;
    margin: 0;
    width: 135px;

    &.view-type-switch {
      border: none;
    }

    .ant-dropdown-link {
      border: 1px solid $border-color;
      border-right: none;
      border-radius: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &:first-child {
      .ant-dropdown-link {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }

    &:last-child {
      .ant-dropdown-link {
        border-right: 1px solid $border-color;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }

  .cswitch-after {
    margin-left: 16px;
    max-width: 225px;
    color: $text-helper;
    font-size: 12px;
  }

  .cswitch-before {
    margin-right: 16px;
    width: 230px;
    color: $text-helper;
    font-size: 12px;
  }
}
