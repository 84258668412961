@import "styles/theme.scss";

.linked-list {
  height: 200px;
}

.kfc-linked-users {
  max-width: 430px;
  min-height: 430px;

  .ant-divider-horizontal {
    margin-bottom: 0;
  }

  .ant-modal-body {
    padding-right: 0;
    padding-left: 0;

    .filter-container {
      padding-right: $default-padding;
      padding-left: $default-padding;
    }
  }

  .linked-list > div[style] {
    height: calc(100% - 30px) !important;

    & + .ant-divider-horizontal {
      margin-top: 0;
    }
  }

  .list-overspin {
    padding: 10px 40px 10px 32px;

    .ant-checkbox-wrapper {
      font-size: 12px;
    }
  }

  .ant-list-item {
    height: auto;
    border: none !important;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;

    .ant-checkbox-wrapper {
      width: 100%;
    }

    .item-title {
      margin-right: 8px;
    }
  }
}
