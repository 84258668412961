@import "styles/theme.scss";

.kfc-tasks .task-table.report-table {
  padding: 0;

  table {
    width: auto;
  }

  .ant-table-tbody {
    & > tr:first-child td {
      border: none;
    }

    & > tr {
      & > td {
        border: none;
        padding-left: 8px;
        vertical-align: top;
        white-space: normal;

        &:first-child {
          padding-left: 0;
          white-space: nowrap;
          padding-right: 16px;
        }

        &:last-child {
          padding-right: 0;
          width: 100%;
        }
      }

      &:hover {
        td {
          background-color: transparent;
          color: $text-link;

          p {
            color: $text-link;
          }
        }
      }
    }
  }

  .task-content {
    p {
      margin-bottom: 4px;
    }

    small {
      display: block;
      font-size: 12px;
      line-height: 16px;
      color: $text-helper;
      margin-bottom: 4px;
    }
  }
}
