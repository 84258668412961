@import "styles/theme.scss";

.dashboard-card {
  width: 100%;
  border-radius: $border-radius;
  font-size: $font-size;

  .ant-layout,
  .ant-layout-sider,
  .ant-layout-header {
    background-color: transparent;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: visible;
  }

  .ant-layout-content {
    box-sizing: content-box;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    position: relative;

    .ant-alert-error {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      color: $error-color;
      font-size: 12px;
      padding: 5px 5px 4px 30px;

      .anticon {
        color: $error-color;
        font-size: 16px;
        left: 5px;
        top: 6px;
      }
    }
  }

  .ant-layout-header {
    align-items: flex-start;
    height: auto;
    line-height: 1;
  }

  .ant-layout-sider {
    &:first-child {
      padding-right: 20px;
    }

    &:last-child {
      margin-left: 40px;
    }
  }

  .ant-card-body {
    height: 100%;
  }

  .view-type-switch {
    border-bottom: 1px solid $border-color;

    .ant-tabs-nav {
      padding: 0;

      .ant-tabs-tab {
        color: $text-helper;
        padding: 11px 16px;

        &.ant-tabs-tab-active {
          color: $text-link;
        }
      }
    }
  }

  .card-controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .control-links {
      a {
        margin-left: 1em;
      }
    }
  }

  .info-icon {
    position: absolute;
    right: 10px;
    bottom: 15px;
    padding-right: 5px;
    font-size: 12px;
    color: $text-faded;
  }

  .under-construction {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);

    .uc-message {
      font-size: 14px;
      background-color: $shaded-background;
      padding: 15px;
      width: 50%;
      text-align: center;
      border-radius: $border-radius;
      pointer-events: none;
      user-select: none;
    }
  }
}

.card-tooltip {
  .ant-tooltip-inner {
    background-color: $hint-background;
    color: $text-color;
  }
  .ant-tooltip-arrow {
    border-top-color: $hint-background;
    right: 5px;
  }
}
