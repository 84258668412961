@charset "UTF-8";
@import "styles/theme.scss";

.task-group {
  .task-group-count {
    display: inline-block;
    margin: 0 8px;
  }

  .task-title {
    margin: 12px 0 0;
    line-height: 1.3;
  }

  .task-time,
  .task-info {
    font-size: 12px;
    display: block;
    padding: 4px 0;
  }

  .manager-task {
    &.mod-sub-ready,
    &.mod-sub-outdated,
    &.mod-sub-confirmed,
    &.mod-sub-declined {
      position: relative;
      padding-left: 16px;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $text-helper;
        margin: 0;
        position: absolute;
        left: 0;
        top: 5px;
        z-index: 1;
      }
    }

    &.mod-sub-ready:before {
      background-color: transparent;
      width: 13px;
      height: 12px;
      content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuOTkzMzEgMC4zMzIwMzFDMy4zMTMzMSAwLjMzMjAzMSAwLjMzMzMxMyAzLjMxODcgMC4zMzMzMTMgNi45OTg3QzAuMzMzMzEzIDEwLjY3ODcgMy4zMTMzMSAxMy42NjU0IDYuOTkzMzEgMTMuNjY1NEMxMC42OCAxMy42NjU0IDEzLjY2NjYgMTAuNjc4NyAxMy42NjY2IDYuOTk4N0MxMy42NjY2IDMuMzE4NyAxMC42OCAwLjMzMjAzMSA2Ljk5MzMxIDAuMzMyMDMxWk02Ljk5OTk4IDEyLjMzMkM0LjA1MzMxIDEyLjMzMiAxLjY2NjY1IDkuOTQ1MzYgMS42NjY2NSA2Ljk5ODdDMS42NjY2NSA0LjA1MjAzIDQuMDUzMzEgMS42NjUzNiA2Ljk5OTk4IDEuNjY1MzZDOS45NDY2NSAxLjY2NTM2IDEyLjMzMzMgNC4wNTIwMyAxMi4zMzMzIDYuOTk4N0MxMi4zMzMzIDkuOTQ1MzYgOS45NDY2NSAxMi4zMzIgNi45OTk5OCAxMi4zMzJaTTYuODUzMzEgMy42NjUzNkg2LjgxMzMxQzYuNTQ2NjUgMy42NjUzNiA2LjMzMzMxIDMuODc4NyA2LjMzMzMxIDQuMTQ1MzZWNy4yOTIwM0M2LjMzMzMxIDcuNTI1MzYgNi40NTMzMSA3Ljc0NTM2IDYuNjU5OTggNy44NjUzNkw5LjQyNjY1IDkuNTI1MzZDOS42NTMzMSA5LjY1ODcgOS45NDY2NSA5LjU5MjAzIDEwLjA4IDkuMzY1MzZDMTAuMjIgOS4xMzg3IDEwLjE0NjYgOC44Mzg3IDkuOTEzMzEgOC43MDUzNkw3LjMzMzMxIDcuMTcyMDNWNC4xNDUzNkM3LjMzMzMxIDMuODc4NyA3LjExOTk4IDMuNjY1MzYgNi44NTMzMSAzLjY2NTM2WiIgZmlsbD0iIzAwMCIgZmlsbC1vcGFjaXR5PSIuNiIvPjwvc3ZnPg==);
      padding: 0;
      line-height: 12px;
      transform: translateY(-3px);
    }

    &.mod-sub-outdated:before {
      background-color: transparent;
      width: 13px;
      height: 12px;
      content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuOTc5OTcgMTEuOTk4NUgxMi4wMkMxMy4wNDY2IDExLjk5ODUgMTMuNjg2NiAxMC44ODUyIDEzLjE3MzMgOS45OTg0OUw4LjE1MzMgMS4zMjUxNkM3LjYzOTk3IDAuNDM4NDkgNi4zNTk5NyAwLjQzODQ5IDUuODQ2NjQgMS4zMjUxNkwwLjgyNjYzNiA5Ljk5ODQ5QzAuMzEzMzAyIDEwLjg4NTIgMC45NTMzMDIgMTEuOTk4NSAxLjk3OTk3IDExLjk5ODVaTTYuOTk5OTcgNy4zMzE4MkM2LjYzMzMgNy4zMzE4MiA2LjMzMzMgNy4wMzE4MiA2LjMzMzMgNi42NjUxNlY1LjMzMTgyQzYuMzMzMyA0Ljk2NTE2IDYuNjMzMyA0LjY2NTE2IDYuOTk5OTcgNC42NjUxNkM3LjM2NjY0IDQuNjY1MTYgNy42NjY2NCA0Ljk2NTE2IDcuNjY2NjQgNS4zMzE4MlY2LjY2NTE2QzcuNjY2NjQgNy4wMzE4MiA3LjM2NjY0IDcuMzMxODIgNi45OTk5NyA3LjMzMTgyWk03LjY2NjY0IDkuOTk4NDlINi4zMzMzVjguNjY1MTZINy42NjY2NFY5Ljk5ODQ5WiIgZmlsbD0iI0VGOTY1QyIvPjwvc3ZnPg==);
      padding: 0;
      line-height: 12px;
      transform: translateY(-3px);
    }

    &.mod-sub-confirmed:before {
      background-color: transparent;
      width: 13px;
      height: 12px;
      content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuOTk5OTggMC4zMzIwMzFDMy4zMTk5OCAwLjMzMjAzMSAwLjMzMzMxMyAzLjMxODcgMC4zMzMzMTMgNi45OTg3QzAuMzMzMzEzIDEwLjY3ODcgMy4zMTk5OCAxMy42NjU0IDYuOTk5OTggMTMuNjY1NEMxMC42OCAxMy42NjU0IDEzLjY2NjYgMTAuNjc4NyAxMy42NjY2IDYuOTk4N0MxMy42NjY2IDMuMzE4NyAxMC42OCAwLjMzMjAzMSA2Ljk5OTk4IDAuMzMyMDMxWk01LjE5MzMxIDkuODU4N0wyLjc5OTk4IDcuNDY1MzZDMi41Mzk5OCA3LjIwNTM2IDIuNTM5OTggNi43ODUzNiAyLjc5OTk4IDYuNTI1MzZDMy4wNTk5OCA2LjI2NTM2IDMuNDc5OTggNi4yNjUzNiAzLjczOTk4IDYuNTI1MzZMNS42NjY2NSA4LjQ0NTM2TDEwLjI1MzMgMy44NTg3QzEwLjUxMzMgMy41OTg3IDEwLjkzMzMgMy41OTg3IDExLjE5MzMgMy44NTg3QzExLjQ1MzMgNC4xMTg3IDExLjQ1MzMgNC41Mzg3IDExLjE5MzMgNC43OTg3TDYuMTMzMzEgOS44NTg3QzUuODc5OTggMTAuMTE4NyA1LjQ1MzMxIDEwLjExODcgNS4xOTMzMSA5Ljg1ODdaIiBmaWxsPSIjMDdBNzJBIi8+PC9zdmc+);
      padding: 0;
      line-height: 12px;
      transform: translateY(-3px);
    }

    &.mod-sub-declined:before {
      background-color: transparent;
      width: 13px;
      height: 12px;
      content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuOTk5OTggMC4zMzIwMzFDMy4zMTMzMSAwLjMzMjAzMSAwLjMzMzMxMyAzLjMxMjAzIDAuMzMzMzEzIDYuOTk4N0MwLjMzMzMxMyAxMC42ODU0IDMuMzEzMzEgMTMuNjY1NCA2Ljk5OTk4IDEzLjY2NTRDMTAuNjg2NiAxMy42NjU0IDEzLjY2NjYgMTAuNjg1NCAxMy42NjY2IDYuOTk4N0MxMy42NjY2IDMuMzEyMDMgMTAuNjg2NiAwLjMzMjAzMSA2Ljk5OTk4IDAuMzMyMDMxWk05Ljg2NjY0IDkuODY1MzZDOS42MDY2NSAxMC4xMjU0IDkuMTg2NjUgMTAuMTI1NCA4LjkyNjY1IDkuODY1MzZMNi45OTk5OCA3LjkzODdMNS4wNzMzMSA5Ljg2NTM2QzQuODEzMzEgMTAuMTI1NCA0LjM5MzMxIDEwLjEyNTQgNC4xMzMzMSA5Ljg2NTM2QzMuODczMzEgOS42MDUzNiAzLjg3MzMxIDkuMTg1MzYgNC4xMzMzMSA4LjkyNTM2TDYuMDU5OTggNi45OTg3TDQuMTMzMzEgNS4wNzIwM0MzLjg3MzMxIDQuODEyMDMgMy44NzMzMSA0LjM5MjAzIDQuMTMzMzEgNC4xMzIwM0M0LjM5MzMxIDMuODcyMDMgNC44MTMzMSAzLjg3MjAzIDUuMDczMzEgNC4xMzIwM0w2Ljk5OTk4IDYuMDU4N0w4LjkyNjY1IDQuMTMyMDNDOS4xODY2NSAzLjg3MjAzIDkuNjA2NjUgMy44NzIwMyA5Ljg2NjY0IDQuMTMyMDNDMTAuMTI2NiA0LjM5MjAzIDEwLjEyNjYgNC44MTIwMyA5Ljg2NjY0IDUuMDcyMDNMNy45Mzk5OCA2Ljk5ODdMOS44NjY2NCA4LjkyNTM2QzEwLjEyIDkuMTc4NyAxMC4xMiA5LjYwNTM2IDkuODY2NjQgOS44NjUzNloiIGZpbGw9IiNBNzAwMDAiLz48L3N2Zz4=);
      padding: 0;
      line-height: 12px;
      transform: translateY(-3px);
    }

    &.mod-sub-ready {
      &.mod-later {
        .task-date {
          color: #dd4a4a;
        }
      }
    }

    &.mod-done {
      position: relative;
      padding-left: 24px;
      cursor: pointer;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $success-color;
        margin: 0;
        position: absolute;
        left: 0;
        top: 2px;
        z-index: 1;
      }

      &:after {
        content: "";
        position: absolute;
        left: 8px;
        top: 10px;
        width: 8px;
        height: 4px;
        border: 1.5px solid #fff;
        border-right: none;
        border-top: none;
        transform: translate(-55%, -70%) rotate(-45deg);
        z-index: 2;
      }
    }

    &.mod-outdated {
      position: relative;
      padding-left: 24px;
      cursor: pointer;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $primary-color;
        margin: 0;
        position: absolute;
        left: 0;
        top: 2px;
        z-index: 1;
      }

      &:after {
        content: "+";
        position: absolute;
        left: 8px;
        top: 10px;
        width: 8px;
        height: 8px;
        font-size: 18px;
        line-height: 0;
        color: #fff;
        border-top: none;
        transform: translate(-26%, -20%) rotate(-45deg);
        z-index: 2;
      }
    }
  }
}
