@import "styles/theme.scss";

.manager-report {
  margin-right: 24px;
  color: $text-helper;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  sup {
    margin-left: 8px;

    .all-done {
      margin: 5px 0 0;
    }
  }

  .self-tasks-group {
    margin-bottom: 24px;

    h3 {
      font-size: 16px;
      line-height: 28px;
      border-bottom: 1px solid $border-color;
    }

    .task-group {
      margin: 16px 0;

      h4 {
        margin-bottom: 4px;
      }
      .task-title {
        margin-top: 5px;
      }
    }
  }

  .user-avatar {
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 24px;
    background-color: $text-link-light;
    border-radius: 40px;
    margin-top: 0;
    margin-right: 16px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }
  }

  .manager-report-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    flex: 0 0 auto;
    align-items: center;

    .manager-info {
      h3 {
        font-size: 20px;
        line-height: 1.2;
        margin-top: 8px;
        margin-bottom: 8px;
        white-space: pre-line;
      }

      .manager-shift {
        display: block;
        font-size: 12px;
      }

      .add-row-control {
        margin-top: 8px;
        display: block;
      }
    }
  }

  .manager-tasks {
    flex: 1 1 100%;
    overflow: hidden;

    & > .ant-tabs {
      display: flex;
      flex-direction: column;
    }

    & > div[style] > div[style]:not(.v-scroll-track) {
      padding-right: 24px;
    }

    hr {
      width: 100%;
      border: none;
      height: 1px;
      background-color: $shaded-background;
      margin: 24px 0;
    }
  }

  .add-row-control,
  .v-scroll-thumb {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .kfc-spoiler {
    h4 {
      color: $text-link;
      margin-bottom: 24px;

      .anticon {
        svg,
        svg * {
          fill: $text-link;
        }
      }
    }
  }

  .task-group,
  .task-group .kfc-spoiler {
    h4 {
      color: $text-color;
      margin-bottom: 16px;

      .anticon {
        svg,
        svg * {
          fill: $text-helper;
        }
      }
    }

    .task-description {
      margin: 4px 0;
      line-height: 16px;
      color: $text-helper;
    }
  }

  &:hover {
    .add-row-control,
    .v-scroll-thumb {
      opacity: 1;
    }
  }

  .ant-tabs,
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }
}
