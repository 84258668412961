.kfc-staff {
  display: flex;
  flex-direction: column;

  .tabbed-content,
  .ant-layout-content {
    display: flex;
    flex-direction: column;
  }

  .inner-content.fixed {
    flex: 1 1 100%;
  }

  .view-type-switch.fixed {
    display: flex;
    flex-direction: column;

    h2 {
      flex: 0 0 auto;
      margin-bottom: -5px;
    }

    .ant-tabs {
      flex: 1 1 100%;
      min-height: 0;

      .ant-tabs-nav {
        padding: 0;
      }
    }
  }
}
