@import "styles/theme.scss";

.guest-vote-table--bold .ant-table-tbody > tr:nth-child(2) {
  font-weight: 700;
}

.guest-vote-table {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .report-table .ant-table-tbody > tr > td .percent,
  .report-table .ant-table-tbody > tr > td.numeric,
  .report-table .ant-table-thead > tr > th.numeric {
    text-align: center !important;
  }

  .report-wrap .ant-table .delta-turnover {
    justify-content: center;
    text-align: center;
  }

  .dropdown_wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;

    .view-type-drop {
      padding: 0;
      width: 180px;

      .ant-dropdown-link {
        // display: flex;
      }
    }
  }

  .dropdown {
    border: 1px solid $shaded-background;
  }

  .support_text {
    color: $text-helper;
  }
}
