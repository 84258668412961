@import "styles/theme.scss";

span.link {
  color: $text-link;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: $text-color;
  }

  &.off-top {
    margin-top: 0.5em;
    display: inline-block;
  }
}

span.list-icon {
  display: inline-block;
  margin-bottom: 16px;

  &:before {
    content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIuNjY2NjcgNi4wMDEzSDEwLjY2NjdDMTEuMDMzMyA2LjAwMTMgMTEuMzMzMyA1LjcwMTMgMTEuMzMzMyA1LjMzNDY0QzExLjMzMzMgNC45Njc5NyAxMS4wMzMzIDQuNjY3OTcgMTAuNjY2NyA0LjY2Nzk3SDIuNjY2NjdDMi4zIDQuNjY3OTcgMiA0Ljk2Nzk3IDIgNS4zMzQ2NEMyIDUuNzAxMyAyLjMgNi4wMDEzIDIuNjY2NjcgNi4wMDEzWk0yLjY2NjY3IDguNjY3OTdIMTAuNjY2N0MxMS4wMzMzIDguNjY3OTcgMTEuMzMzMyA4LjM2Nzk3IDExLjMzMzMgOC4wMDEzQzExLjMzMzMgNy42MzQ2NCAxMS4wMzMzIDcuMzM0NjQgMTAuNjY2NyA3LjMzNDY0SDIuNjY2NjdDMi4zIDcuMzM0NjQgMiA3LjYzNDY0IDIgOC4wMDEzQzIgOC4zNjc5NyAyLjMgOC42Njc5NyAyLjY2NjY3IDguNjY3OTdaTTIuNjY2NjcgMTEuMzM0NkgxMC42NjY3QzExLjAzMzMgMTEuMzM0NiAxMS4zMzMzIDExLjAzNDYgMTEuMzMzMyAxMC42NjhDMTEuMzMzMyAxMC4zMDEzIDExLjAzMzMgMTAuMDAxMyAxMC42NjY3IDEwLjAwMTNIMi42NjY2N0MyLjMgMTAuMDAxMyAyIDEwLjMwMTMgMiAxMC42NjhDMiAxMS4wMzQ2IDIuMyAxMS4zMzQ2IDIuNjY2NjcgMTEuMzM0NlpNMTIuNjY2NyAxMS4zMzQ2SDE0VjEwLjAwMTNIMTIuNjY2N1YxMS4zMzQ2Wk0xMi42NjY3IDQuNjY3OTdWNi4wMDEzSDE0VjQuNjY3OTdIMTIuNjY2N1pNMTIuNjY2NyA4LjY2Nzk3SDE0VjcuMzM0NjRIMTIuNjY2N1Y4LjY2Nzk3WiIgZmlsbD0iIzFGODZGRiIvPjwvc3ZnPg==);
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
}
