.print-report {
  padding: 0 32px;
  font-family: sans-serif;
  font-size: 12px;
  color: #000;
  border: 1px solid #ddd;

  &:first-child:last-child {
    min-height: 100%;
  }

  .print-report-template {
    width: 100%;

    tr,
    td {
      page-break-inside: avoid;
      break-inside: avoid;
    }

    thead {
      & > tr > td {
        padding-left: 4px;
        font-weight: bold;

        &:first-child {
          padding-left: 0;
        }
      }

      & > tr:first-child > td {
        text-align: left;
        padding-right: 0;
        padding-top: 24px;
        padding-bottom: 16px;

        table td:last-child {
          border-right: none;
        }
      }
    }

    tfoot {
      & > tr > td {
        text-align: right;
        padding-bottom: 24px;

        &:after {
          content: "";
        }
      }
    }
  }
}

@page {
  margin: 0 0 1cm;
}

@media print {
  html,
  body,
  #root,
  .App,
  .kfc-app,
  .kfc-app > .ant-layout {
    border: 1px solid #fff;
    height: auto !important;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
  }
  .print-report {
    page-break-before: always;
    width: 100%;
    border: none;

    &:first-child {
      page-break-before: avoid;
    }
  }
}
