@import "styles/theme.scss";

.manning-chart {
  padding-left: 40px;
  padding-bottom: 20px;
  position: relative;

  .bottom-label,
  .left-label {
    font-size: 12px;
    color: $text-helper;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .left-label {
    bottom: 50%;
    left: -40px;
    transform: translateY(-50%) rotate(-90deg);
  }

  .rv-xy-plot {
    margin-left: -24px;
  }
}
