.kfc-reports {
  .inner-content.fixed {
    flex: 1 1 100%;
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
  }

  .section-cards-aside {
    flex: 0 1 400px;
  }

  .reports-container {
    .ant-card-body {
      padding: 0;
    }

    .reports-error {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }

  .ant-card-body {
    width: 100%;

    & > .ant-tabs {
      height: 100%;
      display: flex;
      flex-direction: column;

      .ant-tabs-bar {
        flex: 0 0 auto;
      }

      .ant-tabs-content {
        flex: 1 1 100%;

        .ant-tabs-tabpane-active {
          height: 100%;
        }
      }
    }
  }
}

@media print {
  .kfc-reports {
    .section-cards-aside {
      display: none;
    }
  }
}
