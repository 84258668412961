@import "styles/theme.scss";

.staff-head {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px 16px;

  .user-avatar {
    width: 80px;
    height: 80px;
    margin: 0;
    border-radius: 40px;
    line-height: 80px;
    font-size: 24px;
    background-color: $text-link-light;
  }

  & + .ant-tabs {
    margin: 0 !important;

    .ant-tabs-nav {
      padding: 0 !important;
    }
  }

  h2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .staff-name-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    .transfer-line {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .anticon {
        margin-right: 4px;
      }

      svg * {
        fill: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.staff-name {
  padding: 11px 20px 23px;

  .badge {
    display: inline-block;
    vertical-align: middle;
    background-color: $shaded-background;
    color: $text-color;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 3px;
    margin-left: 16px;
    transform: translateY(3px);
  }
}
