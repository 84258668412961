@import "styles/theme.scss";

.template-card {
  max-width: 980px;

  .add-row-control {
    padding: 8px 16px;
    border: 1px solid $text-link;
    border-radius: $border-radius;
    margin-top: 15px;
    width: 165px;
    height: 37px;

    &:hover {
      border-color: $text-color;
    }
  }

  h2 {
    margin: 20px 0 32px;
    flex: 0 0 auto;
  }

  &.view-type-switch.fixed {
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .template-component {
    border-bottom: 1px solid $border-color;
    padding-top: 20px;
  }

  .ant-legacy-form {
    max-width: 960px;
    width: auto;
    flex: 1 1 auto;
    overflow-x: hidden;
    min-height: 100%;
    position: relative;
    padding-bottom: 48px;

    .buttons {
      & > .ant-col:first-child {
        button {
          margin-left: 5px;
        }
      }
    }

    .field-row {
      &:last-child {
        position: absolute;
        left: 0;
        bottom: 8px;
        width: 100%;

        .ant-row {
          margin-bottom: 0;
        }

        .fi-type-button {
          margin-top: 0;
        }

        &.ant-row {
          position: static;
        }
      }

      .fi-type-checkgroup {
        .ant-checkbox-group {
          flex-direction: column;
          margin-right: -12px;
        }
      }

      .fi-type-checkbox,
      .fi-type-checkgroup {
        .ant-checkbox-wrapper {
          display: flex;
          flex-direction: row;
          margin-bottom: 12px;
          align-items: flex-start;

          .ant-checkbox {
            margin-right: 10px;

            & + span {
              padding: 0;
            }
          }
        }
      }

      .fi-type-button {
        margin-top: 15px;
      }

      .field-column {
        padding: 16px 0;

        .ant-row {
          margin-bottom: 0;

          .ant-form-item-label {
            padding-top: 8px;
          }

          &:first-child {
            .ant-form-item-label {
              padding-top: 0;
            }
          }
        }

        .fi-type-label {
          .ant-form-item-label {
            padding-top: 0;
          }
        }

        .fi-type-checkbox .ant-form-item-control {
          margin-top: 0;
        }
      }
    }

    hr {
      margin: 0;
      max-width: 100%;
    }

    .field-row .fi-type-checkbox .ant-form-item-control {
      margin-top: 24px;
    }

    .field-row + .field-row {
      .fi-type-checkbox .ant-form-item-control {
        margin-top: 0;
      }
    }
  }

  .template-version {
    display: inline-block;
    vertical-align: baseline;
    margin-left: 16px;
    font-size: 12px;
    color: $text-helper;
  }
}

.move-button button {
  width: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;

  .arrow-icon svg {
    height: 18px;
    width: 18px;
  }
}

.arrows {
  flex-direction: row;
  flex-wrap: nowrap;
  transform: translate(10px, -28px);

  .ant-legacy-form-item-control {
    line-height: inherit;
  }

  .ant-col {
    padding-left: 5px !important;
    padding-right: 0 !important;
  }
}
