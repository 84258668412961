@import "styles/theme.scss";

.overall-rating {
  // padding: 0 !important;

  .center-container {
    min-height: 300px;
  }

  .report-table {
    .ant-table-thead
      > tr
      > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-sort
      .ant-table-column-sorter {
      margin-left: 0;
      margin-right: 5px;

      .ant-table-column-sorter-inner-full {
        margin-left: 0;
        transition: none;
      }
    }

    .ant-table-body > table {
      min-width: 100%;

      & > .ant-table-tbody > tr > td,
      & > .ant-table-thead > tr > th {
        padding-left: 16px;
        width: 7%;

        &:first-child {
          width: 30%;
        }

        & + .collapsed {
          border-left: 1px solid $border-color;
        }

        &.collapsed {
          border-right: 1px solid $border-color;

          & + .collapsed,
          &:nth-child(2) {
            border-left: 0;
          }
        }

        &:last-child {
          padding-right: 24px !important;
        }
      }

      & > .ant-table-tbody > tr > t {
        border-bottom: 1px solid $border-color;
      }
    }

    .ant-input-number {
      margin-left: -16px;
      min-width: 60px;
      width: 60px;
    }

    .ant-table-thead {
      & > tr > th {
        padding-left: 16px;
        padding-right: 16px;
      }

      &
        > tr:nth-child(2)
        > th.ant-table-column-has-actions.ant-table-column-has-sorters:first-child {
        padding-left: 24px;
      }

      tr:first-child {
        th {
          font-size: 16px;
          line-height: 20px;
          color: $text-color;
          text-align: left;
          white-space: nowrap;

          &:first-child {
            padding-top: 0;
            padding-bottom: 16px;
            vertical-align: top;
          }

          &[rowspan] {
            border-bottom: 1px solid $border-color !important;
          }
        }
      }

      tr:last-child {
        th {
          border-bottom: 1px solid $border-color !important;
          padding-bottom: 4px !important;
        }
      }
    }

    .ant-table-body {
      min-height: 300px;
    }
  }

  .ant-calendar-month .ant-calendar-month-header-wrap {
    height: 220px;
  }

  .ant-calendar-picker-input {
    border: none;
    width: 110px;
    color: $text-link;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .ant-calendar-picker {
    position: relative;

    &:after {
      content: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wLjcxIDEuNzEwMDJMMy4zIDQuMzAwMDJDMy40ODczNiA0LjQ4NjI3IDMuNzQwODEgNC41OTA4MSA0LjAwNSA0LjU5MDgxQzQuMjY5MTkgNC41OTA4MSA0LjUyMjY0IDQuNDg2MjcgNC43MSA0LjMwMDAyTDcuMyAxLjcxMDAyQzcuNDQxMDMgMS41NzAxNSA3LjUzNzIxIDEuMzkxNDUgNy41NzYyOCAxLjE5NjdDNy42MTUzNSAxLjAwMTk1IDcuNTk1NTQgMC43OTk5NzkgNy41MTkzNyAwLjYxNjUzNUM3LjQ0MzIgMC40MzMwOTEgNy4zMTQxMyAwLjI3NjQ4NiA3LjE0ODYxIDAuMTY2Njg1QzYuOTgzMDkgMC4wNTY4ODM4IDYuNzg4NjIgLTAuMDAxMTM4MTggNi41OSAxLjY5MTY5ZS0wNUwxLjQxIDEuNjkxNjllLTA1QzEuMjEyMjMgMC4wMDA4NDYxNDggMS4wMTkxNCAwLjA2MDMwMSAwLjg1NTE1OCAwLjE3MDg2NEMwLjY5MTE3NCAwLjI4MTQyNyAwLjU2MzY1OCAwLjQzODEzMyAwLjQ4ODczMiAwLjYyMTE2NUMwLjQxMzgwNiAwLjgwNDE5OCAwLjM5NDgzNyAxLjAwNTM0IDAuNDM0MjIyIDEuMTk5MTVDMC40NzM2MDcgMS4zOTI5NiAwLjU2OTU3OCAxLjU3MDc1IDAuNzEgMS43MTAwMloiIGZpbGw9IiMxRjg2RkYiLz48L3N2Zz4=);
      position: absolute;
      right: 0;
      top: 4px;
    }
  }

  .ant-calendar-month-panel-tbody {
    tr {
      height: 1em;

      td {
        display: none;

        &:first-child {
          display: block;
          text-transform: capitalize;
        }
      }
    }
  }
}

.popin-offset {
  margin: 10px 0;
}
