@import "styles/theme.scss";

.data-relation {
  white-space: nowrap;
  color: $text-helper;

  .relation-value {
    margin-right: 0.5em;
    color: $text-color;
  }

  &.bad {
    .relation-fact {
      color: $error-color;

      & + .relation-addon {
        color: $error-color;
      }
    }
  }

  &.good {
    .relation-fact {
      color: $success-color;

      & + .relation-addon {
        color: $success-color;
      }
    }
  }

  .relation-hit {
    display: inline-block;
    width: 4em;
  }
}
