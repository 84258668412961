@import "styles/theme.scss";

.calendar-summary {
  & > p {
    margin: 8px 0;
    color: $text-color;
    font-size: 12px;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      background-color: #ccc;
      margin-top: -2px;
      margin-right: 8px;
    }

    &.done:before {
      background-color: #abffbd;
    }

    &.outdated:before {
      background-color: $mild-color;
    }

    &.missed:before {
      background-color: #ffa7a7;
    }
  }
}
