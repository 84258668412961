@import "styles/theme.scss";

.view-type-drop {
  padding: 6px 25px;
  line-height: 1.5;
  position: relative;

  span.link {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 3px;
    color: $text-helper;
    background-color: transparent;

    &:hover,
    &.active {
      color: $text-link;
    }

    &.ant-dropdown-open {
      background-color: $good-light;
      color: $text-link;
    }

    .anticon {
      font-size: 10px;
      margin-left: 8px;
    }
  }
}

.view-type-drop-dd {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;

  .ant-menu-item {
    margin: 0 !important;

    &:hover {
      background-color: $good-light;

      span.link {
        color: $text-color;
      }
    }

    span.link {
      padding: 3px 16px;
      margin: 0 -16px;
      line-height: 34px;
      max-width: 100%;
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $text-color;
    }
  }
}

.view-type-drop-wrap {
  overflow: hidden;
  transform: translateY(-3px) !important;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.ant-dropdown .link {
  display: block;
}

.view-type-switch + .view-type-drop {
  padding-left: 0;
}

.dd-filter-overlay {
  padding: 8px 16px;
}
