.ppr-table-error {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
    &.centered {
        justify-content: center;
        padding-top: 0;
    }
    .title {
        font-size: 16px;
        font-weight: 600;
    }
    .subtitle {
        margin-bottom: 8px;
        text-align: center;
        padding: 0 32px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
}