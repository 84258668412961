.kfc-popup.kfc-overall-modal {
  max-width: 100%;
  min-width: 760px;
  white-space: nowrap;

  .ant-modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 16px;

    table {
      width: 100%;
      table-layout: auto;

      td {
        width: 180px;

        white-space: nowrap;

        &:first-child {
          width: 110px;
        }

        &:nth-child(2) {
          width: 80px;
        }

        &:last-child {
          min-width: 250px;
        }
      }
    }
  }
}
