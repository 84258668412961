.kfc-splash {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  transition: opacity 0.5s ease 0.5s;
  animation: splash-in 0.5s ease 1;
  opacity: 1;

  & ~ * {
    opacity: 0;
  }

  .inner-splash {
    height: 100%;
    width: auto;
  }

  &.splash-hidden {
    opacity: 0;
    pointer-events: none;

    & ~ * {
      opacity: unset;
    }
  }
}

@keyframes splash-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
