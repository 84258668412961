@import "styles/theme.scss";

.classic-pager {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 16px;
  user-select: none;

  .clpg-prev,
  .clpg-next {
    display: inline-block;
    width: 22px;
    height: 22px;
    flex: 0 0 22px;
    border: 1px solid $dark-border;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 8px;
      height: 8px;
      border-top: 2px solid $text-helper;
      border-left: 2px solid $text-helper;
      transform: translate(-30%, -50%) rotate(-45deg);
    }

    &.disabled {
      pointer-events: none;

      &:before {
        border-color: $text-faded;
      }
    }
  }

  .clpg-prev {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-right: 16px;
  }

  .clpg-next {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-left: 16px;

    &:before {
      transform: translate(-80%, -50%) rotate(135deg);
    }
  }

  .clpg-total {
    display: inline-block;
    padding-left: 0.5em;
  }

  .clpg-page input {
    height: 22px;
    width: 40px;
    text-align: center;
    margin: 0 0.5em;
    border-radius: 4px;
    border: 1px solid $dark-border;
    padding: 4px;
  }
  .clpg-page input::-webkit-outer-spin-button,
  .clpg-page input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
}

.report-table + .classic-pager {
  margin-top: -8px;
}
