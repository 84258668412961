@import "styles/theme.scss";

.compare-goals {
  tr:first-child {
    font-weight: bold;
  }
}

.hide-goodbad {
  tr:first-child * {
    color: $text-color !important;
  }
}
