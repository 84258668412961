$app-background: #efefef;
$faded-background: #f7f7f7;
$panel-background: #fff;
$tag-background: #e5eeff;
$hint-background: #eff8ff;
$shaded-background: #ebebeb;
$button-background: #1f86ff;
$hover-background: #fafcff;
$fixed-row-background: #eee;

$border-color: #ebebeb;
$dark-border: #d9d9d9;

$text-color: #151515;
$text-faded: #b8b8b8;
$text-light: #fff;
$text-helper: #7c7c7c;

$text-link: #1f86ff;
$text-link-light: #52a2ff;

$primary-color: #e4002b;
$error-color: #aa0e0b;
$success-color: #0b7b56;
$mild-color: #ffe58f;
$accent-color: #1f86ff;
$star-color: #f5a623;

$warning-color: #fff7dd;
$bad-light: #ffe9e9;
$good-light: #eff8ff;
$neutral-light: #fbfbfb;

$green-line: #8fc2ff;

$morning-color: #f2e80d;
$day-color: rgb(255, 68, 35);
$evening-color: rgb(105, 188, 221);
$night-color: rgb(26, 70, 128);

$old-row-hover: #e6f8ff;
$icon-def: #7F8797;
$icon-active: #1F86FF
