@charset "UTF-8";
@import "styles/theme.scss";

.calendar-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    color: $text-color;
  }

  .ant-picker {
    padding: 0 10px 0 20px;
    cursor: pointer;

    &:hover {
      .ant-picker-suffix,
      .ant-picker-input,
      .ant-picker-range-separator:after {
        color: $text-color !important;
      }
    }
  }

  .ant-picker-active-bar {
    display: none;
  }

  .ant-picker-input {
    width: auto;
    border: none;
    color: $text-link;
    box-shadow: none !important;
    cursor: pointer;
    max-width: 100%;

    input {
      color: inherit;
      text-align: center;
      cursor: pointer
    }


    .ant-range-picker-input {
      width: 84px;
      -webkit-appearance: none;
      min-width: 0;
      text-align: left;
      cursor: pointer;

      &:first-child {
        text-align: right;
      }
    }
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      padding: 11px 16px;
      min-width: 84px;

      @media (max-width: $breakpoint) {
        min-width: 74px;
        width: 74px;
      }
    }
  }

  .ant-picker {
    &-focused{
      border-color: #fff;
      box-shadow: none;
    }
    &.passive {
      .ant-picker-input,
      .ant-picker-suffix,
      .ant-picker-range-separator:after {
        color: $text-helper;
      }

      &.predef-day,
      &.predef-shift {
        .ant-picker-input,
        .ant-picker-range-separator {
          opacity: 0;

          &:first-child {
            opacity: 1;
          }
        }
      }
    }
  }

  .ant-picker-range-separator {
    position: relative;
    color: transparent;
    //top: -19px;
    padding: 0;
    width: 1em;
    white-space: normal;

    &:after {
      content: "–";
      color: $text-link;
      //padding-left: 3px;
    }
  }

  .ant-picker-suffix {
    //right: auto;
    //left: -5px;
    transition: all .3s;
    //padding-right: 10px;
    order: -1;
    color: #1f86ff;
    top: 50%;
    margin: 0;
    //transform: translateY(-50%);
  }

  .ant-picker-clear {
    display: none;
  }
}

.ant-range-right {
  .ant-date-input-wrap {
    margin-left: -180px;

    .ant-input {
      color: $text-link;
    }
  }
}

.ant-range-left {
  .ant-input {
    width: 80px;
    text-align: right;
    color: $text-link;
  }
}

.ant-range-middle {
  left: 88px;
  top: -32px;
  margin-left: 0;

  &:after {
    content: "–";
    color: $text-link;
    display: block;
  }
}

.ant-input {
  font-family: "PT Sans", sans-serif;
  -webkit-appearance: none;
}

.ant-my-select {
  text-transform: capitalize;
}

.ant-header {
  .ant-century-select,
  .ant-decade-select,
  .ant-year-select,
  .ant-month-select {
    color: $text-helper;
  }
}

.ant-range .ant-in-range-cell::before {
  background-color: $hint-background;
}
