.admin-staff-controls {
  padding: 16px 0;
  white-space: nowrap;
  position: sticky;
  top: -16px;
  background-color: #fff;
  z-index: 9;
  margin-left: -24px;
  padding-left: 24px;
  margin-right: -24px;
  padding-right: 24px;

  .ant-col {
    padding-right: 16px;

    &:last-child {
      padding-right: 0;
    }

    .ant-select {
      width: 100%;
    }
  }

  .ant-checkbox-wrapper {
    padding: 7px 0;
  }
}
