@import "styles/theme.scss";

.inner-content {
  padding: 16px $default-padding 0;

  & > :last-child {
    margin-bottom: 24px;
  }

  &.big-offset {
    padding-bottom: 50px;
  }

  &.fixed {
    height: 100%;
    display: flex;
    flex-direction: column;

    & > .ant-row {
      flex: 1 1 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      & > .ant-col {
        height: 100%;

        & > .ant-card {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          & > .ant-card-body {
            flex: 1 1 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
