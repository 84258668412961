.rating-inner.rating-time {
  .pager-wrap {
    width: 100%;
  }

  @media screen and (max-width: 1600px) {
    .report-wrap table {
      td {
        &:first-child {
          overflow: hidden;
          max-width: 190px;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.reports-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
