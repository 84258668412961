@import "styles/theme";

.staff-absence {
  .tabbed-footer {
    padding-left: 0;
  }
  .ant-table-cell {
    .ant-select-selector, .ant-picker {
      border: 1px solid transparent;
    }
  }
  .ant-table-cell-row-hover {
    .ant-select-selector, .ant-picker {
      border-color: $dark-border !important;
      background-color: $panel-background !important;
    }
  }
}
