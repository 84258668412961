@charset "UTF-8";
@import "styles/theme.scss";

.interactive-reports-card {
  .ant-card-body > .ant-tabs .ant-tabs-content {
    min-height: 400px;
  }

  .center-container {
    min-height: 100px;
  }

  .ant-table table {
    width: auto;
    margin-top: 30px;

    tbody {
      tr:first-child {
        td {
          font-weight: bolder;
        }
      }
    }

    td,
    th {
      &:first-child {
        width: 310px;
      }

      .outstaff:after {
        content: "ВС";
        display: inline-block;
        vertical-align: middle;
        margin-top: -1px;
        margin-left: 5px;
        background-color: $shaded-background;
        color: $text-color;
        font-size: 10px;
        line-height: 1.4;
        border-radius: 4px;
        width: 28px;
        height: 14px;
        text-align: center;
        font-weight: normal;
      }
    }
  }

  .interactive-deep {
    .ant-card-body,
    .interactive-controls {
      padding-left: 0;
      padding-right: 0;
    }
    .ant-table table {
      tbody {
        tr:first-child {
          td {
            font-weight: normal;
          }
        }
      }
    }
  }
}
