@import "styles/theme.scss";

.add-row-control {
  color: $text-link;
  cursor: pointer;
  transition: color 0.2s ease, opacity 0.2s ease;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &.disabled {
    cursor: not-allowed;
    color: gray;
    .add-icon {
      background-color: gray;
    }
    &:hover {
      color: gray;
      .add-icon {
        background-color: gray;
      }
    }
  }

  .add-custom-icon {
    margin-right: 8px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .add-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    padding: 2px;
    font-size: 14px;
    font-weight: bold;
    line-height: 0.75;
    text-align: center;
    background-color: $text-link;
    color: $text-light;
    border-radius: 3px;
    transition: background-color 0.2s ease;
  }

  &:hover {
    color: $text-color;

    .add-icon {
      background-color: $text-color;
    }
  }
}
