@charset "UTF-8";
@import "styles/theme.scss";

.staff-schedule-chart {
  .report-wrap {
    max-width: 100%;

    table {
      colgroup {
        display: none;
      }

      th,
      td {
        width: 60px;
        text-align: center;

        &:first-child {
          width: 32px;
        }
        &:nth-child(2) {
          width: 210px;
          text-align: start;
        }
        &:nth-child(3),
        &:nth-child(4) {
          width: 75px;
        }
        &:nth-child(5) {
          width: 120px;
          padding: 0px;
        }
        &:nth-child(6) {
          width: 40px;
        }
      }

      tr.row-hidden {
        display: none;
      }

      tr.row-inactive {
        td.schedule-position {
          padding-left: 0;
          padding-right:14px;    
        }
      }
    }
  }

  &.inline-hours {
    .sync-scroll-container {
      display: none;
    }

    .ant-table-scroll .ant-table-body {
      overflow-x: visible !important;
    }

    .report-wrap table {
      table-layout: fixed;

      th,
      td {
        &.col-hour {
          width: auto;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

.middle-footer {
  padding: 0;

  & + .report-wrap {
    max-width: 100%;
    margin: 0 -24px;
  }
}

.assign-btn {
  cursor: pointer;
  display: inline-block;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: -5px 0 -5px -12px;
  padding: 5px 12px;
  transition: background-color 0.2s ease;
  position: relative;

  &:before {
    content: "Неназначенная смена";
    opacity: 1;
    transition: opacity 0.2s ease;
  }

  &:after {
    content: "Назначить сотрудника";
    opacity: 0;
    transition: opacity 0.2s ease;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
}

tr:hover,
tr.ant-table-row-hover {
  .assign-btn {
    background-color: #fff;
    border-color: $border-color;

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}

.report-table table tr {
  & > th,
  & > td {
    &.first-star {
      padding: 0 !important;
      width: 32px !important;

      span {
        display: block;
        width: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .anticon {
        margin-left: 6px;
        opacity: 0;
        transition: opacity 0.2s ease;

        svg {
          fill: $text-faded;
          transition: fill 0.2s ease;
        }

        &:hover {
          svg {
            fill: $text-helper;
          }
        }

        &.active {
          opacity: 1;

          svg {
            fill: $star-color;
          }
        }
      }

      & + td,
      & + th {
        padding-left: 0 !important;
      }
    }
  }

  &:hover {
    & > td.first-star {
      .anticon {
        opacity: 1;
      }
    }
  }
}

.schedule-position-dropdown {
  min-width: 150px;
}

.ant-modal .chart-time-focused .ant-time-picker {
  box-shadow: none;
}
