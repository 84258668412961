@import "styles/theme.scss";

.view-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 51px;
  flex: 0 0 51px;
  font-size: $sider-icon-size;
  color: $text-light;
  opacity: 0.6;
  cursor: pointer;
  font-size: 10px;
  line-height: 1;
  transition: none;
  padding-top: 3px;
  margin-bottom: 5px;

  &:hover,
  &.current {
    opacity: 1;
    color: $text-light;
  }

  &.bottom {
    margin-top: auto;
    border-bottom: none;

    & + .bottom,
    & + .user-bottom {
      margin-top: 0;
    }
  }

  .anticon {
    margin-bottom: 4px;
    width: 28px;
    height: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .view-tab-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
