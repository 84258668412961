@charset "UTF-8";
@import "styles/theme.scss";

.report-wrap > .reports-controls {
  padding: 0 24px;
}

.tasks-summary {
  display: flex;
  flex-direction: column;

  &.report-table .section-cards {
    width: 100%;

    .section-cards-content {
      border-left: 1px solid $border-color;
      background-color: #fff;
      z-index: 1;
      position: relative;

      .ant-tabs-nav {
        padding: 0;
      }
    }
  }

  .tasks-summary-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sync-scroll-container-v {
    position: absolute;
    right: 0;
  }

  & > .report-wrap {
    flex: 1 1 100%;
  }

  .section-cards-aside .ant-table-body {
    th,
    td {
      &:first-child {
        padding-left: 8px;
      }
    }
  }

  .report-wrap .ant-table {
    table {
      width: 100%;

      colgroup {
        display: none;
      }

      thead th {
        vertical-align: bottom;
        padding-bottom: 7px;
        text-align: right;
        white-space: nowrap !important;

        &:first-child {
          text-align: left;
        }
      }

      tr.row-current {
        td:nth-child(2) {
          font-weight: bolder;
        }
      }
    }
  }

  td {
    span {
      display: inline-block;

      &.total {
        color: $text-helper;
      }

      &.total-personal + .total:before {
        content: "/";
        display: inline-block;
        margin-right: 0.5em;
      }

      &.ratio-percent {
        width: 56px;
        margin-right: 0;
        display: block;

        &.good {
          color: $success-color;
        }

        &.bad {
          color: $error-color;
        }
      }
    }
  }
}

.tasks-period-view {
  .tasks-summary {
    tr.row-current {
      td {
        font-weight: bolder;
      }
    }

    .section-cards-aside {
      .ant-table-body {
        td,
        th {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(6) {
            padding-left: 16px;
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(5) {
            padding-right: 16px;
          }
        }

        thead th:first-child {
          text-align: left;
          padding-left: 8px;
        }

        .ant-table-tbody > tr {
          &.row-current {
            background-color: transparent !important;
          }

          & > td {
            text-align: right;

            &:first-child {
              text-align: left;
              padding-left: 8px;
            }

            &:nth-child(2),
            &:nth-child(5) {
              border-right: 1px solid $border-color;
            }
          }
        }
      }
    }
  }
}
