@import "styles/theme.scss";

.calendar-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 12px 24px 0;
  border-bottom: 1px solid $border-color;

  h2 {
    width: 130px;
    margin-right: 32px;
  }

  &.calendar-view-week {
    h2 {
      width: 230px;
    }
  }

  .view-type-switch {
    margin-left: auto;

    &:last-child {
      align-self: flex-end;
    }

    .ant-tabs-bar {
      border-bottom: none;
    }
  }

  .ant-picker {
    visibility: hidden;
    margin-top: -20px;
    margin-left: -20px;
    display: block;
    pointer-events: none;
  }
}
