@import "styles/theme.scss";

.kfc-check-upload {
  .ant-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .ant-btn.ant-btn-danger {
    background-color: #a70000;
    color: #fff;
    height: 32px;
    line-height: 32px;

    span {
      height: 32px;
      line-height: 32px;
      font-size: 14px;
    }

    &[disabled] {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.2);
    }
  }

  .ant-card-body {
    padding: 24px 32px;
  }

  b.alarm {
    color: $primary-color;
  }

  .center-container {
    padding-top: 16px;
  }

  .ant-col {
    position: relative;

    .ant-select {
      width: 100%;
    }

    .ant-calendar-picker-container {
      left: 0 !important;
      top: 32px !important;
    }
  }

  .ant-tabs-tab {
    min-width: 120px;
  }

  .dates-fake {
    color: $text-link;
    cursor: pointer;
    transform: translateY(4px);

    .anticon {
      margin-right: 8px;
    }
  }

  .ant-calendar-picker {
    display: none;
  }
}

.kfc-remove-progress .ant-modal-footer {
  padding: 12px;
}

.kfc-remove-done {
  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-title {
    text-align: center;
  }

  .ant-modal-footer > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .ant-btn {
      width: 128px;
    }
  }
}
