@import "styles/theme.scss";

.calendar-screen {
  .calendar-cover {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  .section-cards-aside > .ant-card {
    border: none;
  }

  .section-cards-content {
    padding-right: 24px;
    padding-bottom: 24px;
    box-sizing: content-box;
  }

  .fc {
    box-sizing: border-box;
    height: 100%;

    .fc-view-container * {
      box-sizing: border-box;
    }

    .fc-head-container {
      border: none;
    }

    .fc-event-container {
      padding: 0 4px;
    }

    .fc-day-header {
      border: none;
      text-transform: capitalize;
      color: $text-faded;
      font-size: 12px;
      padding: 4px 8px;
      text-align: left;
    }

    .fc-day.fc-widget-content {
      &.fc-other-month {
        border-color: $border-color;
      }
    }

    td.fc-widget-content.fc-today {
      background-color: $hint-background;
    }

    .fc-widget-header {
      border: none !important;
    }

    .fc-highlight,
    .fc-selected {
      background-color: transparent;
      box-shadow: 1px 1px 0px $text-link inset, -1px -1px 0px $text-link inset;
      opacity: 1;
    }

    .fc-dayGrid-view {
      .fc-day-top {
        .fc-day-number {
          float: left;
          font-size: 12px;
          color: $text-helper;
          padding-left: 5px;
        }

        &.fc-other-month {
          opacity: 1;

          .fc-day-number {
            color: $dark-border;
          }
        }
      }
    }

    .fc-widget-content {
      border-color: $dark-border;
      cursor: pointer;

      &.fc-day {
        border: 1px solid $dark-border;

        &:hover {
          box-shadow: 1px 1px 0px $text-link inset,
            -1px -1px 0px $text-link inset;
        }
      }

      &.fc-past {
        /*cursor: not-allowed;*/
      }
      /*
            &.fc-past,
            &.fc-other-month {
                &:hover {
                    box-shadow: none;
                }
            }*/
    }
  }

  &.dragging {
    .fc-day.fc-widget-content {
      &.fc-past,
      &.fc-other-month {
        border-color: $border-color;
        background-color: $app-background;
        pointer-events: none;

        &:focus {
          outline: none;
        }
      }
    }

    .fc-dayGrid-view {
      .fc-day-top {
        &.fc-past,
        &.fc-other-month {
          .fc-day-number {
            opacity: 0;
          }
        }
      }
    }

    .fc-e-past {
      opacity: 0;
    }

    .fc-time-grid-event {
      pointer-events: none;
    }
  }

  .fc-view-month {
    .fc-content-skeleton,
    .fc-row .fc-bgevent-skeleton {
      pointer-events: none;
    }
  }

  .fc-view-week {
    .fc-body > tr > .fc-widget-content {
      border: none;
    }

    .fc-time-grid-event {
      transition: background-color 0.1s ease, color 0.1s ease;
      min-height: 11px;
      border-bottom: 1px solid #fff;

      &:hover {
        background-color: $text-link;
        color: $text-light;
      }
    }

    .fc-slats {
      td:first-child {
        border: none;
        font-size: 12px;
        color: $text-faded;

        span {
          display: inline-block;
          padding-right: 8px;
        }
      }

      tr:first-child {
        td {
          box-shadow: 0px 1px 0px $dark-border inset;

          &:first-child {
            box-shadow: none;
          }
        }
      }

      tr:last-child {
        td {
          box-shadow: 0px -2px 0px $dark-border inset;

          &:first-child {
            box-shadow: none;
          }
        }
      }
    }
  }
}
