.ppr-manager-data {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #fff;
    width: 100%;
    
    .badge {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.87);
        padding: 4px 8px;
        border-radius: 4px;
        width: max-content;
        min-width: 64px;
        &.date {
            background-color: #EBEBEB;
            margin: auto;
        }
        &.ok {
            background-color: #D6E9FF;
        }
    }

    .text-justify {
        text-align: justify;
    }

    thead {
        tr > th {
            border-top: unset;
        }
        tr > th:first-child {
            border-left: unset;
        }
        tr > th:last-child {
            border-right: unset;
        }
    }

    th,
    td {
        vertical-align: middle;
        text-align: center;
        border: 1px solid #ebebeb;
        &.no-border {
            border: unset;
        }
    }

    th {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.6);
    }
    td {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.87);
        padding: 4px 8px;
        height: 30px;
        word-break: break-word;
        &.bold {
            font-weight: 700;
        }
        &[colspan="10"] {
            border-left: unset;
            border-right: unset;
        }
    }

    .status-check {
        th {
            border-left: unset;
        }
        td {
            border-right: unset;
        }
    }

    .manager-row {
        .hide {
            display: none;
        }
        td, th {
            border: unset;
        }
        th, td:nth-child(4n), td:nth-child(5n) {
            border-right: 1px solid #ebebeb;
        }
        td:nth-child(2) {
            text-align: start;
        }
        /* каждый третий элемент начиная с первого */
        &.one {
            th {
                border-top: 1px solid #ebebeb;
            }
        }
        /* каждый третий элемент*/
        &.three {
            td {
                border-bottom: 1px solid #ebebeb;
            }
        }
    }
}
