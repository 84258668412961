.ppr-area {
    min-width: 900px;
    padding-bottom: 60px;
    display: block!important;
    position: relative;

    .ppr-controls-header {
        background: #fff;
        display: flex;
        align-items: center;
        .header-title {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
        }

        .header-picker {
            align-items: center;
            .reports-controls, .reports-controls-header {
                height: 24px;
                padding: 0;
            }
            .dates-fake#text {
                display: none;
            }
        }
    }

    .ant-form-item {
        margin-bottom: 0;
        .ant-form-item-control {
            padding: 0;
            position: relative;
            .ant-form-explain {
                position: absolute;
                padding: 0;
                margin: 0;
                top: 8px;
                left: 12px;
                min-height: unset;
                pointer-events: none;
            }
        }
    }

    .ant-select {
        min-width: 130px;
    }
}